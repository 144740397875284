import React, { useContext } from "react";
import { Select } from "antd";
import { I18nContext } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";
import "./selectOption.scss";

const { Option } = Select;

interface Props {
  id: number | string;
  handelChange: (valueId: string) => void;
  onSearch: (valueId: string) => void;
  listData: any;
  modeAd: string;
  message: string;
  keyPrint: string;
  messageCreate: string;
  showModal: () => void;
}

interface Mode {
  [key: string]: JSX.Element;
}

const SelectOption = ({
  id,
  handelChange,
  onSearch,
  listData,
  keyPrint,
  message,
  modeAd,
  messageCreate,
  showModal = () => "",
}: Props) => {
  const { i18n } = useContext(I18nContext);

  const createElement = (menu: any) => {
    return messageCreate === "" ? (
      <>{menu}</>
    ) : (
      <div>
        {menu}
        <div>
          <button className="select-create" type="button" onClick={showModal}>
            <p>
              <PlusOutlined /> {messageCreate}
            </p>
          </button>
        </div>
      </div>
    );
  };

  const mode: Mode = {
    createAd: (
      <Select
        value={id ? id.toString() : ""}
        onChange={handelChange}
        showSearch
        onSearch={onSearch}
        placeholder="Select"
        dropdownRender={createElement}
        filterOption={false}
      >
        {listData.map((item: any) => (
          <Option key={item.id} value={item.id.toString()}>
            {item[keyPrint]}
          </Option>
        ))}
      </Select>
    ),
    editAd: (
      <Select
        defaultValue={`${id}`}
        onChange={handelChange}
        showSearch
        onSearch={onSearch}
        dropdownRender={createElement}
        filterOption={false}
        placeholder="Select"
      >
        {listData.map((item: any) => (
          <Option key={item.id} value={item.id.toString()}>
            {item[keyPrint]}
          </Option>
        ))}
      </Select>
    ),
  };

  return (
    <>
      <p>{i18n.t(message)}</p>
      {mode[modeAd]}
    </>
  );
};

export default SelectOption;
