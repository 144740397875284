import React, { useContext } from "react";
import { Col, Table } from "antd";
import { I18nContext } from "react-i18next";
import "./SegmentationTable.scss";
import { StreamerAgreement } from "../../../../services/Agreements/types";
import { Pagination } from "./types";
import getColumns from "./getColumns";
import TagsFilter from "./TagsFilter";
import OptionsFilter from "./OptionsFilter";
import ViewersFilter from "./ViewersFilter";
import CountryFilter from "./CountryFilter";
import { Countries } from "../../../../services/Country/types";

type ChannelTableProps = {
  pagination: Pagination;
  rawStreamers: StreamerAgreement[];
  setRawStreamerList: (v: StreamerAgreement[]) => void;
  countries: Countries[];
  displayNameFilter: string[];
  setdisplayNameFilter: (v: string[]) => void;
  twitchIdFilter: string[];
  settwitchIdFilter: (v: string[]) => void;
  countriesFilter: string[];
  setCountriesFilter: (v: string[]) => void;
  status: { included: boolean; excluded: boolean };
  setStatus: (v: { included: boolean; excluded: boolean }) => void;
  viewersFilter: { from: number; to: number };
  setViewersFilter: (v: { from: number; to: number }) => void;
  allSelected: boolean;
  setAllSelected: (v: boolean) => void;
};
const SegmentationTable = ({
  pagination,
  countries,
  rawStreamers,
  displayNameFilter,
  setdisplayNameFilter,
  twitchIdFilter,
  settwitchIdFilter,
  status,
  setStatus,
  viewersFilter,
  setViewersFilter,
  countriesFilter,
  setCountriesFilter,
  allSelected,
  setAllSelected,
  setRawStreamerList,
}: ChannelTableProps): JSX.Element => {
  const { i18n } = useContext(I18nContext);

  const checkFiltersLists = () => {
    let filtered = rawStreamers;

    if (displayNameFilter.length > 0) {
      filtered = filtered.filter((el) =>
        displayNameFilter.includes(el.displayName.toLowerCase())
      );
    }

    if (twitchIdFilter.length > 0) {
      filtered = filtered.filter((el) => twitchIdFilter.includes(el.twitchId));
    }

    if (status.included || status.excluded) {
      const excludedStreamers = status.excluded
        ? filtered.filter((el) => !el.hasAgreement)
        : [];
      const includedStreamers = status.included
        ? filtered.filter((el) => el.hasAgreement)
        : [];

      filtered = excludedStreamers.concat(includedStreamers);
    }

    if (viewersFilter.from > -1 && viewersFilter.to >= viewersFilter.from) {
      filtered = filtered.filter(
        (el) =>
          el.avgViewers >= viewersFilter.from &&
          el.avgViewers <= viewersFilter.to
      );
    }

    if (countriesFilter.length > 0) {
      filtered = filtered.filter((el) =>
        countriesFilter.includes(el.countryId)
      );
    }

    return filtered;
  };

  const onUpdateStatus = (): Promise<void> => {
    return new Promise((res, rej) => {
      res();
    });
  };

  const selectAllStreamers = (v: string[], checked: boolean) => {
    const updatedRawStreamers = rawStreamers.map((el) => {
      if (v.includes(el.twitchId)) {
        const auxObject = el;
        auxObject.isChecked = checked;
        return auxObject;
      }
      return el;
    });
    setRawStreamerList(updatedRawStreamers);
  };

  const updateSingleStreamer = (v: string, checked: boolean) => {
    const updatedRawStreamers = rawStreamers.map((el) => {
      if (el.twitchId === v) {
        const auxObject = el;
        auxObject.isChecked = checked;
        return auxObject;
      }
      return el;
    });
    setRawStreamerList(updatedRawStreamers);
  };

  return (
    <>
      <Col xs={24}>
        <Table<StreamerAgreement>
          rowKey="streamerId"
          className="channel-table"
          loading={rawStreamers.length === 0}
          dataSource={checkFiltersLists()}
          pagination={{ ...pagination, showSizeChanger: false }}
          columns={getColumns({
            onUpdateStatus,
            DisplayNameFilter: (
              <TagsFilter
                placeHolder="Enter a Name"
                state={displayNameFilter}
                setState={setdisplayNameFilter}
              />
            ),
            StreamerIdFilter: (
              <TagsFilter
                placeHolder="Enter an Id"
                state={twitchIdFilter}
                setState={settwitchIdFilter}
              />
            ),
            CountryFilter: (
              <CountryFilter
                countries={countries}
                state={countriesFilter}
                setState={setCountriesFilter}
              />
            ),
            ViewersFilter: (
              <ViewersFilter
                state={viewersFilter}
                setState={setViewersFilter}
              />
            ),
            IncludedFilter: (
              <OptionsFilter state={status} setState={setStatus} />
            ),
            allSelected,
            setAllSelected,
            selectAllStreamers,
            checkFiltersLists,
            updateSingleStreamer,
          })}
        />
      </Col>
    </>
  );
};

export default SegmentationTable;
