import { Button, Input } from "antd";
import React, { useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import "./StringFilter.scss";
import { channelFilters } from "../../../pages/ChannelsManagement/types";

type StringFilterProps = {
  state: channelFilters;
  filterProp: string;
  setState: (v: channelFilters) => void;
};

const StringFilter = ({
  state,
  filterProp,
  setState = () => undefined,
}: StringFilterProps): JSX.Element => {
  const [filter, setFilter] = useState("");

  function setSearchValue() {
    setState({ ...state, [filterProp]: filter });
    setFilter("");
  }

  function clearFilter() {
    setFilter("");
  }

  return (
    <div className="tags-filter-container">
      <Input
        placeholder="search"
        value={filter}
        onChange={(e) => {
          setFilter(e.target.value);
        }}
      />
      <div className="buttons">
        <Button
          className="okButton"
          type="primary"
          onClick={() => {
            setSearchValue();
          }}
        >
          <SearchOutlined /> Search
        </Button>
        <Button className="clearButton" onClick={() => clearFilter()}>
          Reset
        </Button>
        <div className="centerFilterText">Filter</div>
      </div>
    </div>
  );
};

export default StringFilter;
