import React, { useContext, useRef, useState } from "react";
import { I18nContext } from "react-i18next";
import "./contentAd.scss";

import { Card, Input, Button, Row, Alert } from "antd";
// Types
import { Ad, AdLandingData } from "../../../services/Ad/type";

import VideoAd from "../VideoAd/VideoAd";
import ImageAd from "../ImageAd/ImageAd";

import { validateString } from "../../../utils/regex";

const { TextArea } = Input;

interface Props {
  setEditAd: (editAd: boolean) => void;
  setEditLanding: (edit: boolean) => void;
  setAd: (ad: Ad) => void;
  setAdLanding: (adLanding: AdLandingData) => void;
  ad: Ad;
  adLanding: AdLandingData;
  editLanding: boolean;
  modeAdType: string;
  setSelectMode: (step: number) => void;
  setImg: (file: any) => void;
  setVideo: (file: any) => void;
}

const ContentAd = ({
  setEditAd,
  setEditLanding,
  setAd,
  setAdLanding,
  ad,
  adLanding,
  editLanding,
  modeAdType,
  setSelectMode,
  setVideo,
  setImg,
}: Props) => {
  const { i18n } = useContext(I18nContext);

  const hidenInput = useRef<any>(null);
  const hidenInputVideo = useRef<any>(null);

  const [regexError, setRegexError] = useState(false);

  const handelChangeAd = (e: any) => {
    const { name, value } = e.target;
    setAd({ ...ad, [name]: value });
    setEditAd(true);
  };

  const handelChangeAdLandiing = (e: any) => {
    const { name, value } = e.target;
    setAdLanding({ ...adLanding, [name]: value });
    setEditLanding(true);
  };

  const handelFileImgAndVideo = (
    e: any,
    callback: (url: any) => void,
    type: string,
    setFile: (file: any) => void
  ) => {
    if (e.target.files.length > 0) {
      setFile(e.target.files[0]);
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          callback({ ...ad, [type]: reader.result });
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handelClick = () => {
    hidenInput.current.click();
  };

  const handelClickVideo = () => {
    hidenInputVideo.current.click();
  };

  const validateInputs = () => {
    const validateRegex = validateString(ad.chatMessage, "chatMessage");

    if (
      ad.defaultMessage !== "" &&
      validateRegex &&
      adLanding.newUrl !== "" &&
      (ad.overlayUrl !== "" || ad.videoUrl !== "")
    ) {
      return (
        <Button onClick={() => setSelectMode(4)} type="primary">
          {i18n.t("pages.createAd.btns.next")}
        </Button>
      );
    }

    return null;
  };

  const messageAlert = () => {
    return (
      <p className="text-alert-message">
        {" "}
        {i18n.t("components.editAd.contentAd.message.info")}
      </p>
    );
  };

  return (
    <div className="content-creativity">
      <Card className="card-content-creativity">
        <div className="edit-content-creativity">
          <div>
            <p>{i18n.t("components.editAd.contentAd.url")}</p>
            <Input
              name="newUrl"
              onChange={handelChangeAdLandiing}
              defaultValue={editLanding ? adLanding.newUrl : adLanding.url}
              placeholder={i18n.t(
                "components.editAd.contentAd.message.urlPlaceholder"
              )}
            />
          </div>
          <div>
            <p>{i18n.t("components.editAd.contentAd.shortMessage")}</p>
            <TextArea
              rows={2}
              name="defaultMessage"
              defaultValue={ad.defaultMessage}
              maxLength={100}
              onChange={handelChangeAd}
              placeholder={i18n.t(
                "components.editAd.contentAd.message.shortMessagePlaceholder"
              )}
            />
            <p className="counter">{ad.defaultMessage.length}/100</p>
          </div>
          <div>
            <Alert message={messageAlert()} type="info" banner />
            <p>{i18n.t("components.editAd.contentAd.chatMessage")}</p>
            <TextArea
              rows={4}
              name="chatMessage"
              autoSize={false}
              defaultValue={ad.chatMessage}
              maxLength={247}
              onChange={handelChangeAd}
              placeholder={i18n.t(
                "components.editAd.contentAd.message.chatMessagePlaceholder"
              )}
            />
            <div className="content-message">
              <p className="error-message">
                {validateString(ad.chatMessage, "chatMessage") &&
                ad.chatMessage !== ""
                  ? null
                  : i18n.t("components.editAd.contentAd.message.error")}
              </p>
              <p className="counter"> {ad.chatMessage.length}/247</p>
            </div>
          </div>
        </div>
        <div className="img-video-creativity">
          <div className="img-content">
            <p>{i18n.t("components.editAd.contentAd.image")} 16:9</p>
            <Card className="card-img">
              <ImageAd
                handelClick={handelClick}
                handelFileImgAndVideo={handelFileImgAndVideo}
                setImgPreview={setAd}
                hidenInput={hidenInput}
                imageUrl={ad.overlayUrl}
                modeType={modeAdType}
                setImg={setImg}
              />
            </Card>
          </div>
          <div className="img-content">
            <p>{i18n.t("components.editAd.contentAd.video")} 16:9</p>
            <Card className="card-img">
              <VideoAd
                hidenInputVideo={hidenInputVideo}
                videoUrl={ad.videoUrl}
                modeType={modeAdType}
                handelClick={handelClickVideo}
                setVideoPreview={setAd}
                handelFileImgAndVideo={handelFileImgAndVideo}
                setVideo={setVideo}
              />
            </Card>
          </div>
        </div>
      </Card>
      {modeAdType === "createAd" ? (
        <Row className="positon-btn-segmentation">
          <Button onClick={() => setSelectMode(2)}>
            {i18n.t("pages.createAd.btns.back")}
          </Button>
          {validateInputs()}
        </Row>
      ) : null}
    </div>
  );
};

export default ContentAd;
