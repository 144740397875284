import React, { useContext, useCallback } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "./navbarAd.scss";
import { I18nContext } from "react-i18next";
import { useMappedState, useDispatch } from "redux-react-hook";
import { adPathCreate } from "../../../store/ad/actions";

interface Props {
  setShowModal: (show: boolean) => void;
}

const NavBarAd = ({ setShowModal }: Props) => {
  const { i18n } = useContext(I18nContext);
  const dispatch = useDispatch();

  const mapState = useCallback(
    (state: any) => ({
      editAd: state.ad.editAd.editAd,
      createAd: state.ad.createAd.createAd,
    }),
    []
  );
  const { createAd } = useMappedState(mapState);
  const handelClick = () => {
    if (createAd) {
      dispatch(adPathCreate({ createAd: false }));
    } else {
      setShowModal(true);
    }
  };

  return (
    <div className="container-nav-bar-ad">
      <div className="wrapper-go-back">
        <button className="go-back" type="button" onClick={handelClick}>
          <ArrowLeftOutlined /> {i18n.t("components.navbarAd.back")}
        </button>
      </div>
      <div className="header">
        <p>
          {createAd
            ? i18n.t("components.navbarAd.titleCreate")
            : i18n.t("components.navbarAd.title")}
        </p>
      </div>
    </div>
  );
};

export default NavBarAd;
