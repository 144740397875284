import React, { useState } from "react";
import Navbar from "../Layout/Navbar";
import EdditAd from "../../pages/EditAd/EditAd";
import NavBarAd from "./NavbarAd/NavbarAd";
import CreateAd from "../../pages/CreateAd/CreateAd";

interface Props {
  createAd: boolean;
  editAd: boolean;
}

const LayoutAd = ({ createAd, editAd }: Props) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <div className="dashboard-layout">
      <div className="dashboard-layout__top-navigation">
        <Navbar
          handleToggleCollapsed={() => false}
          isCollapsed={false}
          editAd
        />
        <NavBarAd setShowModal={setShowModal} />
        <div className="dashboard-layout-edit-ad">
          {createAd ? (
            <CreateAd showModal={showModal} setShowModal={setShowModal} />
          ) : (
            <EdditAd showModal={showModal} setShowModal={setShowModal} />
          )}
        </div>
      </div>
    </div>
  );
};

export default LayoutAd;
