import React, { useState, useContext } from "react";
import { I18nContext } from "react-i18next";
import "./revision.scss";
import { Card, Row, Image, Button, Spin, message } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import moment from "moment";
import { useDispatch } from "redux-react-hook";
import { adPathEdit, adPathCreate } from "../../../store/ad/actions";
import {
  Ad,
  AdLandingData,
  Advertiser,
  CampaignData,
  formatDate,
  formatTime,
  CountryData,
} from "../../../services/Ad/type";
import { putAd, postAd, postCreativityAd } from "../../../services/Ad/Ad";
import { updateCampaign } from "../../../services/Campaign/ActiveCampaign";
import {
  updateAdLanding,
  createAdLanding,
} from "../../../services/AdLanding/AdLanding";
import { StreamerAgreement } from "../../../services/Agreements/types";
import { putAgreements } from "../../../services/Agreements/Agreements";
import { validateString } from "../../../utils/regex";

interface Props {
  editAd: boolean;
  editCampaign: boolean;
  editLanding: boolean;
  ad: Ad;
  campaign: CampaignData;
  advertiser: Advertiser;
  adLanding: AdLandingData;
  country: CountryData;
  setEditAd: (edit: boolean) => void;
  setEditCampaign: (edit: boolean) => void;
  setEditLanding: (edit: boolean) => void;
  agreementList: StreamerAgreement[];
  editAgreement: boolean;
  setEditAgreement: (v: boolean) => void;
  setSelectMode: (step: number) => void;
  modeAdType: string;
  image: any;
  video: any;
}

interface ModeBtns {
  [key: string]: JSX.Element;
}

const Revision = ({
  editAd,
  editCampaign,
  editLanding,
  ad,
  campaign,
  advertiser,
  adLanding,
  country,
  setEditAd,
  setEditCampaign,
  setEditLanding,
  agreementList,
  editAgreement,
  setEditAgreement,
  modeAdType,
  setSelectMode,
  image,
  video,
}: Props) => {
  const { i18n } = useContext(I18nContext);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const messageError = (messageEr: string) => {
    message.error({
      content: i18n.t(messageEr),
      className: "message",
      duration: 5,
    });
  };

  const validatrAd = () => {
    if (moment(ad.startDate).isAfter(ad.endDate)) {
      messageError("components.editAd.revision.message.errorDate");
      return true;
    }

    if (ad.cpm === 0 || ad.budget === 0) {
      messageError("components.editAd.revision.message.errorBudgetCpm");
      return true;
    }

    if (ad.name.trim() === "") {
      messageError("components.editAd.revision.message.errorAdName");
      return true;
    }

    if (!validateString(ad.chatMessage, "chatMessage")) {
      messageError("components.editAd.revision.message.errorChatMessage");
      return true;
    }
    return false;
  };

  const adLandingValidate = () => {
    if (adLanding.newUrl === "") {
      messageError("components.editAd.revision.message.errorUrl");
      return true;
    }
    return false;
  };

  const submitData = async () => {
    try {
      if (!editAd && !editCampaign && !editLanding && !editAgreement) {
        message.warning({
          content: i18n.t("components.editAd.revision.message.warning"),
          className: "message",
          duration: 5,
        });
        return;
      }

      setLoading(true);

      if (editAd) {
        const validateAd = validatrAd();
        console.log({ validateAd });
        if (!validateAd) {
          await putAd(ad);
        } else {
          return;
        }
      }

      if (editAgreement) {
        await putAgreements(
          ad.id,
          agreementList.filter((el) => el.hasAgreement).map((el) => el.id)
        );
      }

      if (editCampaign) {
        await updateCampaign(campaign);
      }

      if (editLanding) {
        const validate = adLandingValidate();
        if (!validate) {
          await updateAdLanding(adLanding);
        } else {
          return;
        }
      }

      message.success({
        content: i18n.t("components.editAd.revision.message.success"),
        className: "message",
        duration: 5,
      });
      setEditAd(false);
      setEditCampaign(false);
      setEditLanding(false);
      setEditAgreement(false);
      dispatch(adPathEdit({ adId: 0, editAd: false }));
    } catch (err) {
      console.log(err);
      messageError("components.editAd.revision.message.errorUpadate");
    } finally {
      setLoading(false);
    }
  };

  const launchAd = async () => {
    try {
      setLoading(true);
      const { id, ...newAd } = ad;
      newAd.active = false;
      const formData = new FormData();

      if (image !== "") {
        formData.append("overlay", image);
        newAd.overlayUrl = "In processing";
      }
      if (video !== "") {
        formData.append("video", video);
        newAd.videoUrl = "In processing";
      }
      const adCreateId = await postAd(newAd);
      await createAdLanding({ adId: adCreateId, url: adLanding.newUrl });
      await postCreativityAd(adCreateId, formData);
      if (ad.isAgreementRequired) {
        await putAgreements(
          adCreateId,
          agreementList.filter((el) => el.hasAgreement).map((el) => el.id)
        );
      }
      dispatch(adPathCreate({ createAd: false }));
    } catch (err) {
      console.log(err);
      messageError("components.editAd.revision.message.errorCreateAd");
    } finally {
      setLoading(false);
    }
  };

  const modeBtns: ModeBtns = {
    createAd: !loading ? (
      <Row className="revision-position-btn">
        <Button onClick={() => setSelectMode(3)}>
          {i18n.t("pages.createAd.btns.back")}
        </Button>
        <Button type="primary" onClick={launchAd}>
          {i18n.t("pages.createAd.btns.launchAd")}
        </Button>
      </Row>
    ) : (
      <Row className="revision-position-btn">
        <div />
        <Spin />
      </Row>
    ),
    editAd: !loading ? (
      <div className="btn-save">
        <Button onClick={submitData} type="primary">
          {i18n.t("components.editAd.revision.btnSave")}
        </Button>
      </div>
    ) : (
      <Spin />
    ),
  };

  const printMessageSegmentation = () => {
    if (ad.isAgreementRequired || ad.isAdultContent) {
      return (
        <div>
          <p>
            {" "}
            <strong>
              {i18n.t("components.editAd.revision.public.titleAge")}:
            </strong>{" "}
            {ad.isAdultContent
              ? i18n.t("components.editAd.revision.public.adultContent")
              : i18n.t("components.editAd.revision.public.notAdultContent")}
          </p>
          <p>
            <strong>
              {" "}
              {i18n.t(
                "components.editAd.revision.public.titleSegmentation"
              )}:{" "}
            </strong>
            {ad.isAgreementRequired
              ? i18n.t("components.editAd.revision.public.isAgreements")
              : i18n.t("components.editAd.revision.public.isNotAgreements")}
          </p>
        </div>
      );
    }
    return i18n.t("components.editAd.revision.public.message");
  };

  return (
    <div className="container-revison">
      <Card>
        <p className="title">
          {i18n.t("components.editAd.configAd.description.title")}
        </p>
        <div className="content">
          <p className="sub-title">
            {i18n.t("components.editAd.configAd.description.adName")}:{" "}
            <span className="data-content">{ad.name}</span>
          </p>
          <p className="sub-title">
            {i18n.t("components.editAd.configAd.description.campaignName")}:{" "}
            <span className="data-content">{campaign.title}</span>
          </p>
          <p className="sub-title">
            {i18n.t("components.editAd.configAd.description.advertiser")}:{" "}
            <span className="data-content">{advertiser.name}</span>
          </p>
          <p className="sub-title">
            {i18n.t("components.editAd.configAd.description.country")}:{" "}
            <span className="data-content">{country.code}</span>
          </p>
        </div>
      </Card>
      <Card>
        <p className="title">
          {i18n.t("components.editAd.configAd.budgetAndProgramming.title")}
        </p>
        <Row className="budget-content">
          <div className="content">
            <p className="sub-title">
              {i18n.t("components.editAd.configAd.budgetAndProgramming.budget")}
            </p>
            <p className="data-content">{ad.budget}€</p>
          </div>
          <div className="content">
            <p className="sub-title">
              {" "}
              {i18n.t("components.editAd.configAd.budgetAndProgramming.cpm")}
            </p>
            <p className="data-content">{ad.cpm}</p>
          </div>
          <div className="icon">
            <ArrowRightOutlined />
          </div>
          <div className="content">
            <p className="sub-title">
              {i18n.t(
                "components.editAd.configAd.budgetAndProgramming.viewsResult"
              )}
            </p>
            <p className="data-content">{(ad.budget / ad.cpm) * 1000}</p>
          </div>
        </Row>
        <div className="content">
          <p className="sub-title">
            {i18n.t("components.editAd.revision.schedule")}
          </p>
          <p className="data-content">
            Del {moment(ad.startDate).format(formatDate)} a las{" "}
            {moment(ad.startDate).format(formatTime)} hasta el{" "}
            {moment(ad.endDate).format(formatDate)} a las{" "}
            {moment(ad.endDate).format(formatTime)}
          </p>
        </div>
      </Card>
      <Card>
        <p className="title">
          {i18n.t("components.editAd.revision.public.title")}
        </p>
        <Card>{printMessageSegmentation()}</Card>
      </Card>
      <Card>
        <p className="title">{i18n.t("components.editAd.contentAd.title")}</p>
        <div className="content">
          <p className="content-wrapper">
            {i18n.t("components.editAd.contentAd.shortMessage")}:{" "}
            <span className="data-content">{ad.defaultMessage}</span>
          </p>
          <p className="content-wrapper">
            {i18n.t("components.editAd.contentAd.chatMessage")}:{" "}
            <span className="data-content">{ad.chatMessage}</span>
          </p>
          <p className="content-wrapper">
            {i18n.t("components.editAd.contentAd.url")}:{" "}
            <span className="data-content">
              {editLanding ? adLanding.newUrl : adLanding.url}
            </span>
          </p>
        </div>
        <Row className="data-img">
          <div className="img-content">
            <p>Image 16:9</p>
            <Card className="card-img">
              <Image src={ad.overlayUrl} />
            </Card>
          </div>
          <div className="img-content video-content">
            <p>Video 16:9</p>
            <Card className="card-img">
              <video controls>
                <track kind="captions" default src={ad.videoUrl} />
                <source src={ad.videoUrl} />
                Sorry, your browser doesnt support embedded videos.
              </video>
            </Card>
          </div>
        </Row>
      </Card>
      {modeBtns[modeAdType]}
    </div>
  );
};

export default Revision;
