import React, { useEffect, useState, useContext } from "react";

import "./viewersFrequecy.scss";
import { Button, Table, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { I18nContext } from "react-i18next";
import {
  getIntervalMinutes,
  getAllViewersFrequency,
  postViewersFrequency,
  deletedViewersFrequency,
  putViewersFrequencie,
  createStopper,
  updateStopper,
} from "../../services/FrequencyManagement/FrequencyManagement";
import { ViewersFrequency } from "../../services/FrequencyManagement/types";

import columnsViewersFrequency from "./ColumnsViewersFrequency";

const newViewersFrequency: ViewersFrequency = {
  delete: false,
  id: "newViewersFrequency",
  edit: true,
  intervalSeconds: 0,
  viewers: 0,
  getIntervalMinutes,
};

const ViewerFrequency = () => {
  const { i18n } = useContext(I18nContext);

  const printText = {
    btnDelete: i18n.t("components.viewersFrequency.table.btn_deleted"),
    viewers: i18n.t("components.viewersFrequency.table.viewers"),
    frequency: i18n.t("components.viewersFrequency.table.frequency"),
  };

  const [prevState, setPrevState] = useState<Array<ViewersFrequency>>([]);
  const [viewersFrequencies, setViewersFrequencies] = useState<
    Array<ViewersFrequency>
  >([]);
  const [disabledAddViewersFrequency, setDisabledViewersFrequency] = useState<
    boolean
  >(false);

  const getViewersFrequencies = () => {
    getAllViewersFrequency()
      .then((viewrsFrequencyData: ViewersFrequency[]) => {
        setViewersFrequencies(viewrsFrequencyData);
        setPrevState(viewrsFrequencyData);
      })
      .catch((err: any) => console.log(err));
  };

  useEffect(() => {
    getViewersFrequencies();
  }, []);

  const messageError = (content: string) => {
    return message.error({
      content,
      className: "message",
      duration: 5,
    });
  };

  const addNewViewersFrequency = () => {
    setDisabledViewersFrequency(true);
    setViewersFrequencies([newViewersFrequency, ...viewersFrequencies]);
  };

  const edditViewersFrequency = (viewersFrequencyId: string) => {
    const viewersFrequencyEdit: ViewersFrequency[] = [];
    if (viewersFrequencyId === "newViewersFrequency") {
      viewersFrequencies.splice(0, 1);
      setDisabledViewersFrequency(false);
      return setViewersFrequencies([...viewersFrequencies]);
    }

    viewersFrequencies.forEach((viewrsFrequency: ViewersFrequency) => {
      if (viewrsFrequency.id === viewersFrequencyId) {
        const editViewersFrequency: ViewersFrequency = {
          ...viewrsFrequency,
          edit: !viewrsFrequency.edit,
          delete: !viewrsFrequency.delete,
        };
        viewersFrequencyEdit.push(editViewersFrequency);
      } else {
        viewersFrequencyEdit.push(viewrsFrequency);
      }
    });
    return setViewersFrequencies(viewersFrequencyEdit);
  };

  const onChangeViewers = (
    viewrs: string | number | undefined,
    viewersFrequencyId: string
  ) => {
    const viewersFrquenciesList = [...viewersFrequencies];
    const indexViewersFrequency = viewersFrquenciesList.findIndex(
      (viewersFrequency) => viewersFrequency.id === viewersFrequencyId
    );
    viewersFrquenciesList.splice(indexViewersFrequency, 1, {
      ...viewersFrquenciesList[indexViewersFrequency],
      viewers: Number(viewrs),
    });
    setViewersFrequencies(viewersFrquenciesList);
  };

  const onChangeFrequency = (
    frequency: string | number | undefined,
    viewersFrequency: ViewersFrequency
  ) => {
    const viewersFrquenciesList = [...viewersFrequencies];
    const indexViewersFrequency = viewersFrquenciesList.findIndex(
      (viewersFrequencyInList) =>
        viewersFrequencyInList.id === viewersFrequency.id
    );

    if (frequency === "stop") {
      viewersFrquenciesList.splice(indexViewersFrequency, 1, {
        ...viewersFrequency,
        intervalSeconds: frequency,
      });
      return setViewersFrequencies(viewersFrquenciesList);
    }
    viewersFrquenciesList.splice(indexViewersFrequency, 1, {
      ...viewersFrequency,
      intervalSeconds: Number(frequency) * 60,
    });
    return setViewersFrequencies(viewersFrquenciesList);
  };

  const createViewersFrequency = async (viewersFrequency: ViewersFrequency) => {
    try {
      if (viewersFrequency.intervalSeconds === "stop") {
        await createStopper(viewersFrequency.viewers);
      } else {
        await postViewersFrequency(
          Number(viewersFrequency.intervalSeconds),
          viewersFrequency.viewers
        );
      }
      getViewersFrequencies();
      setDisabledViewersFrequency(false);
    } catch (err) {
      console.log({ err });
      messageError(i18n.t("components.viewersFrequency.message_error.create"));
      setViewersFrequencies(prevState);
    }
  };

  const updateViewersFrequency = async (viewersFrequency: ViewersFrequency) => {
    try {
      if (viewersFrequency.intervalSeconds === "stop") {
        await updateStopper(viewersFrequency.id, viewersFrequency.viewers);
      } else {
        await putViewersFrequencie(
          viewersFrequency.id,
          viewersFrequency.viewers,
          Number(viewersFrequency.intervalSeconds)
        );
      }

      getViewersFrequencies();
    } catch (err) {
      console.log({ err });
      messageError(i18n.t("components.viewersFrequency.message_error.update"));
      setViewersFrequencies(prevState);
    }
  };

  const deleteViewersFrequency = async (viewersFrequencyId: string) => {
    try {
      const res = await deletedViewersFrequency(viewersFrequencyId);
      getViewersFrequencies();
    } catch (err) {
      console.log({ err });
      messageError(i18n.t("components.viewersFrequency.message_error.deleted"));
      setViewersFrequencies(prevState);
    }
  };

  return (
    <div className="container-stopper">
      <div className="header-stopper">
        <div className="wrapper">
          <h1 className="section-tittle">
            {i18n.t("components.viewersFrequency.title")}
          </h1>
          <p>{i18n.t("components.viewersFrequency.sub_title")}</p>
        </div>
        <div className="add-stopper">
          <Button
            type="primary"
            disabled={disabledAddViewersFrequency}
            onClick={addNewViewersFrequency}
          >
            <PlusOutlined /> {i18n.t("components.viewersFrequency.button")}
          </Button>
        </div>
      </div>
      <div className="wrapper-table-stopper">
        <Table
          dataSource={viewersFrequencies}
          columns={columnsViewersFrequency({
            edditViewersFrequency,
            createViewersFrequency,
            updateViewersFrequency,
            deleteViewersFrequency,
            onChangeViewers,
            onChangeFrequency,
            printText,
          })}
          size="middle"
        />
      </div>
    </div>
  );
};

export default ViewerFrequency;
