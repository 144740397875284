import createGetFn from "../get";
import createPutFn from "../put";
import { ModerationTable, RequestStatus, UpdateStatusActions } from "./type";

export const baseUrl = `${window._env_.SERVER_URL}/admin/moderation`;

const get = createGetFn(baseUrl, {
  normalize: (res: { data: ModerationTable }) => res.data,
});

const put = createPutFn(baseUrl, {
  normalize: (res: { data: unknown }) => res.data,
});

export const getModerationData = (query: string): Promise<ModerationTable> =>
  get(query);

export const updateEmissionProofStatus = (
  id: string,
  status: RequestStatus
): Promise<void> => put(`${id}/${UpdateStatusActions[status]}`, {});
