import React from "react";
import { Table } from "antd";

interface Props {
  columns: any;
  dataToPrint: any;
  rowSelection: any | null;
  loading: boolean;
}

const DynamicTable = ({
  columns,
  dataToPrint,
  rowSelection = null,
  loading = false,
}: Props) => {
  return (
    <Table
      rowSelection={rowSelection}
      columns={columns}
      dataSource={dataToPrint}
      scroll={{ x: 1300 }}
      size="middle"
      pagination={{ defaultPageSize: 6 }}
      loading={loading}
    />
  );
};

export default DynamicTable;
