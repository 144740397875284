import React, { ReactNode } from "react";
import { Modal as AntDesignModal } from "antd";
import "./modal.scss";

interface Props {
  children: ReactNode;
  modalSize: "eager" | "jumbo" | "big" | "small" | "s" | "xs";
  className: string;
  visible: boolean;
  onCancel: () => void;
  onOk: () => void;
  okText: "Ok" | string;
  cancelText: "cancel" | string;
}

const Modal = ({
  children,
  className,
  visible,
  modalSize,
  onCancel,
  onOk,
  okText,
  cancelText,
}: Props) => {
  const getClassName = () => {
    const classNames = ["modal"];

    if (modalSize) classNames.push(`modal--size-${modalSize}`);
    if (className) classNames.push(className);

    return classNames.join(" ");
  };

  return (
    <AntDesignModal
      onCancel={onCancel}
      onOk={onOk}
      visible={visible}
      className={getClassName()}
      okText={okText}
      cancelText={cancelText}
    >
      {children}
    </AntDesignModal>
  );
};

export default Modal;
