import createGetFn from "../get";
import createPutFn from "../put";

import { Countries } from "./types";

const baseUrl = `${window._env_.SERVER_URL}/admin/country`;

const get = createGetFn(baseUrl, {
  normalize: (res: any): Countries[] => {
    const countries: Countries[] = res.data.map((country: any) => {
      return {
        id: country.id,
        code: country.code,
      };
    });
    return countries;
  },
});

export const getCountry = () => get("");
