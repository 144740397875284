import React, { useContext, useCallback, useState } from "react";
import { useMappedState, useDispatch } from "redux-react-hook";
import { Switch } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { I18nContext } from "react-i18next";
import { ActiveCampaign, Ad } from "../../../services/Campaign/type";
import { adPathEdit } from "../../../store/ad/actions";

import "./infoCampaign.scss";

interface Props {
  ad: Ad;
  showSwitch: boolean;
  statusAd: (e: boolean, ad: Ad, campaignName: string) => void;
  campaignName: string;
}

const InfoCampaign = ({ ad, showSwitch, statusAd, campaignName }: Props) => {
  const { i18n } = useContext(I18nContext);
  const dispatch = useDispatch();

  const fractionDigits = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  };

  const handelChange = (event: boolean) => {
    statusAd(event, ad, campaignName);
  };

  const handelClick = () => {
    dispatch(adPathEdit({ editAd: true, adId: ad.adId }));
  };

  const isFutureAd = (adDetails: Ad) => {
    return new Date(adDetails.adStartDate) > new Date();
  };

  const isFutureActiveAd = (adDetails: Ad) => {
    return adDetails.adActive ? "futureCampaingOn" : "futureCampaingOff";
  };

  const showPlatformSelected = (twitch: boolean, youtube: boolean) => {
    if (twitch && youtube) return "Twitch, Youtube";
    if (youtube) return "Youtube";
    return "Twitch";
  };

  return (
    <div className="container-expanded">
      <div className="content-expanded">
        <div className="first-message">
          <button type="button" onClick={handelClick}>
            <span className="ad-id"> {`${ad.adId}`} </span>
            {` ${ad.adName}`} <EditOutlined />{" "}
          </button>
          <div className="platform-text">
            {showPlatformSelected(ad.twitch, ad.youtube)}
          </div>
        </div>
        <div className="column-info-expanded">
          <div className="section-info-expanded">
            <p>{`${i18n.t(
              "pages.activeCampaigns.detail.idealDailyDelivery"
            )}`}</p>
            <p>
              {new Intl.NumberFormat("de-DE", fractionDigits).format(
                ad.idealAverageDailyDelivery
              )}
            </p>
          </div>
          <div className="section-info-expanded">
            <p>{`${i18n.t(
              "pages.activeCampaigns.detail.realDailyDeliveryPending"
            )}`}</p>
            <p>
              {new Intl.NumberFormat("de-DE", fractionDigits).format(
                ad.remainingAverageDailyDelivery
              )}
            </p>
          </div>
        </div>
        <div className="column-info-expanded">
          <div className="section-info-expanded">
            <p>{`${i18n.t(
              "pages.activeCampaigns.detail.servedDailyViews"
            )}`}</p>
            <p>
              {new Intl.NumberFormat("de-DE", fractionDigits).format(
                ad.todayConsumed
              )}
            </p>
          </div>
          <div className="section-info-expanded">
            <p>{`${i18n.t(
              "pages.activeCampaigns.detail.percentageDailyServed"
            )}`}</p>
            <p>
              {new Intl.NumberFormat("de-DE", fractionDigits).format(
                ad.percentageDeliveredToday
              )}
              %
            </p>
          </div>
        </div>
        <div className="final-column-info-expanded">
          <div className="section-info-expanded">
            <p>{`${i18n.t(
              "pages.activeCampaigns.detail.remainingDailyViews"
            )}`}</p>
            <p>
              {new Intl.NumberFormat("de-DE", fractionDigits).format(
                ad.pendingViews
              )}
            </p>
          </div>
        </div>
        <div className="change-status-ad">
          <span className={isFutureAd(ad) ? isFutureActiveAd(ad) : ""}>
            {showSwitch ? (
              <Switch onClick={handelChange} checked={ad.adActive} />
            ) : null}
          </span>
        </div>
      </div>
      {console.log(ad)}
    </div>
  );
};

export default InfoCampaign;
