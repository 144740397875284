import moment from "moment";
import { QueryFormatter } from "../../components/ModerationTable/type";

export function queryFormatter({
  pagination,
  dateRange,
  twitchDisplayNames,
  adMetricIds,
  campaignId,
  status,
}: QueryFormatter): string {
  let query = "?";
  if (pagination.page && pagination.pageSize) {
    query += `page=${pagination.page}&limit=${pagination.pageSize}`;
  }
  if (dateRange) {
    const startDate = moment(dateRange[0]).format("YYYY-MM-DD");
    const endDate = moment(dateRange[1]).format("YYYY-MM-DD");
    if (query) query += "&";
    query += `startDate=${startDate}&endDate=${endDate}`;
  }
  if (twitchDisplayNames.length) {
    if (query) query += "&";
    const stringifiedTwitchDisplayNames = JSON.stringify(twitchDisplayNames);
    query += `twitchDisplayNames=${stringifiedTwitchDisplayNames}`;
  }
  if (adMetricIds.length) {
    if (query) query += "&";
    const numericAdMetricIds = adMetricIds.map(Number);
    const stringifiedAdMetricIds = JSON.stringify(numericAdMetricIds);
    query += `adMetricsIds=${stringifiedAdMetricIds}`;
  }
  if (campaignId.length) {
    if (query) query += "&";
    const numericCampaignIdS = campaignId.map(Number);
    const stringifiedCampaignIdS = JSON.stringify(numericCampaignIdS);
    query += `campaignIds=${stringifiedCampaignIdS}`;
  }
  if (status.some(({ checked }) => checked)) {
    const checkedStatus = status.reduce<number[]>(
      (accumulator, { checked, value }) => {
        if (checked) accumulator.push(value);
        return accumulator;
      },
      []
    );
    const stringifiedCheckedStatus = JSON.stringify(checkedStatus);
    if (query) query += "&";
    query += `status=${stringifiedCheckedStatus}`;
  }
  return query;
}
