import React, { FunctionComponent } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import Moderation from "../pages/Moderation";
import ActiveCampaigns from "../pages/ActiveCampaigns";
import FrequenciesManagement from "../pages/FrequenciesManagement/FrequenciesManagement";
import ChannelManagement from "../pages/ChannelsManagement/ChannelsManagement";
import Moderationv2 from "../pages/Moderationv2";

const MainRouterSwitch: FunctionComponent = () => (
  <>
    <Switch>
      <Route path="/moderation" component={Moderationv2} />
      <Route path="/emissions" component={Moderation} />
      <Route path="/frequencies" component={FrequenciesManagement} />
      <Route path="/channels" component={ChannelManagement} />
      <Route path="/campaigns" component={ActiveCampaigns} />
      <Redirect from="*" to="/emissions" />
    </Switch>
  </>
);

export default MainRouterSwitch;
