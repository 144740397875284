import React, { useState } from "react";
import { Input, Button } from "antd";
import { Filter } from "../../pages/FrequenciesManagement/type";
import { Tags, Props } from "./type";

const initialState = {
  "id/name": {
    column: "",
    value: "",
    name: "",
  },
  country: {
    column: "",
    value: "",
    name: "",
  },
  frequency: {
    column: "",
    value: "",
    name: "",
  },
  "min.viewers": {
    column: "",
    value: "",
    name: "",
  },
  "max.viewers": {
    column: "",
    value: "",
    name: "",
  },
};

const SearchPanelFrequencyManagement = ({
  filterSelected,
  setFilterSelected,
  onSearch,
  dataStreamers,
}: Props) => {
  const [state, setState] = useState<Tags>(initialState);
  const [disable, setDisable] = useState(true);

  const addFilter = (filter: Filter) => {
    if (filter.column === "viewers" || filter.column === "minIntervalSeconds") {
      const value = Number(filter.value);
      if (!Number.isNaN(value)) {
        setState({ ...state, [filter.name.toLowerCase()]: filter });
      }
    } else {
      setState({ ...state, [filter.name.toLowerCase()]: filter });
    }
    setDisable(false);
  };

  const onClick = () => {
    const objKeys = Object.keys(state);
    const listOfFilters: Filter[] = [...filterSelected];
    objKeys.forEach((key: string) => {
      const { value } = state[key];
      if (typeof value === "string") {
        if (value.trim() !== "" && value.trim() !== " ") {
          const includeObj = listOfFilters.find(
            (oldFilter) =>
              oldFilter.column === state[key].column &&
              oldFilter.value === state[key].value
          );
          if (!includeObj) {
            listOfFilters.push(state[key]);
          }
        }
      }
    });
    setFilterSelected(listOfFilters);
    setDisable(true);
    onSearch(dataStreamers, listOfFilters);
    setState(initialState);
  };

  return (
    <div className="wrapper-search">
      <div className="search-block">
        <Input
          size="large"
          placeholder="ID/Name"
          value={state["id/name"].value}
          onChange={(e) =>
            addFilter({
              name: "ID/Name",
              value: e.target.value,
              column: "ID/Name",
            })
          }
          style={{ width: 318 }}
        />
        <Input
          size="large"
          placeholder="Country"
          value={state.country.value}
          onChange={(e) =>
            addFilter({
              name: "Country",
              value: e.target.value,
              column: "country",
            })
          }
          style={{ width: 154 }}
        />
      </div>
      <div className="search-set">
        <Input
          size="large"
          placeholder="Current Frequency"
          value={state.frequency.value}
          onChange={(e: any) =>
            addFilter({
              name: "Frequency",
              value: e.target.value,
              column: "minIntervalSeconds",
            })
          }
          style={{ width: 220 }}
        />
        <div className="search-line">
          <Input
            size="large"
            placeholder="Min. viewers"
            value={state["min.viewers"].value}
            onChange={(e) =>
              addFilter({
                name: "Min.viewers",
                value: e.target.value,
                column: "viewers",
              })
            }
            style={{ width: 170 }}
          />
          <span>to</span>
          <Input
            size="large"
            placeholder="Max. viewers"
            value={state["max.viewers"].value}
            onChange={(e) =>
              addFilter({
                name: "Max.viewers",
                value: e.target.value,
                column: "viewers",
              })
            }
            style={{ width: 170 }}
          />
        </div>

        <Button
          className="ant-btn ant-btn-primary"
          size="small"
          onClick={onClick}
          disabled={disable}
        >
          Search
        </Button>
      </div>
    </div>
  );
};

export default SearchPanelFrequencyManagement;
