import { EDIT_AD, EditAd, CREATE_AD, CreateAd } from "./types";

export const adPathEdit = (editAd: EditAd) => ({
  type: EDIT_AD,
  payload: { ...editAd },
});

export const adPathCreate = (createAd: CreateAd) => ({
  type: CREATE_AD,
  payload: { ...createAd },
});
