import React from "react";

interface Props {
  videoUrl: string | null;
}

const Video = ({ videoUrl }: Props) => {
  return (
    <video controls>
      <track kind="captions" default src={videoUrl?.toString()} />
      <source src={videoUrl?.toString()} />
      Sorry, your browser doesnt support embedded videos.
    </video>
  );
};

export default Video;
