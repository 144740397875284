import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import "./Footer.scss";

const Footer: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <div id="footer-page">
      <div>{t("components.footer.legal")}</div>
      <div>Copyright &copy; 2020 Streamion SL</div>
    </div>
  );
};

export default Footer;
