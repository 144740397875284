/* eslint-disable no-underscore-dangle */
import { createStore, combineReducers, compose } from "redux";
import { authReducer } from "./auth";
import { adReducer } from "./ad/reducer";

const ENV = process.env.NODE_ENV;

const rootReducer = combineReducers({
  auth: authReducer,
  ad: adReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  rootReducer,
  ENV !== "production" ? composeEnhancers() : undefined
);

export type RootState = ReturnType<typeof rootReducer>;

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}
