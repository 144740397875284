import createGetFn from "../get";
import { ModerationTable } from "./type";

export const baseUrl = `${window._env_.SERVER_URL}/admin/moderation/data`;

const get = createGetFn(baseUrl, {
  normalize: (res: { data: ModerationTable }) => {
    return { rows: res.data };
  },
});

export const getModerationData = (query: string): Promise<ModerationTable> =>
  get(query);
