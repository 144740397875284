import React, { useState, useEffect, useCallback, useContext } from "react";
import { I18nContext } from "react-i18next";
import "./configAd.scss";
import {
  Card,
  Input,
  DatePicker,
  message,
  TimePicker,
  Row,
  Button,
  Alert,
} from "antd";
import { ArrowRightOutlined, EuroCircleOutlined } from "@ant-design/icons";
import moment, { Moment } from "moment";

// service
import {
  getAdvertiser,
  createAdvertiser,
} from "../../../services/Advertiser/Advertiser";
import {
  getCampaignNames,
  createCampaign,
} from "../../../services/Campaign/ActiveCampaign";

// service types
import { Advertisers } from "../../../services/Advertiser/type";
import {
  Ad,
  CampaignData,
  Advertiser,
  CountryData,
} from "../../../services/Ad/type";
import { Countries } from "../../../services/Country/types";

// Components
import {
  countryInitialState,
  advertiserInitialState,
  campaignInitialState,
} from "../../../pages/EditAd/initalState";

import SelectOption from "../SelectOption/SelectOption";
import ModalCreate from "../ModalCreate/ModalCreate";

interface Props {
  setEditAd: (editAd: boolean) => void;
  setEditCampaign: (editCampaign: boolean) => void;
  setAd: (ad: Ad) => void;
  setCampaign: (campaign: CampaignData) => void;
  setAdvertiser: (advertiser: Advertiser) => void;
  setCountry: (country: CountryData) => void;
  setSelectMode: (step: number) => void;
  ad: Ad;
  campaign: CampaignData;
  advertiser: Advertiser;
  country: CountryData;
  countriesList: Countries[];
  modeAdType: string;
}

interface TypeComponent {
  date: (typeDate: string, defaultDate?: string) => JSX.Element;
  time: (typeDate: string, defaultDate?: string | Moment) => JSX.Element;
}
interface DateMode {
  [key: string]: TypeComponent;
}

const dateFormat = "YYYY-MM-DD";
const dateFormatSave = "YYYY-MM-DDTHH:mm:ss.SSSSZ";
const timeFormat = "HH:mm:ss";

interface Date {
  [key: string]: {
    [key: string]: string;
  };
}

const ConfigAd = ({
  setEditAd,
  setEditCampaign,
  setAd,
  setCampaign,
  setAdvertiser,
  setCountry,
  ad,
  campaign,
  advertiser,
  countriesList,
  country,
  modeAdType,
  setSelectMode,
}: Props) => {
  const { i18n } = useContext(I18nContext);

  // drowpdowns
  const [advertisersList, setAdvertisersList] = useState<Array<Advertisers>>(
    []
  );
  const [campaignsList, setCampaignsList] = useState<Array<CampaignData>>([]);

  const [contriesListData, setContriesListData] = useState(countriesList);
  const [advertiserListData, setAdvertiserListData] = useState<
    Array<Advertisers>
  >([]);
  const [campaignsListData, setCampaignsListData] = useState<
    Array<CampaignData>
  >([]);

  // Errors
  const [errorDate, setErrorDate] = useState("");
  const [errorBugget, setErrorBugget] = useState("");

  // modal to create advertiser and campaign
  const [modalAdvertiser, setModalAdvertiser] = useState({
    visible: false,
    title: "pages.createAd.modal.advertiser.title",
    message: "pages.createAd.modal.advertiser.text",
    loading: false,
  });

  const [modalCampaign, setModalCampaign] = useState({
    visible: false,
    title: "pages.createAd.modal.campaign.title",
    message: "pages.createAd.modal.campaign.text",
    loading: false,
  });

  const [newAdvertiser, setNewAdvertiser] = useState("");
  const [newCampaign, setNewCampaign] = useState("");

  const [dateAd, setDateAd] = useState<Date>({
    startDate: {
      date: "",
      time: "",
    },
    endDate: {
      date: "",
      time: "",
    },
  });

  const handelDate = (dateValue: string, typeDate: string, type: string) => {
    if (dateValue === "Invalid date") {
      const dateNew = {
        ...dateAd,
        [typeDate]: {
          ...dateAd[typeDate],
          [type]: "",
        },
      };
      setDateAd(dateNew);
      return;
    }

    if (!dateValue) {
      setErrorDate("");
      return;
    }
    const value = moment(dateValue);

    if (typeDate === "startDate") {
      if (value.isAfter(ad.endDate)) {
        setErrorDate("components.editAd.configAd.message.errorDateStart");
      } else if (value?.isBefore(ad.endDate)) {
        setErrorDate("");
      }
    }

    if (typeDate === "endDate") {
      if (value?.isBefore(ad.startDate)) {
        setErrorDate("components.editAd.configAd.message.errorDateEnd");
      } else if (value?.isAfter(ad.startDate)) {
        setErrorDate("");
      }
    }

    let unionDateTime = "";
    if (type === "time") {
      unionDateTime = `${dateAd[typeDate].date}T${dateValue}`;
      setDateAd({
        ...dateAd,
        [typeDate]: {
          date: dateAd[typeDate].date.toString(),
          time: dateValue.toString(),
        },
      });
    } else {
      unionDateTime = `${dateValue}T${dateAd[typeDate].time}`;
      setDateAd({
        ...dateAd,
        [typeDate]: {
          date: dateValue.toString(),
          time: dateAd[typeDate].time.toString(),
        },
      });
    }

    setAd({ ...ad, [typeDate]: moment(unionDateTime).format(dateFormatSave) });
    setEditAd(true);
  };

  const dateMode: DateMode = {
    createAt: {
      date: (typeDate: string, defaultDate) => (
        <DatePicker
          className="date-picker"
          format={dateFormat}
          onChange={(dateValue) =>
            handelDate(moment(dateValue).format(dateFormat), typeDate, "date")
          }
        />
      ),
      time: (typeDate: string, defaultDate) => (
        <TimePicker
          format={timeFormat}
          onChange={(dateValue) =>
            handelDate(moment(dateValue).format(timeFormat), typeDate, "time")
          }
        />
      ),
    },
    editAt: {
      date: (typeDate: string, defaultDate) => {
        return (
          <DatePicker
            className="date-picker"
            value={moment(defaultDate)}
            format={dateFormat}
            onChange={(dateValue) =>
              handelDate(moment(dateValue).format(dateFormat), typeDate, "date")
            }
          />
        );
      },
      time: (typeDate: string, defaultDate) => {
        return (
          <TimePicker
            value={moment(defaultDate, timeFormat)}
            format={timeFormat}
            onChange={(dateValue) =>
              handelDate(moment(dateValue).format(timeFormat), typeDate, "time")
            }
          />
        );
      },
    },
  };

  const getInitalDataAdvertiser = async () => {
    const advertiserData = await getAdvertiser();
    setAdvertisersList(advertiserData);
    setAdvertiserListData(advertiserData);
  };
  const getCampaignsName = async (advertiserId: string) => {
    try {
      const campaignName = await getCampaignNames(advertiserId);
      setCampaignsList(campaignName);
      setCampaignsListData(campaignName);
      return campaignName;
    } catch (err) {
      console.log(err);
      message.error({
        content: i18n.t("components.editAd.configAd.message.errorCampaignList"),
        className: "message",
        duration: 5,
      });
    }
    return advertiserId;
  };

  const advertisersData = useCallback(getInitalDataAdvertiser, []);
  const campaignsName = useCallback(getCampaignsName, [i18n]);

  useEffect(() => {
    if (campaign.advertiserId !== "" && !campaignsListData.length) {
      campaignsName(campaign.advertiserId);
    }

    if (!contriesListData.length) {
      setContriesListData(countriesList);
    }

    if (!advertisersList.length) {
      advertisersData();
    }

    const startDate = dateAd.startDate.date;
    const endDate = dateAd.endDate.date;
    if (
      startDate === "" &&
      endDate === "" &&
      ad.startDate !== "" &&
      ad.endDate !== ""
    ) {
      setDateAd({
        startDate: {
          date: moment(ad.startDate).format(dateFormat),
          time: moment(ad.startDate).format(timeFormat),
        },
        endDate: {
          date: moment(ad.endDate).format(dateFormat),
          time: moment(ad.endDate).format(timeFormat),
        },
      });
    }
  }, [
    campaignsName,
    campaign,
    advertisersData,
    advertisersList,
    countriesList,
    contriesListData,
    ad,
    dateAd,
    modeAdType,
    campaignsListData,
  ]);

  const handelChangeAd = (e: any) => {
    const { name, value } = e.target;
    if (name === "budget" || name === "cpm") {
      if (!Number(value)) {
        setErrorBugget("components.editAd.configAd.message.errorBudget");
      } else {
        setAd({ ...ad, [name]: Number(value) });
        setEditAd(true);
        setErrorBugget("");
      }
    } else {
      setAd({ ...ad, [name]: value });
      setEditAd(true);
    }
  };

  const handelChangeCampaign = (campaignId: number | string) => {
    const id = Number(campaignId);
    setAd({ ...ad, campaignId: id });
    setEditAd(true);
    const findCampaign = campaignsList.find((item) => item.id === id);
    setCampaign(findCampaign || campaignInitialState);
    setEditCampaign(true);
  };

  const handelChangeAdvertiser = async (advertiserId: string) => {
    const advertiserSelect = advertisersList.find(
      (item) => item.id === advertiserId
    );
    setAdvertiser(advertiserSelect || advertiserInitialState);
    const newList = await getCampaignsName(advertiserId);
    setCampaign(newList[0] || campaignInitialState);
    setEditCampaign(true);
    setAd({ ...ad, campaignId: newList[0]?.id || 0 });
    setEditAd(true);
  };

  const handelChangeCountry = (countryId: string) => {
    const selectCountry = countriesList.find(
      (item: CountryData) => item.id === countryId
    );
    setAd({ ...ad, countryId });
    setEditAd(true);
    setCountry(selectCountry || countryInitialState);
  };

  const onSearchCountry = (text: string) => {
    if (text !== "") {
      const newListCountry = countriesList.filter(
        (countryData) =>
          countryData.code.toLowerCase().indexOf(text.toLowerCase()) >= 0
      );
      setContriesListData(newListCountry);
    } else {
      setContriesListData(countriesList);
    }
  };

  const onSearchAdvertiser = (text: string) => {
    if (text !== "") {
      const newListAdvertiser = advertisersList.filter(
        (countryData) =>
          countryData.name.toLowerCase().indexOf(text.toLowerCase()) >= 0
      );
      setAdvertiserListData(newListAdvertiser);
    } else {
      setAdvertiserListData(advertisersList);
    }
  };

  const onSearchCampaign = (text: string) => {
    if (text !== "") {
      const newListCampaign = campaignsList.filter(
        (campaignData) =>
          campaignData.title.toLowerCase().indexOf(text.toLowerCase()) >= 0
      );
      setCampaignsListData(newListCampaign);
    } else {
      setCampaignsListData(campaignsList);
    }
  };

  const viewsResult = () => {
    if (ad.cpm > 0 && ad.budget > 0) {
      return ((ad.budget / ad.cpm) * 1000).toFixed(2);
    }
    return 0;
  };

  const createNewAdvertiser = async () => {
    try {
      setModalAdvertiser({ ...modalAdvertiser, loading: true });
      const postAdvertiser = await createAdvertiser(newAdvertiser);
      await getInitalDataAdvertiser();
      setAdvertiser(postAdvertiser);
      setModalAdvertiser({
        ...modalAdvertiser,
        loading: false,
        visible: false,
      });
    } catch (err) {
      console.log(err);
      message.error({
        content: i18n.t("pages.createAd.error.advertiser"),
        className: "message",
        duration: 5,
      });
    }
  };

  const createNewCampaign = async () => {
    try {
      setModalCampaign({ ...modalCampaign, loading: true });
      const newCampaignData = await createCampaign(advertiser.id, newCampaign);
      await getCampaignsName(advertiser.id);
      setCampaign(newCampaignData);
      setAd({ ...ad, campaignId: newCampaignData.id });
      setModalCampaign({ ...modalCampaign, loading: false, visible: false });
    } catch (err) {
      console.log(err);
      message.error({
        content: i18n.t("pages.createAd.error.campaign"),
        className: "message",
        duration: 5,
      });
    }
  };

  const validateInputs = () => {
    if (
      ad.name !== "" &&
      country.id !== "" &&
      country.code !== "" &&
      advertiser.id !== "" &&
      advertiser.name !== "" &&
      campaign.id > 0 &&
      campaign.title !== "" &&
      ad.cpm > 0 &&
      ad.budget > 0 &&
      dateAd.startDate.date !== "" &&
      dateAd.startDate.time !== "" &&
      dateAd.endDate.date !== "" &&
      dateAd.endDate.time !== ""
    ) {
      return (
        <Button type="primary" onClick={() => setSelectMode(2)}>
          {" "}
          {i18n.t("pages.createAd.btns.next")}{" "}
        </Button>
      );
    }
    return null;
  };

  const startDate = dateAd.startDate.date === "" ? "createAt" : "editAt";
  const startTime = dateAd.startDate.time === "" ? "createAt" : "editAt";

  const endDate = dateAd.endDate.date === "" ? "createAt" : "editAt";
  const endTime = dateAd.endDate.time === "" ? "createAt" : "editAt";

  const messageAlert = () => {
    return (
      <p className="text-alert-message">
        {" "}
        {i18n.t("components.editAd.configAd.message.alertInfo")}
      </p>
    );
  };

  return (
    <>
      <div className="container-config-ads">
        <Card>
          <p className="title">
            {i18n.t("components.editAd.configAd.description.title")}
          </p>
          <div className="row description">
            <div>
              <Alert message={messageAlert()} type="info" banner />
              <p>{i18n.t("components.editAd.configAd.description.adName")}</p>
              <Input
                name="name"
                onChange={handelChangeAd}
                defaultValue={ad.name}
              />
            </div>
            <div>
              <SelectOption
                id={country.id}
                handelChange={handelChangeCountry}
                onSearch={onSearchCountry}
                listData={contriesListData}
                message="components.editAd.configAd.description.country"
                keyPrint="code"
                modeAd={modeAdType}
                messageCreate=""
                showModal={() => ""}
              />
            </div>
          </div>
          <div className="row description">
            <div>
              <SelectOption
                id={advertiser.id}
                handelChange={handelChangeAdvertiser}
                onSearch={onSearchAdvertiser}
                listData={advertiserListData}
                message="components.editAd.configAd.description.advertiser"
                keyPrint="name"
                modeAd={modeAdType}
                messageCreate="Create at new advertiser"
                showModal={() =>
                  setModalAdvertiser({ ...modalAdvertiser, visible: true })
                }
              />
            </div>
            <div>
              <SelectOption
                id={campaign.id}
                handelChange={handelChangeCampaign}
                onSearch={onSearchCampaign}
                listData={campaignsListData}
                message="components.editAd.configAd.description.campaignName"
                keyPrint="title"
                modeAd={modeAdType}
                messageCreate="Create at new campaign"
                showModal={() =>
                  setModalCampaign({ ...modalCampaign, visible: true })
                }
              />
            </div>
          </div>
        </Card>
        <Card>
          <p className="title">
            {i18n.t("components.editAd.configAd.budgetAndProgramming.title")}
          </p>
          <div className="row buget">
            <div>
              <p>
                {i18n.t(
                  "components.editAd.configAd.budgetAndProgramming.budget"
                )}
              </p>
              <Input
                suffix={<EuroCircleOutlined />}
                name="budget"
                defaultValue={ad.budget}
                onChange={handelChangeAd}
              />
            </div>
            <div>
              <p>
                {i18n.t("components.editAd.configAd.budgetAndProgramming.cpm")}
              </p>
              <Input
                name="cpm"
                defaultValue={ad.cpm}
                onChange={handelChangeAd}
              />
            </div>
            <div className="icon">
              <ArrowRightOutlined />
            </div>
            <div>
              <p>
                {i18n.t(
                  "components.editAd.configAd.budgetAndProgramming.viewsResult"
                )}
              </p>
              <button className="result-view" disabled type="button">
                {viewsResult()}
              </button>
            </div>
          </div>
          {errorBugget.length > 0 ? (
            <p className="error-message">*{i18n.t(errorBugget)}</p>
          ) : null}
          <div className="row buget">
            <div className="date-time">
              <p>
                {i18n.t(
                  "components.editAd.configAd.budgetAndProgramming.startDate"
                )}
              </p>
              <Input.Group compact>
                {dateMode[startDate].date("startDate", dateAd.startDate.date)}
              </Input.Group>
            </div>
            <div className="position-timer">
              {dateMode[startTime].time("startDate", dateAd.startDate.time)}
            </div>
          </div>
          <div className="row buget">
            <div className="date-time">
              <p>
                {i18n.t(
                  "components.editAd.configAd.budgetAndProgramming.endDate"
                )}
              </p>
              <Input.Group compact>
                {dateMode[endDate].date("endDate", dateAd.endDate.date)}
              </Input.Group>
            </div>
            <div className="position-timer">
              {dateMode[endTime].time("endDate", dateAd.endDate.time)}
            </div>
          </div>
          {errorDate.length > 0 ? (
            <p className="error-message">*{i18n.t(errorDate)}</p>
          ) : null}
        </Card>

        <ModalCreate
          title={modalAdvertiser.title}
          message={modalAdvertiser.message}
          visible={modalAdvertiser.visible}
          loading={modalAdvertiser.loading}
          cancel={() =>
            setModalAdvertiser({ ...modalAdvertiser, visible: false })
          }
          ok={createNewAdvertiser}
          onChange={setNewAdvertiser}
        />
        <ModalCreate
          title={modalCampaign.title}
          message={modalCampaign.message}
          visible={modalCampaign.visible}
          loading={modalCampaign.loading}
          cancel={() => setModalCampaign({ ...modalCampaign, visible: false })}
          ok={createNewCampaign}
          onChange={setNewCampaign}
        />
        <Row className="position-btn">
          {modeAdType === "createAd" ? validateInputs() : null}
        </Row>
      </div>
    </>
  );
};

export default ConfigAd;
