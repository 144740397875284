import createGetFn from "../get";
import createPostFn from "../post";
import { Advertisers } from "./type";

const baseUrl = `${window._env_.SERVER_URL}/admin/agency`;

const get = createGetFn(baseUrl, {
  normalize: (res: any): Advertisers[] => {
    const advertisers: Advertisers[] = res.data.map((advertiser: any) => {
      return {
        id: advertiser.id,
        name: advertiser.name,
      };
    });
    return advertisers;
  },
});

const post = createPostFn(baseUrl, {
  normalize: (res: any): Advertisers => {
    const { id, name } = res.data;
    return { id, name };
  },
});

export const getAdvertiser = (): Advertisers[] => get("");
export const createAdvertiser = (advertiserName: string) =>
  post("", { name: advertiserName });
