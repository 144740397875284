export const EDIT_AD = "EDIT_AD";
export const CREATE_AD = "CREATE_AD";

export type EditAd = {
  editAd: boolean;
  adId: number;
};

export type CreateAd = {
  createAd: boolean;
};
