import axios from "axios";
import { store } from "../../store";
import { userLogin, logOut } from "../../store/auth";
import { User } from "../../store/auth/types";
import { getTokenFromStorage, setAuthToken } from "../_auth";

import createGetFn from "../get";

type LoginCredentials = {
  email: string;
  password: string;
};

const baseUrl = `${window._env_.SERVER_URL}/auth/admin`;

export async function credentialsAuth(
  emailAndPassword: LoginCredentials,
  erroMsgCB: (msg: string) => void
): Promise<void> {
  try {
    const { data } = await axios.post(`${baseUrl}/login`, emailAndPassword);
    if (data) {
      setAuthToken(data.token);
      store.dispatch(userLogin(data.user));
    }
  } catch (e) {
    erroMsgCB(e?.response?.data.message);
  }
}

const get = createGetFn(baseUrl, {
  auth: true,
});

export async function tokenAuth(): Promise<void> {
  if (!getTokenFromStorage()) return;

  try {
    const { data } = await get("/me");
    if (data) store.dispatch(userLogin(data.user));
  } catch (e) {
    store.dispatch(logOut());
  }
}
