import { Button, Input } from "antd";
import React, { ChangeEvent, useState } from "react";
import "./ViewersFilter.scss";

type ViewersFilterProps = {
  state: { from: number; to: number };
  setState: (v: { from: number; to: number }) => void;
};

const ViewersFilter = ({
  state,
  setState,
}: ViewersFilterProps): JSX.Element => {
  const [from, setfrom] = useState(0);
  const [to, setto] = useState(0);

  const onOk = () => {
    setState({ from, to });
  };

  const reset = () => {
    setState({ from: -1, to: -1 });
    setfrom(0);
    setto(0);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "from") setfrom(parseInt(value, 10));
    else setto(parseInt(value, 10));
  };

  return (
    <div className="viewers-filter-container">
      <div className="inputs">
        Desde
        <Input
          type="number"
          name="from"
          value={from}
          onChange={handleChange}
          defaultValue=""
        />
      </div>

      <div className="inputs">
        hasta
        <Input
          min={0}
          type="number"
          name="to"
          value={to}
          onChange={handleChange}
          defaultValue=""
        />
      </div>
      <div className="buttonWrapp">
        <Button type="default" className="button-reset" onClick={reset}>
          Reset
        </Button>
        <Button type="primary" className="button-ok" onClick={onOk}>
          Ok
        </Button>
      </div>
    </div>
  );
};

export default ViewersFilter;
