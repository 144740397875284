import { AxiosResponse } from "axios";
import i18n from "i18next";
import createGetFn from "../get";
import createPutFn from "../put";
import { Country } from "./types";

export const getMonetizationData = `${window._env_.SERVER_URL}/admin/monetization/country`;
export const putMonetizationCountry = `${window._env_.SERVER_URL}/admin/monetization/country`;
export const putCountryWithoutMonetization = `${window._env_.SERVER_URL}/admin/monetization/country/no_monetization`;
export const putMonetizationGroup = `${window._env_.SERVER_URL}/admin/monetization/group`;
export const putMonetizationRange = `${window._env_.SERVER_URL}/admin/monetization/range`;

const get = createGetFn(getMonetizationData, {
  auth: true,

  normalize: (res: AxiosResponse) => {
    const { data } = res;
    const countries: Country[] = data;

    return countries.map((country) => ({
      ...country,
      code: i18n.t(`pages.channels.countries.${country.code}`),
    }));
  },
});

export const getCountriesMonetization = (query: string): Promise<Country[]> =>
  get(query);

const putCountry = createPutFn(putMonetizationCountry, {
  normalize: (res: AxiosResponse) => res,
});

const putCountryNoMonetization = createPutFn(putCountryWithoutMonetization, {
  normalize: (res: AxiosResponse) => res,
});

const putGroup = createPutFn(putMonetizationGroup, {
  normalize: (res: AxiosResponse) => res,
});
const putRange = createPutFn(putMonetizationRange, {
  normalize: (res: AxiosResponse) => res,
});

export const putStreamerCountry = (
  streamerId: string,
  countryId: string
): AxiosResponse => {
  return putCountry(``, {
    streamerId,
    countryId,
  });
};

export const putStreamerCountrywithoutMonetization = (
  streamerId: string,
  countryId: string
): AxiosResponse => {
  return putCountryNoMonetization(``, {
    streamerId,
    countryId,
  });
};

export const putStreamerCountryGroup = (
  streamerId: string,
  groupId: string
): AxiosResponse => {
  return putGroup(``, {
    streamerId,
    groupId,
  });
};

export const putStreamerCountryRange = (
  streamerId: string,
  rangeId: string
): AxiosResponse => {
  return putRange(``, {
    streamerId,
    rangeId,
  });
};
