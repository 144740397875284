import React, {
  FunctionComponent,
  useContext,
  useState,
  useEffect,
} from "react";
import Helmet from "react-helmet";
import { I18nContext } from "react-i18next";
import { Col, Row, message, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch } from "redux-react-hook";
import { getActiveCampaignData } from "../../services/Campaign";
import ActiveCampaignsTable from "../../components/ActiveCampaignsTable";
import "./activeCampaign.scss";
import { ActiveCampaign, Ad } from "../../services/Campaign/type";
import { putActivateDeactivateCampaing } from "../../services/Campaign/ActiveCampaign";
import Modal from "../../components/Modal/Modal";
import { adPathCreate } from "../../store/ad/actions";

const initialStateCampaingSelected = {
  name: "",
  adsId: [0],
  adActive: false,
};

const ActiveCampaigns: FunctionComponent = () => {
  const { i18n } = useContext(I18nContext);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [activeCampaData, setActiveCampaData] = useState<Array<ActiveCampaign>>(
    []
  );
  const [campaingSelected, setCampaingSelected] = useState<
    typeof initialStateCampaingSelected
  >(initialStateCampaingSelected);
  const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);

  useEffect(() => {
    getActiveCampaignData()
      .then((data: ActiveCampaign[]) => setActiveCampaData(data))
      .finally(() => setLoading(false));
  }, []);

  const statusCampaingChange = (event: boolean, campaing: ActiveCampaign) => {
    const dataCampaing = {
      name: campaing.campaignName,
      adsId: campaing.ads.map((ad) => ad.adId),
      adActive: !event,
    };
    setCampaingSelected(dataCampaing);
    setShowModalConfirm(true);
  };

  const handleCancel = () => {
    setCampaingSelected(initialStateCampaingSelected);
    setShowModalConfirm(false);
  };

  const statusAd = (event: boolean, ad: Ad, campaignName: string) => {
    const dataCampaing = {
      name: campaignName,
      adsId: [ad.adId],
      adActive: !event,
    };
    setCampaingSelected(dataCampaing);
    setShowModalConfirm(true);
  };

  const handleOk = async () => {
    try {
      await putActivateDeactivateCampaing(
        campaingSelected.adsId,
        !campaingSelected?.adActive
      );
      const newCampaingsList = activeCampaData.map(
        (campaing: ActiveCampaign) => {
          const newAds = campaing.ads.map((ad: Ad) => {
            if (campaingSelected.adsId.includes(ad.adId)) {
              const newAd: Ad = { ...ad, adActive: !campaingSelected.adActive };
              return newAd;
            }
            return ad;
          });
          const newCampaing: ActiveCampaign = { ...campaing, ads: newAds };
          return newCampaing;
        }
      );

      setActiveCampaData(newCampaingsList);
      message.success({
        content: `${i18n.t("pages.activeCampaigns.statusMessage")} ${
          campaingSelected.name
        } ${i18n.t("pages.activeCampaigns.changeOk")}`,
        className: "message",
        duration: 3,
      });
    } catch (err) {
      message.error({
        content: `${i18n.t("pages.activeCampaigns.changeFailed")} ${
          campaingSelected.name
        }`,
        className: "message",
        duration: 5,
      });
    } finally {
      setShowModalConfirm(false);
    }
  };

  const createAd = () => {
    dispatch(adPathCreate({ createAd: true }));
  };

  return (
    <section>
      <Helmet>
        <title>Streamion | {i18n.t("pages.activeCampaigns.title")}</title>
      </Helmet>
      <Row gutter={45}>
        <Col span={24}>
          <Row className="space-element">
            <h1 className="section-tittle mb-1">
              {i18n.t("pages.activeCampaigns.title")}
            </h1>
            <Button onClick={createAd} type="primary" className="wiht-button">
              <PlusOutlined />
              Crear anuncio
            </Button>
          </Row>
        </Col>
        <Col span={24}>
          <ActiveCampaignsTable
            statusCampaingChange={statusCampaingChange}
            statusAd={statusAd}
            loading={loading}
            dataSource={activeCampaData}
          />
        </Col>
      </Row>
      <Modal
        visible={showModalConfirm}
        onOk={handleOk}
        onCancel={handleCancel}
        modalSize="xs"
        className=""
        okText={i18n.t("pages.activeCampaigns.okButton")}
        cancelText={i18n.t("pages.activeCampaigns.cancelButton")}
      >
        {i18n.t("pages.activeCampaigns.changeStatus")}
        <strong>{` ${i18n.t("pages.activeCampaigns.campaign")} ${
          campaingSelected.name ? campaingSelected.name : null
        }?`}</strong>
      </Modal>
    </section>
  );
};

export default ActiveCampaigns;
