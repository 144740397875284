import React, { useState } from "react";
import { Select } from "antd";
import "./DropDownFilter.scss";
import { Option } from "antd/lib/mentions";
import { channelFilters } from "../../../pages/ChannelsManagement/types";

type DropdownType = {
  name: string;
  id: string;
};

type DropdownSelectorProps = {
  state: channelFilters;
  elements: DropdownType[];
  filterProp: string;
  setState: (v: channelFilters) => void;
};

const DropDownFilter = ({
  state,
  elements,
  filterProp,
  setState,
}: DropdownSelectorProps): JSX.Element => {
  const [filter, setFilter] = useState<string>();

  function setSearchValue(value: string) {
    setState({ ...state, [filterProp]: value });
    setFilter(value);
  }

  return (
    <div className="dropdownWraper">
      <div className="selector">
        <Select
          style={{ width: 120 }}
          value={filter}
          onSelect={(value) => setSearchValue(value)}
        >
          {elements.map((element: DropdownType) => (
            <Option key={element.id} value={element.id}>
              {element.name}
            </Option>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default DropDownFilter;
