import React, { FunctionComponent, useContext, useState } from "react";
import { Menu } from "antd";
import { MenuInfo } from "rc-menu/lib/interface";
import { SearchOutlined } from "@ant-design/icons";
import { NavLink, useLocation } from "react-router-dom";
import { I18nContext } from "react-i18next";
import { ReactComponent as WhiteLogo } from "../../../assets/images/white-logo.svg";
import { ReactComponent as StreamionLogo } from "../../../assets/images/logo-streamion-white.svg";
import "./Sidebar.scss";

type SidebarProps = {
  default?: string;
  isCollapsed: boolean;
};

const Sidebar: FunctionComponent<SidebarProps> = ({
  isCollapsed,
  default: defaultPathname,
}) => {
  const { i18n } = useContext(I18nContext);
  const routerLocation = useLocation();
  const initialPathname = defaultPathname || routerLocation.pathname;
  const [currentLocaltion, setCurrentLocation] = useState(initialPathname);

  const getDefaultOpenMenus = (): string[] => {
    const openKeys = [];

    if (
      currentLocaltion.includes("account") ||
      currentLocaltion.includes("campaigns")
    )
      openKeys.push("dashboard");
    if (currentLocaltion.includes("creatives")) openKeys.push("creatives");

    return openKeys;
  };

  return (
    <div className="sidebar">
      <div className="sidebar__header">
        {isCollapsed ? (
          <StreamionLogo className="sidebar__logo-collapsed" />
        ) : (
          <WhiteLogo className="sidebar__logo" />
        )}
      </div>
      <div className="sidebar__menu">
        <Menu
          selectedKeys={[currentLocaltion]}
          defaultOpenKeys={getDefaultOpenMenus()}
          mode="inline"
          theme="dark"
          inlineCollapsed={isCollapsed}
          style={{ width: isCollapsed ? 75 : 220 }}
          onClick={({ key }: MenuInfo): void => setCurrentLocation(`${key}`)}
        >
          <Menu.Item>
            <NavLink to="/emissions">
              {i18n.t("components.mainSidebar.emissions")}
            </NavLink>
          </Menu.Item>
          <Menu.Item>
            <NavLink to="/moderation">
              {i18n.t("components.mainSidebar.moderation")}
            </NavLink>
          </Menu.Item>
          <Menu.Item>
            <NavLink to="/frequencies">
              {i18n.t("components.mainSidebar.frequencyManagement")}
            </NavLink>
          </Menu.Item>
          <Menu.Item>
            <NavLink to="/channels">
              {" "}
              {i18n.t("components.mainSidebar.channelsManagement")}
            </NavLink>
          </Menu.Item>
          <Menu.Item>
            <NavLink to="/campaigns">
              {i18n.t("components.mainSidebar.activeCampaigns")}
            </NavLink>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
};

export default Sidebar;
