import React, { useState } from "react";
import { message, Switch } from "antd";
import { StatusData, UpdateStatusData } from "./types";

type UpdateStatusProps = {
  data: StatusData;
  updateStatus: (v: UpdateStatusData) => Promise<void>;
  disabled: boolean;
  // fetchLocalData: (twitchId: string, createAutomaticTip: boolean) => void;
};

const UpdateStatus = ({
  data,
  updateStatus,
  disabled,
}: UpdateStatusProps): JSX.Element => {
  const [updateData, setUpdateData] = useState(data);

  const update = async () => {
    try {
      const values = {
        streamerId: updateData.streamerId,
        createAutomaticTip: !updateData.createAutomaticTip,
        displayName: updateData.displayName,
      };
      await updateStatus(values);
      setUpdateData(values);
      message.success({
        content: `Channel ${values.displayName} is ${
          values.createAutomaticTip ? "active now!" : "inactive now!"
        }`,
        className: "message",
        duration: 5,
      });
    } catch (error) {
      message.error({
        content: error.message,
        className: "message",
        duration: 5,
      });
    }
  };

  return (
    <>
      <Switch
        key={updateData.streamerId}
        checked={updateData.createAutomaticTip}
        onChange={() => update()}
        disabled={disabled}
      />
    </>
  );
};

export default UpdateStatus;
