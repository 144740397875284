import createGetFn from "../get";
import createPutFn from "../put";
import createPostFn from "../post";
import createDeletFn from "../delete";
import { Row, ViewersFrequency } from "./types";
import { StreamerGroupId } from "../../pages/FrequenciesManagement/type";

export const baseUrlGet = `${window._env_.SERVER_URL}/admin/streamer/all`;
export const baseUrlPut = `${window._env_.SERVER_URL}/admin/streamer`;
export const baseUrlPutGroupStreamers = `${window._env_.SERVER_URL}/admin/streamer/update`;

export const getIntervalMinutes = (seconds: number): number => {
  return seconds / 60;
};

const get = createGetFn(baseUrlGet, {
  auth: true,
  normalize: (res: any) => {
    const { results, streamers, streams } = res.data;
    const keys = Object.keys(streamers);
    if (!keys.length) {
      return [];
    }
    const streamersIsLive = results.isLive;
    const allStreamers: Row[] = [];

    keys.map((keyObj: string, index: number) => {
      allStreamers.push({
        key: index,
        id: streamers[keyObj].id,
        minIntervalSeconds: streamers[keyObj].minIntervalSeconds,
        twitchId: keyObj,
        name: streamers[keyObj].twitchUser.displayName,
        viewers: streams[keyObj] ? streams[keyObj].viewers : 0,
        disabled: true,
        country: streamers[keyObj].country.code,
        isLive: streamersIsLive.includes(keyObj),
        getIntervalMinutes,
        isChecked: false,
      });
      return allStreamers;
    });

    const descSteramerViewers = allStreamers.sort(
      (a, b) => b.viewers - a.viewers
    );
    return descSteramerViewers;
  },
});

const put = createPutFn(baseUrlPut, {
  normalize: (res: any) => res,
});

const putGroup = createPutFn(baseUrlPutGroupStreamers, {
  normalize: (res: any) => res,
});

export const getStreamerLive = () => get("");

export const putStreamerFrequency = (id: string, minIntervalSeconds: number) =>
  put(`${id}`, { minIntervalSeconds });

export const putGroupStreamers = (streamerGroupId: StreamerGroupId) =>
  putGroup("", streamerGroupId);

// ViewersFrequencies / Stopper

export const baseUrlFrequency = `${window._env_.SERVER_URL}/admin/frequency`;
export const baseUrlStopper = `${window._env_.SERVER_URL}/admin/frequency/stopper`;

const getStopper = createGetFn(baseUrlFrequency, {
  auth: true,
  normalize: (res: any) => {
    const { data } = res;
    if (data !== "") {
      const viewrsFrequencies: ViewersFrequency[] = [];
      data.forEach((viewrsFrequency: any) => {
        viewrsFrequencies.push({
          delete: false,
          edit: false,
          id: viewrsFrequency.id,
          intervalSeconds: viewrsFrequency.intervalSeconds,
          viewers: viewrsFrequency.viewers,
          getIntervalMinutes,
        });
      });
      return viewrsFrequencies;
    }
    return [];
  },
});

const post = createPostFn(baseUrlFrequency, {
  normalize: (res: any) => res,
});

const deleted = createDeletFn(baseUrlFrequency, {
  normalize: (res: any) => res,
});

const putViewersFrequencies = createPutFn(baseUrlFrequency, {
  normalize: (res: any) => res,
});

export const getAllViewersFrequency = () => getStopper("");

export const postViewersFrequency = (
  intervalSeconds: number,
  viewers: number
) => post("", { intervalSeconds, viewers });

export const deletedViewersFrequency = (viewersFreuqencyId: string) =>
  deleted(`/${viewersFreuqencyId}`);

export const putViewersFrequencie = (
  id: string,
  viewers: number,
  intervalSeconds: number | null
) => putViewersFrequencies("", { id, viewers, intervalSeconds });

const postStop = createPostFn(baseUrlStopper, {
  normalize: (res: any) => res,
});

const putStopper = createPutFn(baseUrlStopper, {
  normalize: (res: any) => res,
});

export const createStopper = (viewers: number) => postStop("", { viewers });

export const updateStopper = (id: string, viewers: number) =>
  putStopper("", { id, viewers });
