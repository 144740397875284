import React, { useCallback, useState, useEffect, useContext } from "react";
import { useMappedState, useDispatch } from "redux-react-hook";
import { Modal, Button, Spin, message } from "antd";
import { I18nContext } from "react-i18next";
import { adPathEdit } from "../../store/ad/actions";
import "./editAd.scss";

// Component
import SidebarAd from "../../components/SidebarAd/SidebarAd";
import ConfigAd from "../../components/EditAd/ConfigAd/ConfigAd";
import ContentAd from "../../components/EditAd/ContentAd/ContentAd";
import Revision from "../../components/EditAd/Revision/Revision";
import Segmentation from "../../components/EditAd/Segmentation/Segmentantion";

// service
import { getAd } from "../../services/Ad/Ad";
import { getCountry } from "../../services/Country/Country";

// Types
import {
  Ad,
  CampaignData,
  AdLandingData,
  Advertiser,
  CountryData,
} from "../../services/Ad/type";

import { Countries } from "../../services/Country/types";

// Initial State
import {
  adInitialState,
  adLandingInitialState,
  advertiserInitialState,
  campaignInitialState,
  countryInitialState,
} from "./initalState";
import { StreamerAgreement } from "../../services/Agreements/types";

interface Mode {
  [key: string]: JSX.Element;
}

interface Props {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}

const EdditAdd = ({ showModal, setShowModal }: Props) => {
  const { i18n } = useContext(I18nContext);
  const mapState = useCallback(
    (state: any) => ({
      adId: state.ad.editAd.adId,
    }),
    []
  );

  const { adId } = useMappedState(mapState);

  // data ad
  const [ad, setAd] = useState<Ad>(adInitialState);
  const [campaign, setCampaign] = useState<CampaignData>(campaignInitialState);
  const [advertiser, setAdvertiser] = useState<Advertiser>(
    advertiserInitialState
  );
  const [adLanding, setAdLanding] = useState<AdLandingData>(
    adLandingInitialState
  );
  const [country, setCountry] = useState<CountryData>(countryInitialState);

  // drowpdowns
  const [countriesList, setCountriesList] = useState<Array<Countries>>([]);

  // interaction
  const [selectMode, setSelectMode] = useState(1);
  const [loading, setLoading] = useState(false);

  // to send data to edited
  const [editAd, setEditAd] = useState(false);
  const [editCampaign, setEditCampaign] = useState(false);
  const [editLanding, setEditLanding] = useState(false);
  const [editAgreement, setEditAgreement] = useState(false);
  const [agreementList, setAgreementList] = useState<StreamerAgreement[]>([]);

  // image and video
  const [image, setImg] = useState<any>("");
  const [video, setVideo] = useState<any>("");

  const oredreredCountries = (countryList: CountryData[]) => {
    return countryList
      ?.map((c) => ({
        id: c.id,
        code: i18n.t(`pages.channels.countries.${c.code}`),
      }))
      .sort((a, b) => a.code.localeCompare(b.code));
  };

  const getAllData = async () => {
    try {
      setLoading(true);
      const dataAd = await getAd(adId);
      setAd(dataAd.ad);
      setCampaign(dataAd.campaign);
      setAdLanding(dataAd.adLanding);
      setAdvertiser(dataAd.advertiser);
      setCountry(dataAd.country);
      const dataCountry = await getCountry();
      setCountriesList(dataCountry);
    } catch (err) {
      console.log(err);
      message.error({
        content: i18n.t("pages.editAd.message.errorData"),
        className: "message",
        duration: 5,
      });
    } finally {
      setLoading(false);
    }
  };

  const get = useCallback(getAllData, [
    adId,
    setAd,
    setCampaign,
    setAdLanding,
    setAdvertiser,
    setCountry,
    setLoading,
    i18n,
  ]);

  useEffect(() => {
    get();
  }, [get]);

  const mode: Mode = {
    step1: (
      <ConfigAd
        setEditAd={setEditAd}
        setEditCampaign={setEditCampaign}
        ad={ad}
        setAd={setAd}
        campaign={campaign}
        setCampaign={setCampaign}
        advertiser={advertiser}
        setAdvertiser={setAdvertiser}
        country={country}
        setCountry={setCountry}
        countriesList={oredreredCountries(countriesList)}
        modeAdType="editAd"
        setSelectMode={setSelectMode}
      />
    ),
    step2: (
      <Segmentation
        adId={adId}
        ad={ad}
        setAd={setAd}
        setEditAd={setEditAd}
        agreementList={agreementList}
        setAgreementList={setAgreementList}
        countriesList={countriesList}
        editAgreement={editAgreement}
        setEditAgreement={setEditAgreement}
        modeAdType="editAd"
        setSelectMode={setSelectMode}
      />
    ),
    step3: (
      <ContentAd
        setEditAd={setEditAd}
        setEditLanding={setEditLanding}
        editLanding={editLanding}
        ad={ad}
        setAd={setAd}
        adLanding={adLanding}
        setAdLanding={setAdLanding}
        modeAdType="editAd"
        setSelectMode={setSelectMode}
        setVideo={setVideo}
        setImg={setImg}
      />
    ),
    step4: (
      <Revision
        editAd={editAd}
        editCampaign={editCampaign}
        editLanding={editLanding}
        setEditAd={setEditAd}
        setEditCampaign={setEditCampaign}
        setEditLanding={setEditLanding}
        editAgreement={editAgreement}
        setEditAgreement={setEditAgreement}
        agreementList={agreementList}
        ad={ad}
        campaign={campaign}
        advertiser={advertiser}
        adLanding={adLanding}
        country={country}
        modeAdType="editAd"
        setSelectMode={setSelectMode}
        image={image}
        video={video}
      />
    ),
  };

  const dispatch = useDispatch();
  const handelUnSave = () => {
    setShowModal(false);
    dispatch(adPathEdit({ adId: 0, editAd: false }));
    setAgreementList([]);
  };

  const handelSave = () => {
    setSelectMode(4);
    setShowModal(false);
  };

  return (
    <div className="container-edit-ad">
      <div className="sidebar-edit-ad">
        <SidebarAd
          selectMode={`step${selectMode}`}
          setSelectMode={setSelectMode}
          modeAdType="editAd"
        />
      </div>
      {loading ? (
        <div className="spin-loading">
          <Spin tip="Loading..." />
        </div>
      ) : (
        <div className="content-edit-ad">{mode[`step${selectMode}`]}</div>
      )}
      <Modal
        visible={showModal}
        className="modal-ad-edit"
        footer={[
          <Button
            className="btn-footer-modal"
            onClick={() => setShowModal(false)}
          >
            {" "}
            {i18n.t("pages.editAd.modal.cancel")}
          </Button>,
          <Button className="btn-footer-modal" onClick={handelUnSave}>
            {" "}
            {i18n.t("pages.editAd.modal.notSave")}
          </Button>,
          <Button type="primary" onClick={handelSave}>
            {" "}
            {i18n.t("pages.editAd.modal.save")}
          </Button>,
        ]}
      >
        <p className="modal-text">{i18n.t("pages.editAd.modal.text")}</p>
      </Modal>
    </div>
  );
};

export default EdditAdd;
