import { EDIT_AD, EditAd, CREATE_AD, CreateAd } from "./types";

type State = {
  editAd: EditAd;
  createAd: CreateAd;
};

const initialState: State = {
  editAd: {
    adId: 0,
    editAd: false,
  },
  createAd: {
    createAd: false,
  },
};

export const adReducer = (state: State = initialState, action: any) => {
  switch (action.type) {
    case EDIT_AD:
      return {
        ...state,
        editAd: {
          editAd: action.payload.editAd,
          adId: action.payload.adId,
        },
        createAd: {
          createAd: false,
        },
      };
    case CREATE_AD:
      return {
        ...state,
        editAd: {
          editAd: false,
          adId: 0,
        },
        createAd: {
          createAd: action.payload.createAd,
        },
      };
    default:
      return state;
  }
};
