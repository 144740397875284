import React, { useContext } from "react";
import { Tag } from "antd";
import { I18nContext } from "react-i18next";
import { ValidStatus } from "../../commonTypes";
import "./StatusTag.scss";

type StatusTagProps = {
  status: ValidStatus;
};

const StatusTag = ({ status }: StatusTagProps): JSX.Element => {
  const { i18n } = useContext(I18nContext);
  const MODERATION_STATUS = {
    0: {
      label: "revision",
      color: "geekblue",
    },
    1: {
      label: "approved",
      color: "green",
    },
    2: {
      label: "missing_ad",
      color: "magenta",
    },
    3: {
      label: "wrong_size",
      color: "magenta",
    },
    4: {
      label: "covered_ad",
      color: "magenta",
    },
  };

  return (
    <Tag className="status-tag" color={MODERATION_STATUS[status].color}>
      {i18n.t(`pages.moderation.${MODERATION_STATUS[status].label}`)}
    </Tag>
  );
};

export default StatusTag;
