import React, { useContext } from "react";

import { Button, InputNumber, Select } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import { I18nContext } from "react-i18next";
import { ViewersFrequency } from "../../services/FrequencyManagement/types";

import EditSaveViewersFrequency from "./EditSaveViewersFrequency/EditSaveViewersFrequency";
import { frequenciesList } from "../ButtonsFrequency/ButtonsFrequency";

const { Option } = Select;
const addStopperValue = [...frequenciesList, "stop"];

interface Props {
  edditViewersFrequency: (viewrsFrequencyId: string) => void;
  createViewersFrequency: (viewrsFrequency: ViewersFrequency) => void;
  updateViewersFrequency: (viewrsFrequency: ViewersFrequency) => void;
  deleteViewersFrequency: (viewrsFrequencyId: string) => void;
  onChangeViewers: (
    viewrs: string | number | undefined,
    viewersFrequency: string
  ) => void;
  onChangeFrequency: (
    frequency: string | number | undefined,
    viewersFrequency: ViewersFrequency
  ) => void;
  printText: {
    btnDelete: string;
    viewers: string;
    frequency: string;
  };
}
const columnsViewersFrequency = ({
  createViewersFrequency,
  edditViewersFrequency,
  updateViewersFrequency,
  deleteViewersFrequency,
  onChangeViewers,
  onChangeFrequency,
  printText,
}: Props) => {
  return [
    {
      title: "",
      dataIndex: "delete",
      key: "delete",
      render: (deleted: boolean, viewersFrequency: ViewersFrequency) => (
        <div>
          {deleted ? (
            <div className="wrapper-delete">
              <Button
                size="middle"
                onClick={() => deleteViewersFrequency(viewersFrequency.id)}
                icon={<DeleteOutlined />}
              >
                {printText.btnDelete}
              </Button>
            </div>
          ) : null}
        </div>
      ),
    },
    {
      title: printText.viewers,
      dataIndex: "viewers",
      key: "viewers",
      render: (viewers: number, viewersFrequency: ViewersFrequency) => (
        <div className="viewers-stopper">
          {viewers > 0 && !viewersFrequency.edit ? (
            <p> {viewers} </p>
          ) : (
            <div className="wrapper-input-viewers">
              {" "}
              <InputNumber
                defaultValue={viewers}
                min={1}
                onChange={(e: string | number | undefined) =>
                  onChangeViewers(e, viewersFrequency.id)
                }
              />{" "}
            </div>
          )}
        </div>
      ),
    },
    {
      title: printText.frequency,
      dataIndex: "intervalSeconds",
      key: "intervalSeconds",
      render: (
        intervalSeconds: number | null | string,
        viewersFrequency: ViewersFrequency
      ) => (
        <div className="wrapper-frequecy-stopper">
          {!viewersFrequency.edit ? (
            <p>
              {intervalSeconds === null || intervalSeconds === "stop"
                ? "stop"
                : viewersFrequency.getIntervalMinutes(Number(intervalSeconds))}
            </p>
          ) : (
            <div className="select-frquency">
              <Select
                onChange={(e) => onChangeFrequency(e, viewersFrequency)}
                defaultValue={
                  intervalSeconds === null || intervalSeconds === "stop"
                    ? "stop"
                    : viewersFrequency.getIntervalMinutes(
                        Number(viewersFrequency.intervalSeconds)
                      )
                }
              >
                {addStopperValue.map((frequecy: number | string) => (
                  <Option value={frequecy}>{frequecy}</Option>
                ))}
              </Select>
            </div>
          )}
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "edit",
      key: "edit",
      render: (edit: boolean, viewersFrequency: ViewersFrequency) => (
        <EditSaveViewersFrequency
          edit={edit}
          viewersFrequency={viewersFrequency}
          edditViewersFrequency={edditViewersFrequency}
          createViewersFrequency={createViewersFrequency}
          updateViewersFrequency={updateViewersFrequency}
        />
      ),
    },
  ];
};

export default columnsViewersFrequency;
