import React from "react";
import { Input, Form, Button } from "antd";

type InputTagProps = {
  addTag: (v: string) => void;
};

const InputTag = ({ addTag }: InputTagProps): JSX.Element => {
  const [form] = Form.useForm();
  const key = "v";

  function onFinish(formValues: { [k: string]: string }) {
    addTag(formValues[key]);
    form.resetFields();
  }

  return (
    <div>
      <Form layout="inline" form={form} onFinish={onFinish}>
        <Form.Item name={key}>
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Add
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default InputTag;
