import React, { useState } from "react";
import { Col, Table } from "antd";
import { SearchOutlined, DeleteOutlined } from "@ant-design/icons";
import getColumns from "./getColumns";
import "./ModerationTable.scss";
import { TagsFilter, CheckboxFilter, FiltersBtn, DateFilter } from "../Filters";
import { DatesState } from "../../commonTypes";
import { Moderation } from "../../pages/Moderation/types";
import { UpdateStatusData, QueryFormatter } from "./type";

type ModerationTableProps = {
  loading: boolean;
  dataSource: Moderation;
  onUpdateStatus: (v: UpdateStatusData) => void;
  queryWithFilters: (filters: QueryFormatter) => Promise<void>;
};

const ModerationTable = ({
  loading,
  dataSource,
  onUpdateStatus,
  queryWithFilters,
}: ModerationTableProps): JSX.Element => {
  const defaultPageSize = 10;
  const [initStatusValues] = useState<QueryFormatter["status"]>([
    { label: "revision", value: 0, checked: true },
    { label: "approved", value: 1, checked: true },
    { label: "missing_ad", value: 2, checked: true },
    { label: "wrong_size", value: 3, checked: true },
    { label: "covered_ad", value: 4, checked: true },
  ]);

  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [dateRange, setDateRange] = useState<DatesState>(null);
  const [twitchDisplayNames, setTwitchDisplayNames] = useState<string[]>([]);
  const [adMetricIds, setAdMetricIds] = useState<string[]>([]);
  const [statusValues, setStatusValues] = useState(initStatusValues);
  const [campaignId, setCampaignId] = useState<string[]>([]);

  function resetFilters() {
    setDateRange(null);
    setTwitchDisplayNames([]);
    setAdMetricIds([]);
    setStatusValues(initStatusValues);
    setCampaignId([]);
    queryWithFilters({
      pagination,
      dateRange: null,
      twitchDisplayNames: [],
      adMetricIds: [],
      status: initStatusValues,
      campaignId: [],
    });
  }

  function handlePagination(page: number, pageSize?: number) {
    setPagination((prevState) => ({
      page,
      pageSize: pageSize || prevState.page,
    }));
    queryWithFilters({
      pagination: { page, pageSize: pageSize || defaultPageSize },
      dateRange,
      twitchDisplayNames,
      adMetricIds,
      status: statusValues,
      campaignId,
    });
  }

  function onFilterClick() {
    queryWithFilters({
      pagination,
      dateRange,
      twitchDisplayNames,
      adMetricIds,
      status: statusValues,
      campaignId,
    });
  }

  return (
    <>
      <Col xs={24} style={{ marginBottom: "10px" }}>
        <FiltersBtn
          label="Filter"
          type="primary"
          icon={<SearchOutlined />}
          onClick={onFilterClick}
        />
        <span style={{ margin: "0 10px" }} />
        <FiltersBtn
          label="Reset"
          icon={<DeleteOutlined />}
          onClick={resetFilters}
        />
      </Col>
      <Col xs={24}>
        <Table
          rowKey="adMetricId"
          className="moderation-table"
          loading={loading}
          dataSource={dataSource && dataSource.data && dataSource.data.rows}
          pagination={{
            current: dataSource && dataSource.page,
            defaultCurrent: 1,
            pageSize: dataSource && dataSource.limit,
            defaultPageSize,
            total: dataSource && dataSource.data && dataSource.data.total,
            onChange: handlePagination,
            showSizeChanger: true,
          }}
          columns={getColumns(onUpdateStatus, {
            DateFilter: (
              <DateFilter state={dateRange} setState={setDateRange} />
            ),
            StreamerFilter: (
              <TagsFilter
                state={twitchDisplayNames}
                setState={setTwitchDisplayNames}
              />
            ),
            AdFilter: (
              <TagsFilter state={adMetricIds} setState={setAdMetricIds} />
            ),
            StatusFilter: (
              <CheckboxFilter state={statusValues} setState={setStatusValues} />
            ),
            CampaignFilter: (
              <TagsFilter state={campaignId} setState={setCampaignId} />
            ),
          })}
        />
      </Col>
    </>
  );
};

export default ModerationTable;
