import React from "react";
import { Input, Form, Button } from "antd";
import TextArea from "antd/lib/input/TextArea";

type InputTagProps = {
  addTag: (v: string) => void;
  resetTags: () => void;
  placeHolder: string | "";
};

const InputTag = ({
  addTag,
  resetTags,
  placeHolder,
}: InputTagProps): JSX.Element => {
  const [form] = Form.useForm();
  const key = "v";

  function onFinish(formValues: { [k: string]: string }) {
    addTag(formValues[key]);
    form.resetFields();
  }

  return (
    <div className="form-container">
      <Form
        layout="inline"
        form={form}
        onFinish={onFinish}
        className="form-wrapper"
      >
        <Form.Item name={key} className="textarea-wrapper">
          <TextArea
            autoSize={{ minRows: 1, maxRows: 6 }}
            className="text-input"
            placeholder={placeHolder}
          />
        </Form.Item>
        <Form.Item className="form-button-wrapper">
          <Button className="button-reset" type="default" onClick={resetTags}>
            Reset
          </Button>
          <Button className="button-ok" type="primary" htmlType="submit">
            Buscar
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default InputTag;
