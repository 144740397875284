import React from "react";
import { Checkbox } from "antd";
import { Streamer } from "../../services/FrequencyManagement/types";
import ButtonsFrequency from "../../components/ButtonsFrequency/ButtonsFrequency";
import { Columns, PropsColumnsTable } from "./type";

const ColumnsTable = ({
  text,
  selectFrequency,
  updateFrequency,
  selectStreamerSearch,
  mode,
  selectAllSearch,
  checkedAllStreamerSearch,
}: PropsColumnsTable) => {
  const columns: Columns = {
    isLive: [
      {
        title: "ID",
        dataIndex: "twitchId",
        align: "center",
      },
      {
        title: "Name",
        dataIndex: "name",
        align: "center",
      },
      {
        title: "Viewers",
        dataIndex: "viewers",
        align: "center",
      },
      {
        title: "Update Frequency",
        dataIndex: "minIntervalSeconds",
        align: "center",
        render: (minIntervalSeconds: number, streamer: Streamer) => {
          return (
            <ButtonsFrequency
              streamer={streamer}
              text={text}
              selectFrequency={selectFrequency}
              updateFrequency={updateFrequency}
              mode={mode}
            />
          );
        },
      },
    ],
    search: [
      {
        title: (
          <Checkbox
            checked={checkedAllStreamerSearch}
            onChange={(e) => (selectAllSearch ? selectAllSearch(e) : null)}
          />
        ),
        dataIndex: "isCheket",
        align: "center",
        render: (minIntervalSeconds: number, streamer: Streamer) => (
          <Checkbox
            checked={streamer.isChecked}
            onChange={(e) =>
              selectStreamerSearch ? selectStreamerSearch(e, streamer) : null
            }
          />
        ),
      },
      {
        title: "ID",
        dataIndex: "twitchId",
        align: "center",
      },
      {
        title: "Name",
        dataIndex: "name",
        align: "center",
      },
      {
        title: "Viewers",
        dataIndex: "viewers",
        align: "center",
      },
      {
        title: "Country",
        dataIndex: "country",
        align: "center",
      },
      {
        title: "Current Frequency",
        dataIndex: "minIntervalSeconds",
        align: "center",
        render: (minIntervalSeconds: number, streamer: Streamer) =>
          streamer.getIntervalMinutes(minIntervalSeconds),
      },
      {
        title: "Update Frequency",
        dataIndex: "minIntervalSeconds",
        align: "center",
        render: (minIntervalSeconds: number, streamer: Streamer) => {
          return (
            <ButtonsFrequency
              streamer={streamer}
              text={text}
              selectFrequency={selectFrequency}
              updateFrequency={updateFrequency}
              mode={mode}
            />
          );
        },
      },
    ],
  };

  return columns[mode];
};

export default ColumnsTable;
