import React from "react";
import { Modal } from "antd";
import StatusTag from "../../StatusTag";
import { ValidStatus } from "../../../commonTypes";
import "./ConfirmationModal.scss";

type ConfirmationModalProps = {
  showModal: boolean;
  metricId: number;
  currentStatus: ValidStatus;
  newStatus: ValidStatus;
  cancelUpdate: () => void;
  updateConfirmation: () => void;
};

const ConfirmationModal = ({
  showModal = false,
  metricId,
  currentStatus,
  newStatus,
  cancelUpdate = () => undefined,
  updateConfirmation = () => undefined,
}: ConfirmationModalProps): JSX.Element => {
  return (
    <Modal
      title="Are you sure you want to continue?"
      visible={showModal}
      onOk={() => updateConfirmation()}
      okText="Confirm"
      onCancel={() => cancelUpdate()}
    >
      <ul className="confirmation-modal-ul">
        <li>
          <span>
            Metric Id: <b>{metricId}</b>
          </span>
        </li>
        <li>
          <span>Current Status:</span>
          <StatusTag status={currentStatus} />
        </li>
        <li>
          <span>New Status:</span>
          <StatusTag status={newStatus} />
        </li>
      </ul>
    </Modal>
  );
};

export default ConfirmationModal;
