import { AxiosResponse } from "axios";
import createPutFn from "../put";
import createPostFn from "../post";
import { AdLandingData } from "../Ad/type";
import { AdLandingCreate } from "./types";

export const baseUrl = `${window._env_.SERVER_URL}/admin/adlanding`;

const put = createPutFn(baseUrl, {
  normalize: (res: AxiosResponse) => res,
});

const post = createPostFn(baseUrl, {
  normalize: (res: AxiosResponse) => res,
});

export const updateAdLanding = (adLanding: AdLandingData) =>
  put("", { ...adLanding });

export const createAdLanding = (adLanding: AdLandingCreate) =>
  post("", { ...adLanding });
