import React from "react";
import InputTag from "./InputTag";
import Tags from "./Tags";
import "./TagsFilter.scss";

type TagsFilterProps = {
  state: string[];
  setState: (v: string[]) => void;
};

const multipleValueFilter = (v: string): string[] => {
  const value = v.trim();
  let separator = "";

  if (value.search(" ") !== -1) {
    separator = " ";
  } else if (value.search(",") !== -1) {
    separator = ",";
  } else if (value.search(";") !== -1) {
    separator = ";";
  }

  if (separator === "") {
    return [v];
  }
  const newTags = new Set(v.trim().split(separator));
  return Array.from(newTags);
};

const TagsFilter = ({
  state = [],
  setState = () => undefined,
}: TagsFilterProps): JSX.Element => {
  function addTag(value: string) {
    if (typeof value === typeof undefined) return;
    const newState = [...state];
    if (!newState.includes(value)) {
      setState([...newState, ...multipleValueFilter(value)]);
    }
  }

  function removeTag(removedValue: string) {
    setState(state.filter((value) => value !== removedValue));
  }

  return (
    <div className="tags-filter-container">
      <InputTag addTag={addTag} />
      <Tags tags={state} removeTag={removeTag} />
    </div>
  );
};

export default TagsFilter;
