import React, { useContext } from "react";
import { Table } from "antd";
import "./ModerationTable.scss";
import { I18nContext } from "react-i18next";
import ColumnGroup from "antd/lib/table/ColumnGroup";
import Column from "antd/lib/table/Column";
import { CalculatedModeration } from "../../services/ModerationV2/type";

interface ModerationTableProps {
  loading: boolean;
  dataSource: CalculatedModeration[];
}
const ModerationTable = ({
  loading,
  dataSource,
}: ModerationTableProps): JSX.Element => {
  const { i18n } = useContext(I18nContext);
  return (
    <Table
      rowKey="adId"
      loading={loading}
      bordered
      dataSource={dataSource}
      pagination={false}
      rowClassName={(record, index) =>
        `table-text ${typeof record.adId === "string" ? "table-row-last" : ""}`
      }
      scroll={{ x: 200 }}
    >
      <Column
        title={i18n.t(`pages.moderationv2.adId`)}
        dataIndex="adId"
        key="adId"
      />
      <Column
        title={i18n.t(`pages.moderationv2.name`)}
        dataIndex="name"
        key="name"
        // className="no-new-line"
      />
      <ColumnGroup title={i18n.t(`pages.moderationv2.status0`)}>
        <Column
          title={i18n.t(`pages.moderationv2.views`)}
          dataIndex="totalViewsStatus0"
          key="totalViewsStatus1"
        />
        <Column
          title={i18n.t(`pages.moderationv2.ads`)}
          dataIndex="totalAdsStatus0"
          key="totalAdsStatus1"
          className="no-new-line"
        />
      </ColumnGroup>
      <ColumnGroup title={i18n.t(`pages.moderationv2.status1`)}>
        <Column
          title={i18n.t(`pages.moderationv2.views`)}
          dataIndex="totalViewsStatus1"
          key="totalViewsStatus1"
        />
        <Column
          title={i18n.t(`pages.moderationv2.ads`)}
          dataIndex="totalAdsStatus1"
          key="totalAdsStatus1"
          className="no-new-line"
        />
      </ColumnGroup>
      <ColumnGroup title={i18n.t(`pages.moderationv2.status2`)}>
        <Column
          title={i18n.t(`pages.moderationv2.views`)}
          dataIndex="totalViewsStatus2"
          key="totalViewsStatus2"
        />
        <Column
          title={i18n.t(`pages.moderationv2.ads`)}
          dataIndex="totalAdsStatus2"
          key="totalAdsStatus2"
          className="no-new-line"
        />
      </ColumnGroup>
      <ColumnGroup title={i18n.t(`pages.moderationv2.status3`)}>
        <Column
          title={i18n.t(`pages.moderationv2.views`)}
          dataIndex="totalViewsStatus3"
          key="totalViewsStatus3"
        />
        <Column
          title={i18n.t(`pages.moderationv2.ads`)}
          dataIndex="totalAdsStatus3"
          key="totalAdsStatus3"
          className="no-new-line"
        />
      </ColumnGroup>
      <ColumnGroup title={i18n.t(`pages.moderationv2.status4`)}>
        <Column
          title={i18n.t(`pages.moderationv2.views`)}
          dataIndex="totalViewsStatus4"
          key="totalViewsStatus4"
        />
        <Column
          title={i18n.t(`pages.moderationv2.ads`)}
          dataIndex="totalAdsStatus4"
          key="totalAdsStatus4"
          className="no-new-line"
        />
      </ColumnGroup>
      <ColumnGroup title={i18n.t(`pages.moderationv2.statusNull`)}>
        <Column
          title={i18n.t(`pages.moderationv2.views`)}
          dataIndex="totalViewsStatusNull"
          key="totalViewsStatusNull"
        />
        <Column
          title={i18n.t(`pages.moderationv2.ads`)}
          dataIndex="totalAdsStatusNull"
          key="totalAdsStatusNull"
          className="no-new-line"
        />
      </ColumnGroup>
      <ColumnGroup title={i18n.t(`pages.moderationv2.totals`)}>
        <Column
          title={i18n.t(`pages.moderationv2.views`)}
          dataIndex="totalViews"
          key="totalViews"
          className="totals"
        />
        <Column
          title={i18n.t(`pages.moderationv2.ads`)}
          dataIndex="totalAds"
          key="totalAds"
          className="totals no-new-line"
        />
      </ColumnGroup>
    </Table>
  );
};

export default ModerationTable;
