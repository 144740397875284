import React, { useState } from "react";
import { Button, DatePicker } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { DatesState } from "../../../commonTypes";
import { channelFilters } from "../../../pages/ChannelsManagement/types";

type DateFilterSelectableProps = {
  format?: string;
  state: channelFilters;
  filterProp: string;
  setState: (v: channelFilters) => void;
};

export const DateFilterSelectable = ({
  format = "DD-MM-YYYY",
  state,
  filterProp,
  setState = () => undefined,
}: DateFilterSelectableProps): JSX.Element => {
  const [filter, setFilter] = useState<DatesState>(null);

  function setSearchValue() {
    setState({ ...state, [filterProp]: filter });
    setFilter(null);
  }

  return (
    <>
      <Button
        type="primary"
        onClick={setSearchValue}
        disabled={!filter?.[0].isValid() || !filter?.[1].isValid()}
      >
        <SearchOutlined />
      </Button>
      <DatePicker.RangePicker
        value={filter}
        format={[format, format]}
        onCalendarChange={(dates) => {
          if (dates && dates[0] && dates[1]) {
            const initDate = dates[0].set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            });
            const endDate = dates[1].set({
              hour: 23,
              minute: 59,
              second: 59,
              millisecond: 59,
            });
            setFilter([initDate, endDate]);
          } else {
            setFilter(null);
          }
        }}
        allowEmpty={[false, false]}
      />
    </>
  );
};

DateFilterSelectable.defaultProps = {
  format: "DD-MM-YYYY",
};
