import React, { useState, useEffect, useContext } from "react";
import { Space, Select, Form, Button } from "antd";
import { I18nContext } from "react-i18next";
import ConfirmationModal from "../ConfirmationModal";
import { ValidStatus } from "../../../commonTypes";
import { StatusData, UpdateStatusData } from "../type";
import "./UpdateStatus.scss";

const { Item, useForm } = Form;
const { Option } = Select;

type UpdateStatusProps = {
  data: StatusData;
  updateStatus: (v: UpdateStatusData) => void;
};
type OnFinish = (v: { [name: string]: string }) => void;

const UpdateStatus = ({
  data,
  updateStatus,
}: UpdateStatusProps): JSX.Element => {
  const { i18n } = useContext(I18nContext);

  const [initialOptions] = useState([
    { label: "unset", value: "" },
    { label: "approved", value: 1 },
    { label: "missing_ad", value: 2 },
    { label: "wrong_size", value: 3 },
    { label: "covered_ad", value: 4 },
  ]);
  const [form] = useForm();
  const [showModal, setShowModal] = useState(false);
  const [options, setOptions] = useState(
    initialOptions.filter(({ value }) => value !== data.status)
  );
  const [newStatusValue, setNewStatusValue] = useState(-1);

  useEffect(() => {
    setOptions(initialOptions.filter(({ value }) => value !== data.status));
  }, [data.status, initialOptions]);

  const onFinish: OnFinish = ({ select }) => {
    if (select !== null) setShowModal(true);
  };

  const cancelUpdate = () => {
    setShowModal(false);
  };

  const updateConfirmation = () => {
    updateStatus({
      adMetricId: data.adMetricId,
      status: form.getFieldValue("select"),
    });
    setShowModal(false);
  };

  const onValueChange = (changedValues: any, values: any) => {
    setNewStatusValue(parseInt(values.select, 16));
  };

  return (
    <Space>
      <Form
        form={form}
        initialValues={{ select: "" }}
        layout="inline"
        onFinish={onFinish}
        onValuesChange={onValueChange}
      >
        <Item name="select">
          <Select
            size="small"
            className="update-status-select"
            dropdownClassName="update-status-select__dropdown"
          >
            {options.map(({ label, value }) => (
              <Option key={label} value={value}>
                {i18n.t(`pages.moderation.${label}`)}
              </Option>
            ))}
          </Select>
        </Item>
        <Item>
          <Button
            size="small"
            type="primary"
            htmlType="submit"
            disabled={![1, 2, 3, 4].includes(newStatusValue)}
          >
            Update
          </Button>
        </Item>
      </Form>
      <ConfirmationModal
        showModal={showModal}
        metricId={data.adMetricId}
        currentStatus={data.status}
        newStatus={form.getFieldValue("select")}
        cancelUpdate={cancelUpdate}
        updateConfirmation={updateConfirmation}
      />
    </Space>
  );
};

export default UpdateStatus;
