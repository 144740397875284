import React from "react";
import { Card, Image } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "./imageAd.scss";

interface Props {
  hidenInput: any;
  handelClick: (e: any) => void;
  handelFileImgAndVideo: (e: any, c: any, type: string, callback: any) => void;
  setImgPreview: (v: any) => void;
  imageUrl: string;
  modeType: string;
  setImg: (file: any) => void;
}

const ImageAd = ({
  hidenInput,
  handelClick,
  handelFileImgAndVideo,
  setImgPreview,
  imageUrl,
  modeType,
  setImg,
}: Props) => {
  return (
    <>
      {modeType === "createAd" ? (
        <button
          className="btn-upload-img"
          type="button"
          color="primary"
          onClick={handelClick}
        >
          {imageUrl !== "" ? <Image src={imageUrl} alt="img" /> : null}
          <label htmlFor="contained-button-file">
            <input
              type="file"
              id="contained-button-file-1"
              style={{ display: "none" }}
              ref={hidenInput}
              onChange={(e) =>
                handelFileImgAndVideo(e, setImgPreview, "overlayUrl", setImg)
              }
            />
            {imageUrl === "" ? (
              <>
                <PlusOutlined />
                <p>Subir</p>
              </>
            ) : null}
          </label>
        </button>
      ) : (
        <Image src={imageUrl} alt="img" />
      )}
    </>
  );
};

export default ImageAd;
