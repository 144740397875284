import React, {
  FunctionComponent,
  useContext,
  useState,
  useEffect,
} from "react";
import locale from "antd/es/date-picker/locale/es_ES";
import moment from "moment";
import Helmet from "react-helmet";
import { I18nContext } from "react-i18next";
import { Col, DatePicker, Row } from "antd";
import { getModerationData } from "../../services/ModerationV2/Moderation";
import "./ModerationV2.scss";
import {
  CalculatedModeration,
  RawModeration,
  ModerationTable,
} from "../../services/ModerationV2/type";
import ModerationTableV2 from "../../components/ModerationTableV2";

const ModerationV2: FunctionComponent = () => {
  const { i18n } = useContext(I18nContext);

  const [loading, setLoading] = useState(true);
  const [moderationDate, setModerationData] = useState<Array<RawModeration>>(
    []
  );
  const [dateSelected, setDateSelected] = useState(moment().locale("es"));
  const dateFormat = "DD-MM-YYYY";

  const calculateTotals = (
    moderationData: RawModeration[]
  ): CalculatedModeration[] => {
    const calculatedRows: CalculatedModeration[] = moderationData.map(
      (element) => ({
        ...element,
        totalViews:
          element.totalViewsStatus0 +
          element.totalViewsStatus1 +
          element.totalViewsStatus2 +
          element.totalViewsStatus3 +
          element.totalViewsStatus4 +
          element.totalViewsStatusNull,
        totalAds:
          element.totalAdsStatus0 +
          element.totalAdsStatus1 +
          element.totalAdsStatus2 +
          element.totalAdsStatus3 +
          element.totalAdsStatus4 +
          element.totalAdsStatusNull,
      })
    );

    const finalId = i18n.t(`pages.moderationv2.absoluteTotals`);

    const totals: CalculatedModeration = {
      adId: finalId,
      name: "",
      totalViewsStatus0: 0,
      totalAdsStatus0: 0,
      totalViewsStatus1: 0,
      totalAdsStatus1: 0,
      totalViewsStatus2: 0,
      totalAdsStatus2: 0,
      totalViewsStatus3: 0,
      totalAdsStatus3: 0,
      totalViewsStatus4: 0,
      totalAdsStatus4: 0,
      totalViewsStatusNull: 0,
      totalAdsStatusNull: 0,
      totalViews: 0,
      totalAds: 0,
    };

    calculatedRows.forEach((element: CalculatedModeration) => {
      totals.totalViewsStatus0 += element.totalViewsStatus0;
      totals.totalAdsStatus0 += element.totalAdsStatus0;
      totals.totalViewsStatus1 += element.totalViewsStatus1;
      totals.totalAdsStatus1 += element.totalAdsStatus1;
      totals.totalViewsStatus2 += element.totalViewsStatus2;
      totals.totalAdsStatus2 += element.totalAdsStatus2;
      totals.totalViewsStatus3 += element.totalViewsStatus3;
      totals.totalAdsStatus3 += element.totalAdsStatus3;
      totals.totalViewsStatus4 += element.totalViewsStatus4;
      totals.totalAdsStatus4 += element.totalAdsStatus4;
      totals.totalViewsStatusNull += element.totalViewsStatusNull;
      totals.totalAdsStatusNull += element.totalAdsStatusNull;
      totals.totalViews +=
        element.totalViewsStatus0 +
        element.totalViewsStatus1 +
        element.totalViewsStatus2 +
        element.totalViewsStatus3 +
        element.totalViewsStatus4 +
        element.totalViewsStatusNull;
      totals.totalAds +=
        element.totalAdsStatus0 +
        element.totalAdsStatus1 +
        element.totalAdsStatus2 +
        element.totalAdsStatus3 +
        element.totalAdsStatus4 +
        element.totalAdsStatusNull;
    });

    return [...calculatedRows, totals];
  };

  useEffect(() => {
    getModerationData(
      `/?start_date=${dateSelected.toISOString().split("T")[0]}&end_date=${
        dateSelected.toISOString().split("T")[0]
      }`
    )
      .then((data) => {
        setModerationData(data.rows);
      })
      .finally(() => setLoading(false));
  }, [dateSelected]);

  return (
    <section>
      <Helmet>
        <title>Streamion | {i18n.t(`pages.moderationv2.title`)}</title>
      </Helmet>
      <Row gutter={45}>
        <Col span={24}>
          <Row className="space-element">
            <h1 className="section-tittle mb-1">
              {i18n.t(`pages.moderationv2.title`)}
            </h1>
          </Row>
        </Col>
        <Col span={24}>
          <Row className="space-element">
            <p className="mb-3">{i18n.t(`pages.moderationv2.datePicker`)}</p>
          </Row>
          <Row className="space-element">
            <DatePicker
              value={dateSelected}
              format={dateFormat}
              disabledDate={(date) => {
                return date > moment();
              }}
              clearIcon={<div />}
              locale={locale}
              onChange={(dateValue) => {
                setDateSelected(moment(dateValue));
                setLoading(true);
              }}
            />
          </Row>
        </Col>
        <Col span={24}>
          <ModerationTableV2
            loading={loading}
            dataSource={calculateTotals(moderationDate)}
          />
        </Col>
      </Row>
    </section>
  );
};

export default ModerationV2;
