import React, { useContext } from "react";

import { Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import "./editSaveViewersFrequency.scss";

import { I18nContext } from "react-i18next";
import { ViewersFrequency } from "../../../services/FrequencyManagement/types";

interface Props {
  viewersFrequency: ViewersFrequency;
  edit: boolean;
  edditViewersFrequency: (viewrsFrequencyId: string) => void;
  createViewersFrequency: (viewrsFrequency: ViewersFrequency) => void;
  updateViewersFrequency: (viewrsFrequency: ViewersFrequency) => void;
}

const EditSaveViewersFrequency = ({
  edit,
  viewersFrequency,
  edditViewersFrequency,
  createViewersFrequency,
  updateViewersFrequency,
}: Props) => {
  const { i18n } = useContext(I18nContext);
  const onClick = () => {
    if (viewersFrequency.id === "newViewersFrequency") {
      createViewersFrequency(viewersFrequency);
    } else {
      updateViewersFrequency(viewersFrequency);
    }
  };

  return (
    <div className="container-edti-stopper">
      {!edit ? (
        <Button
          className="edit-btn"
          icon={<EditOutlined />}
          onClick={() => edditViewersFrequency(viewersFrequency.id)}
        >
          {i18n.t("components.viewersFrequency.table.btn_edit")}
        </Button>
      ) : (
        <div className="wrapper-btns">
          <Button type="primary" onClick={onClick}>
            {" "}
            {i18n.t("components.viewersFrequency.table.btn_save")}{" "}
          </Button>
          <Button
            size="middle"
            onClick={() => edditViewersFrequency(viewersFrequency.id)}
          >
            {i18n.t("components.viewersFrequency.table.btn_cancel")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default EditSaveViewersFrequency;
