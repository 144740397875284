import { Button } from "antd";
import React from "react";
import InputTag from "./InputTag";
import "./TagsFilter.scss";

type TagsFilterProps = {
  state: string[];
  setState: (v: string[]) => void;
  placeHolder: string | "";
};

const multipleValueFilter = (v: string): string[] => {
  const value = v.trim();
  let separator = "";

  if (value.search(" ") !== -1) {
    separator = " ";
  } else if (value.search(",") !== -1) {
    separator = ",";
  } else if (value.search(";") !== -1) {
    separator = ";";
  } else if (value.search("\n") !== -1) {
    separator = "\n";
  }

  if (separator === "") {
    return [v];
  }
  const newTags = new Set(v.trim().split(separator));
  return Array.from(newTags);
};

const TagsFilter = ({
  state = [],
  setState = () => undefined,
  placeHolder,
}: TagsFilterProps): JSX.Element => {
  function addTag(value: string) {
    if (typeof value === typeof undefined) return;
    const newState = [...state];
    if (!newState.includes(value.toLowerCase())) {
      setState(
        Array.from(
          new Set([...newState, ...multipleValueFilter(value.toLowerCase())])
        )
      );
    }
  }

  function removeTag(removedValue: string) {
    setState(state.filter((value) => value !== removedValue.toLowerCase()));
  }

  function resetTags() {
    setState([]);
  }

  return (
    <div className="tags-filter-container">
      <InputTag
        addTag={addTag}
        resetTags={resetTags}
        placeHolder={placeHolder}
      />
      <div className="filter-container">
        <div className="button-wrapper">
          {state.map((el) => (
            <Button className="inner-button" onClick={() => removeTag(el)}>
              {el}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TagsFilter;
