import React, { useState } from "react";
import { Dropdown, Button, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Props, ModeUpdateStreamers } from "./type";

export const frequenciesList: number[] = [
  120,
  105,
  90,
  75,
  60,
  45,
  40,
  30,
  20,
  15,
  12,
];
const ButtonsFrequency = ({
  text,
  selectFrequency,
  updateFrequency,
  streamer,
  mode,
}: Props) => {
  const [selectedFrequency, setSlectedFrequency] = useState<number>(90);

  const modeUpdateStreamers: ModeUpdateStreamers = {
    group: {
      title: "Update Frequency to ",
      frequencySelected: () =>
        frequenciesList.map((frequency: number) =>
          frequency !== selectedFrequency ? (
            <Menu.Item key={frequency}>{frequency}</Menu.Item>
          ) : null
        ),
      frequencyDefault: () => selectedFrequency,
      button: () => (
        <Button
          className="ant-btn ant-btn-primary"
          size="small"
          onClick={() => updateFrequency()}
        >
          {text}
        </Button>
      ),
    },
    isLive: {
      title: "",
      frequencySelected: () =>
        frequenciesList.map((frequency: number) =>
          frequency !==
          streamer.getIntervalMinutes(streamer.minIntervalSeconds) ? (
            <Menu.Item key={frequency}>{frequency}</Menu.Item>
          ) : null
        ),
      frequencyDefault: () =>
        streamer.getIntervalMinutes(streamer.minIntervalSeconds),
      button: () => (
        <Button
          className="ant-btn ant-btn-primary"
          id={streamer.twitchId}
          size="small"
          disabled={streamer.disabled}
          onClick={() => updateFrequency(streamer)}
        >
          {text}
        </Button>
      ),
    },
    search: {
      title: "",
      frequencySelected: () =>
        frequenciesList.map((frequency: number) =>
          frequency !==
          streamer.getIntervalMinutes(streamer.minIntervalSeconds) ? (
            <Menu.Item key={frequency}>{frequency}</Menu.Item>
          ) : null
        ),
      frequencyDefault: () =>
        streamer.getIntervalMinutes(streamer.minIntervalSeconds),
      button: () => (
        <Button
          className="ant-btn ant-btn-primary"
          id={streamer.twitchId}
          size="small"
          disabled={streamer.disabled}
          onClick={() => updateFrequency(streamer)}
        >
          {text}
        </Button>
      ),
    },
  };

  return (
    <div className="wrapper-button-table" key={streamer ? streamer.id : ""}>
      <p className="title">{modeUpdateStreamers[mode].title}</p>
      <Dropdown
        overlay={
          <Menu
            onClick={(e: any) => {
              setSlectedFrequency(parseInt(e.key, 10));
              selectFrequency(e, streamer, mode);
            }}
          >
            {modeUpdateStreamers[mode].frequencySelected()}
          </Menu>
        }
      >
        <Button size="small">
          {modeUpdateStreamers[mode].frequencyDefault()} <DownOutlined />
        </Button>
      </Dropdown>
      {modeUpdateStreamers[mode].button()}
    </div>
  );
};

export default ButtonsFrequency;
