export const USER_LOGIN = "AUTH_USER_LOGIN";
export const LOG_OUT = "AUTH_USER_LOGOUT";

export type User = {
  email?: string;
  roleId?: number;
};

export type AuthState = {
  authenticated: boolean;
  user: User;
};

interface UserLoginAction {
  type: typeof USER_LOGIN;
  payload: {
    user: User;
  };
}

interface LogOutAction {
  type: typeof LOG_OUT;
}

export type AuthActionTypes = UserLoginAction | LogOutAction;
