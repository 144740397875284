import React, {
  useContext,
  useState,
  useEffect,
  FunctionComponent,
} from "react";
import "./channelsManagement.scss";
import { I18nContext } from "react-i18next";
import Helmet from "react-helmet";
import { Button, Col, Row } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import {
  getChannelData,
  putStreamerCreateAutomaticTip,
} from "../../services/ChannelsManagement/ChannelsManagement";
import { UpdateStatusData } from "../../components/ChannelTable/types";
import { Channel, Country } from "../../services/ChannelsManagement/types";
import ChannelTable from "../../components/ChannelTable/ChannelTable";
import { DatesState } from "../../commonTypes";
import { channelFilters } from "./types";
import { getCountriesMonetization } from "../../services/ChannelsManagement/CountryMonetization";

const ChannelManagement: FunctionComponent = () => {
  const initChannelData: Channel[] = [];
  const { i18n } = useContext(I18nContext);
  const [loading, setLoading] = useState(true);
  const [rawChanels, setRawChannels] = useState(initChannelData);
  const [countries, setCountries] = useState<Country[]>([]);

  const baseI18N = "pages.channels.table.filters";

  const twitchId = "";
  const displayName = "";
  const email = "";
  const createdAt: DatesState = null;
  const affiliateEmail = "";
  const monetizationRangeId = "";
  const monetizationGroupId = "";
  const countryId = "";
  const countryCode = "";

  const initialFilters: channelFilters = {
    twitchId,
    displayName,
    email,
    createdAt,
    affiliateEmail,
    monetizationRangeId,
    monetizationGroupId,
    countryId,
    countryCode,
  };

  const [allFilters, setAllFilters] = useState(initialFilters);

  useEffect(() => {
    if (!rawChanels.length) {
      getChannelData("").then((channelData) => {
        setRawChannels(channelData);
      });
      getCountriesMonetization("").then((countriesData) =>
        setCountries(countriesData)
      );
    }
    setLoading(false);
  }, [rawChanels, allFilters]);

  const streamerActivation = async ({
    streamerId,
    createAutomaticTip,
  }: UpdateStatusData): Promise<void> => {
    setLoading(true);
    try {
      const status = createAutomaticTip ? "activate" : "deactivate";
      const activationResponse = await putStreamerCreateAutomaticTip(
        streamerId,
        status
      );

      if (activationResponse.status !== 200) {
        throw new Error("Country or monetization not set.");
      }

      const index = rawChanels.findIndex(
        (element) => element.streamerId === streamerId
      );

      const auxData = [...rawChanels];
      auxData[index] = {
        ...auxData[index],
        createAutomaticTip,
      };
      setRawChannels(auxData);
      setLoading(true);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const updateRawChannels = (
    updatedValues: {
      countryId: string;
      countryName: string;
      groupId: string;
      groupName: string;
      rangeId: string;
      rangeName: string;
    },
    channel: Channel
  ) => {
    const index = rawChanels.findIndex(
      (element) => element.streamerId === channel.streamerId
    );

    const auxData = [...rawChanels];
    auxData[index] = {
      ...auxData[index],
      countryId: updatedValues.countryId,
      countryCode: updatedValues.countryName,
      monetizationGroupId: updatedValues.groupId,
      monetizationGroupName: updatedValues.groupName,
      monetizationRangeId: updatedValues.rangeId,
      monetizationRangeName: updatedValues.rangeName,
    };
    setRawChannels(auxData);
  };

  const resetFilters = () => {
    setAllFilters(initialFilters);
  };

  const getCountryById = (id: string) =>
    countries.filter((element) => element.id === id);

  const getMonetizationGroupById = (id: string) => {
    const arrayGroups = countries.map((countryElement) =>
      countryElement.groups.map((group) => ({ id: group.id, name: group.name }))
    );

    if (arrayGroups.length === 0) {
      return [{ id: "", name: "" }];
    }

    const groups: { id: string; name: string }[] = Object.assign(
      [],
      ...arrayGroups
    );
    return groups.filter((element) => element.id === id);
  };
  const getMonetizationRangeById = (id: string) => {
    const arrayRanges = countries.map((countryElement) =>
      countryElement.groups.map((group) =>
        group.range.map((range) => ({ id: range.id, name: range.name }))
      )
    );

    if (arrayRanges.length === 0) {
      return [{ id: "", name: "" }];
    }

    const ranges: { id: string; name: string }[] = Object.assign(
      [],
      ...arrayRanges
    ).flat();

    return ranges.filter((element) => element.id === id);
  };

  const showDataValue = (
    filterValue: string | DatesState | boolean,
    keyProp: string
  ) => {
    if (typeof filterValue === "string") {
      if (keyProp === "countryId") {
        return getCountryById(filterValue)[0].code || filterValue;
      }
      if (keyProp === "monetizationGroupId") {
        return getMonetizationGroupById(filterValue)[0].name || filterValue;
      }
      if (keyProp === "monetizationRangeId") {
        return getMonetizationRangeById(filterValue)[0].name || filterValue;
      }
      return filterValue;
    }
    if (filterValue && Array.isArray(filterValue)) {
      return `${filterValue[0].format("DD-MM-YYYY")} / ${filterValue[1].format(
        "DD-MM-YYYY"
      )}`;
    }
    if (typeof filterValue === "boolean") {
      return filterValue ? "true" : "false";
    }
    return "";
  };

  const isFiltersEmpty = () => {
    return Object.values(allFilters).every((x) => x === null || x === "");
  };

  const className = `clearFilters ${!isFiltersEmpty() ? "marginLeft" : ""}`;

  return (
    <div className="container-channels">
      <h1>{i18n.t(`pages.channels.title`)}</h1>
      <section className="moderation-page">
        <Helmet>
          <title>Streamion | {i18n.t("pages.moderation.title")}</title>
        </Helmet>
        <Row gutter={45}>
          <Col xs={24} style={{ marginBottom: "10px" }}>
            <div className="filterWrapper">
              <div className="filters">
                {Object.entries(allFilters).map((filterUsed) =>
                  filterUsed[1] ? (
                    <Button
                      className="activeFilter"
                      onClick={() =>
                        setAllFilters({ ...allFilters, [filterUsed[0]]: "" })
                      }
                    >
                      {i18n.t(`${baseI18N}.${filterUsed[0]}`)}:{" "}
                      {showDataValue(filterUsed[1], filterUsed[0])}
                      <CloseOutlined />
                    </Button>
                  ) : null
                )}
              </div>
              <div className="actionsButtons">
                <Button
                  type="primary"
                  className={className}
                  icon={<span />}
                  onClick={resetFilters}
                >
                  {i18n.t(`pages.channels.buttons.clearFilter`)}
                </Button>
              </div>
            </div>
          </Col>
          <Col xs={24} className="tableWrapper">
            <ChannelTable
              setLoading={setLoading}
              updateRawChannels={updateRawChannels}
              rawChanels={rawChanels}
              streamerActivation={streamerActivation}
              allFilters={allFilters}
              getCountryById={getCountryById}
              getMonetizationGroupById={getMonetizationGroupById}
              getMonetizationRangeById={getMonetizationRangeById}
              setAllFilters={setAllFilters}
              countries={countries}
              loading={loading}
              pagination={{ defaultPageSize: 10 }}
            />
          </Col>
        </Row>
      </section>
    </div>
  );
};

export default ChannelManagement;
