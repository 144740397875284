interface RegexType {
  [key: string]: RegExp;
}

/*eslint-disable */
// prettier-ignore
const typeRegex: RegexType = {
  chatMessage: /{url_0}/,
};
/* eslint-enable */

export const validateString = (text: string, regexType: string) => {
  return typeRegex[regexType].test(text);
};
