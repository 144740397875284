import axios from "axios";
import * as R from "ramda";

import { getTokenFromStorage } from "./_auth";

const putData = R.curry((baseUrl, options, id, data) =>
  axios
    .put(`${baseUrl}/${id}`, data, {
      headers: {
        Authorization: getTokenFromStorage(),
      },
    })
    .then(options.normalize)
);

/**
 *
 * @param {String} baseUrl
 * @param {Object} options
 * @param {Function} options.normalize
 * @param {String} id
 * @param {Object} baseUrl
 */
export default (baseUrl: string, options: { [key: string]: any }): any =>
  putData(baseUrl, options);
