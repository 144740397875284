import React from "react";
import i18next from "i18next";
import { SearchOutlined } from "@ant-design/icons";
import { Checkbox, Tag } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { TableColumnFormat } from "../../../../commonTypes";
import { UpdateStatusData } from "./types";
import { StreamerAgreement } from "../../../../services/Agreements/types";

type getColumnsArguments = {
  onUpdateStatus: (v: UpdateStatusData) => Promise<void>;
  StreamerIdFilter: JSX.Element;
  DisplayNameFilter: JSX.Element;
  CountryFilter: JSX.Element;
  IncludedFilter: JSX.Element;
  ViewersFilter: JSX.Element;
  allSelected: boolean;
  setAllSelected: (v: boolean) => void;
  selectAllStreamers: (v: string[], checked: boolean) => void;
  checkFiltersLists: () => StreamerAgreement[];
  updateSingleStreamer: (v: string, checked: boolean) => void;
};

const baseI18N = "pages.channels.table.headers";

const getColumns = ({
  StreamerIdFilter,
  DisplayNameFilter,
  CountryFilter,
  IncludedFilter,
  ViewersFilter,
  allSelected,
  setAllSelected,
  selectAllStreamers,
  checkFiltersLists,
  updateSingleStreamer,
}: getColumnsArguments): TableColumnFormat => {
  const handleSelectAll = (e: CheckboxChangeEvent) => {
    selectAllStreamers(
      checkFiltersLists().map((el) => el.twitchId),
      e.target.checked
    );

    setAllSelected(e.target.checked);
  };

  const handleSingleCheck = (e: CheckboxChangeEvent) => {
    updateSingleStreamer(e.target.value, e.target.checked);
  };

  return [
    {
      title: <Checkbox checked={allSelected} onChange={handleSelectAll} />,
      dataIndex: "isChecked",
      align: "center",
      render: (minIntervalSeconds: number, streamer: StreamerAgreement) => (
        <Checkbox
          defaultChecked={streamer.isChecked}
          checked={streamer.isChecked}
          onChange={handleSingleCheck}
          value={streamer.twitchId}
        />
      ),
    },
    {
      title: i18next.t(`${baseI18N}.name`),
      dataIndex: "twitchUser.displayName",
      align: "left",
      render: (value: string, streamer: StreamerAgreement) =>
        streamer.displayName || "-",
      filterIcon: (filtered) => <SearchOutlined />,
      filterDropdown: () => DisplayNameFilter,
    },
    {
      title: i18next.t(`${baseI18N}.id`),
      dataIndex: "twitchUser['id']",
      align: "left",
      filterIcon: (filtered) => <SearchOutlined />,
      render: (value: string, streamer: StreamerAgreement) =>
        streamer.twitchId || "-",
      filterDropdown: () => StreamerIdFilter,
    },
    {
      title: "Status",
      dataIndex: "hasAgreement",
      align: "left",
      render: (value: string, streamer: StreamerAgreement) =>
        streamer.hasAgreement ? (
          <Tag color="blue">Incluido</Tag>
        ) : (
          <Tag>No incluido</Tag>
        ),
      filterDropdown: () => IncludedFilter,
    },
    {
      title: i18next.t(`${baseI18N}.viewers`),
      dataIndex: "avgViewers",
      align: "left",
      sorter: (a: StreamerAgreement, b: StreamerAgreement) =>
        a.avgViewers - b.avgViewers,
      filterDropdown: () => ViewersFilter,
    },
    {
      title: i18next.t(`${baseI18N}.country`),
      dataIndex: "country['code']",
      filterDropdown: () => CountryFilter,
      align: "left",
      render: (value: boolean, channel: StreamerAgreement) =>
        i18next.t(`pages.channels.countries.${channel.countryCode}`),
    },
  ];
};

export default getColumns;
