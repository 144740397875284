import { Button, Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import React, { useState } from "react";
import i18n from "../../../../../i18n";
import { Countries } from "../../../../../services/Country/types";
import "./CountryFilter.scss";

type CountryFilterProps = {
  state: string[];
  countries: Countries[];
  setState: (v: string[]) => void;
};

const CountryFilter = ({
  state,
  setState,
  countries,
}: CountryFilterProps): JSX.Element => {
  const [innerState, setInnerState] = useState<string[]>(state);

  function reset() {
    setState([]);
    setInnerState([]);
  }

  const handleClick = (e: CheckboxChangeEvent) => {
    const { checked, value } = e.target;
    if (checked) setInnerState(Array.from(new Set([...innerState, value])));
    else setInnerState(innerState.filter((el) => el !== value));
  };

  return (
    <div className="country-filter-container">
      {countries.map((el) => (
        <div className="checkbox">
          <Checkbox
            value={el.id}
            name={el.code}
            onChange={handleClick}
            checked={innerState.includes(el.id)}
          >
            {i18n.t(`pages.channels.countries.${el.code}`)}
          </Checkbox>
        </div>
      ))}

      <div className="buttonWrapp">
        <Button type="default" className="button-reset" onClick={reset}>
          Reset
        </Button>
        <Button
          type="primary"
          className="button-ok"
          onClick={() => setState(innerState)}
        >
          Ok
        </Button>
      </div>
    </div>
  );
};

export default CountryFilter;
