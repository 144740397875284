import React, { useCallback, FunctionComponent } from "react";
import {
  UserOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { Avatar, Button } from "antd";
import { useMappedState, useDispatch } from "redux-react-hook";
import { logOut } from "../../../store/auth/actions";
import { AuthActionTypes } from "../../../store/auth/types";
import "./Navbar.scss";

type NavbarProps = {
  handleToggleCollapsed: () => void;
  isCollapsed: boolean;
  editAd?: boolean;
};

type Auth = {
  auth: { user: { email: string } };
};

type UserInfo = {
  email: string;
};

const Navbar: FunctionComponent<NavbarProps> = ({
  handleToggleCollapsed,
  isCollapsed,
  editAd,
}) => {
  const dispatch = useDispatch();
  const mapState = useCallback(
    (state: Auth): UserInfo => ({
      email: state.auth.user.email,
    }),
    []
  );
  const { email } = useMappedState(mapState);

  return (
    <div className="top-bar">
      <div className="top-bar__collapse_container">
        {!editAd ? (
          <Button
            onClick={(): void => handleToggleCollapsed()}
            className="top-bar__collapse_container__btn"
            type="text"
          >
            {isCollapsed ? (
              <MenuUnfoldOutlined style={{ color: "grey" }} />
            ) : (
              <MenuFoldOutlined style={{ color: "grey" }} />
            )}
          </Button>
        ) : null}
      </div>
      <div className="top-bar__actions">
        <div className="top-bar__actions__user">
          <Avatar size="small" icon={<UserOutlined />} />
          <span className="top-bar__actions__user__email">{email}</span>
        </div>
        <Button
          className="top-bar__actions__logout-btn"
          onClick={(): AuthActionTypes => dispatch(logOut())}
        >
          <LogoutOutlined />
          <span>Logout</span>
        </Button>
      </div>
    </div>
  );
};

export default Navbar;
