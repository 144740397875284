import { AxiosResponse } from "axios";
import createGetFn from "../get";
import createPutFn from "../put";
import createPostFn from "../post";

import { ActiveCampaignTable, Ad, ActiveCampaign } from "./type";
import { CampaignData } from "../Ad/type";

export const baseUrl = `${window._env_.SERVER_URL}/admin/campaign/metrics`;
export const baseUrlUpdateAds = `${window._env_.SERVER_URL}/admin/ad/ads`;
export const baseUrlAdvertiser = `${window._env_.SERVER_URL}/admin/campaign/agency/`;
export const baseUrlCampaign = `${window._env_.SERVER_URL}/admin/campaign`;

const get = createGetFn(baseUrl, {
  normalize: (res: { data: ActiveCampaignTable }) => {
    const { rows } = res.data;
    const listCampaignActive: ActiveCampaign[] = rows.map(
      (campaign: ActiveCampaign) => {
        return { ...campaign, country: campaign.ads[0].country };
      }
    );
    return listCampaignActive;
  },
});

export const getActiveCampaignData = (): Promise<Array<ActiveCampaign>> =>
  get("");

const put = createPutFn(baseUrlUpdateAds, {
  normalize: (res: AxiosResponse) => res,
});

export const putActivateDeactivateCampaing = (
  ids: number[],
  status: boolean
): Promise<AxiosResponse<{ ads: Ad[] }>> => {
  return put(``, {
    ids,
    status,
  });
};

const getCampaignName = createGetFn(baseUrlAdvertiser, {
  normalize: (res: any): CampaignData[] => {
    const { data } = res;
    const camapigns: CampaignData[] = data.map((campaing: any) => {
      return {
        id: campaing.id,
        title: campaing.title,
        advertiserId: campaing.advertiserId,
      };
    });
    return camapigns;
  },
});

const postCampaign = createPostFn(baseUrlCampaign, {
  normalize: (res: any): CampaignData => {
    const { data } = res;
    const { id, title, advertiserId } = data;
    return { id, title, advertiserId };
  },
});

const putCampaigm = createPutFn(baseUrlCampaign, {
  normalize: (res: AxiosResponse) => res,
});

export const getCampaignNames = (advertiserId: string) =>
  getCampaignName(advertiserId);

export const updateCampaign = (campaign: CampaignData) =>
  putCampaigm("", { ...campaign });

export const createCampaign = (advertiserId: string, campaignName: string) =>
  postCampaign("", { advertiserId, title: campaignName });
