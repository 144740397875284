import React from "react";
import { Table, Divider } from "antd";
import { RightOutlined, DownOutlined } from "@ant-design/icons";
import "./activeCampaignTable.scss";
import getColumns from "./getColumns";
import { ActiveCampaign, Ad } from "../../services/Campaign/type";
import InfoCampaign from "./InfoCampaign/InfoCampaign";

interface ActiveCampaignsTableProps {
  statusCampaingChange: (e: boolean, campaign: ActiveCampaign) => void;
  statusAd: (e: boolean, ad: Ad, campaignName: string) => void;
  loading: boolean;
  dataSource: ActiveCampaign[];
}
const ActiveCampaignsTable = ({
  statusCampaingChange,
  statusAd,
  loading,
  dataSource,
}: ActiveCampaignsTableProps): JSX.Element => {
  return (
    <Table
      rowKey="campaignId"
      loading={loading}
      dataSource={dataSource}
      columns={getColumns({ statusCampaingChange })}
      expandable={{
        expandedRowRender: (campaign) => {
          const showSwitch = campaign.ads.length > 1;
          return campaign.ads.map((ad: Ad) => (
            <>
              <InfoCampaign
                key={ad.adId}
                ad={ad}
                showSwitch={showSwitch}
                statusAd={statusAd}
                campaignName={campaign.campaignName}
              />
              <Divider />
            </>
          ));
        },
        expandIcon: ({ expanded, onExpand, record }) =>
          expanded ? (
            <DownOutlined onClick={(e) => onExpand(record, e)} />
          ) : (
            <RightOutlined onClick={(e) => onExpand(record, e)} />
          ),
      }}
    />
  );
};

export default ActiveCampaignsTable;
