import React, { useContext } from "react";
import { Modal, Button, Input, Spin } from "antd";
import "./modalCreate.scss";
import { I18nContext } from "react-i18next";

interface Props {
  visible: boolean;
  ok: () => void;
  cancel: () => void;
  title: string;
  message: string;
  loading: boolean;
  onChange: (text: string) => void;
}

const ModalCreate = ({
  visible,
  ok,
  cancel,
  title,
  message,
  loading,
  onChange,
}: Props) => {
  const { i18n } = useContext(I18nContext);
  return (
    <Modal
      className="modal-create"
      title={i18n.t(title)}
      visible={visible}
      onCancel={cancel}
      width={317}
      centered
      footer={[
        <Button onClick={cancel} key="back">
          {i18n.t("pages.createAd.modal.btn.cancel")}
        </Button>,
        <Button onClick={ok} key="submit" type="primary">
          {i18n.t("pages.createAd.modal.btn.create")}
          {loading ? (
            <span className="modal-create-loading">
              {" "}
              <Spin size="small" />{" "}
            </span>
          ) : null}
        </Button>,
      ]}
    >
      <div>
        <p className="message-modal-create">{i18n.t(message)}</p>
        <Input onChange={(e) => onChange(e.target.value)} />
      </div>
    </Modal>
  );
};

export default ModalCreate;
