import { Button, Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import "./OptionsFilter.scss";

type OptionsFilter = {
  state: { included: boolean; excluded: boolean };
  setState: (v: { included: boolean; excluded: boolean }) => void;
};

const OptionsFilter = ({ state, setState }: OptionsFilter): JSX.Element => {
  const [innerState, setInnerState] = useState(state);

  function reset() {
    setState({ included: false, excluded: false });
  }

  return (
    <div className="options-filter-container">
      <div className="checkbox">
        <Checkbox
          value={innerState.included}
          onClick={() =>
            setInnerState({
              included: !innerState?.included,
              excluded: innerState?.excluded,
            })
          }
        >
          Incluido
        </Checkbox>
      </div>

      <div className="checkbox">
        <Checkbox
          value={innerState.excluded}
          onClick={() =>
            setInnerState({
              included: innerState.included,
              excluded: !innerState.excluded,
            })
          }
        >
          No incluido
        </Checkbox>
      </div>
      <div className="buttonWrapp">
        <Button type="default" className="button-reset" onClick={reset}>
          Reset
        </Button>
        <Button
          type="primary"
          className="button-ok"
          onClick={() => setState(innerState)}
        >
          Ok
        </Button>
      </div>
    </div>
  );
};

export default OptionsFilter;
