import React, { useContext } from "react";
import { Checkbox, Row, Col } from "antd";
import { I18nContext } from "react-i18next";
import "./CheckboxFilter.scss";
import { QueryFormatter } from "../../ModerationTable/type";

type StatusOption = QueryFormatter["status"];

type CheckboxFilterProps = {
  state: StatusOption;
  setState: (value: StatusOption) => void;
};

const CheckboxFilter = ({
  state,
  setState,
}: CheckboxFilterProps): JSX.Element => {
  const { i18n } = useContext(I18nContext);
  function onChange(label: string) {
    setState(
      state.map((option) => {
        if (label === option.label) {
          return {
            ...option,
            checked: !option.checked,
          };
        }
        return option;
      })
    );
  }

  return (
    <Row className="checkbox-filter-row">
      {state.map(({ label, checked }) => {
        return (
          <Col key={label}>
            <Checkbox checked={checked} onChange={() => onChange(label)}>
              {i18n.t(`pages.moderation.${label}`)}
            </Checkbox>
          </Col>
        );
      })}
    </Row>
  );
};

export default CheckboxFilter;
