import React, { useEffect, useState } from "react";
import "./segmentation.scss";

// ant
import { Alert, Button, Card, Checkbox, Dropdown, Menu, Row } from "antd";

// service
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { DownOutlined } from "@ant-design/icons";
import {
  getAgreements,
  getAllAgreements,
} from "../../../services/Agreements/Agreements";

// types
import { Ad } from "../../../services/Ad/type";
import SegmentationTable from "./SegmentationTable/SegmentationTable";

import { StreamerAgreement } from "../../../services/Agreements/types";
import i18n from "../../../i18n";
import { Countries } from "../../../services/Country/types";

// Props
interface Props {
  adId: number;
  ad: Ad;
  setAd: (ad: Ad) => void;
  setEditAd: (show: boolean) => void;
  agreementList: StreamerAgreement[];
  setAgreementList: (v: StreamerAgreement[]) => void;
  countriesList: Countries[];
  editAgreement: boolean;
  setEditAgreement: (v: boolean) => void;
  modeAdType: string;
  setSelectMode: (step: number) => void;
}

const Segmentation = ({
  adId,
  ad,
  setAd,
  setEditAd,
  agreementList,
  setAgreementList,
  countriesList,
  editAgreement,
  setEditAgreement,
  modeAdType,
  setSelectMode,
}: Props) => {
  const baseI18N = "pages.channels.table.filters";
  const [allSelected, setAllSelected] = useState(false);
  const [rawStreamerList, setRawStreamerList] = useState(agreementList);
  const [displayNameFilter, setdisplayNameFilter] = useState<string[]>([]);
  const [countriesFilter, setCountriesFilter] = useState<string[]>([]);
  const [twitchIdFilter, settwitchIdFilter] = useState<string[]>([]);
  const [status, setStatus] = useState({
    included: false,
    excluded: false,
  });
  const [viewersFilter, setViewersFilter] = useState({
    from: -1,
    to: -1,
  });

  useEffect(() => {
    if (agreementList.length === 0 && modeAdType === "editAd") {
      getAgreements(adId).then((res) => {
        setAgreementList(res);
        setRawStreamerList(res);
      });
    }
  }, [adId, agreementList.length, setAgreementList, modeAdType]);

  const handelChangeAdultContent = (e: CheckboxChangeEvent) => {
    const check = e.target.checked;
    const newAd: Ad = { ...ad, isAdultContent: check };
    setAd(newAd);
    setEditAd(true);
  };

  const handleTwitch = (e: CheckboxChangeEvent) => {
    const check = e.target.checked;
    const newAd: Ad = { ...ad, twitch: check };
    setAd(newAd);
    setEditAd(true);
  };

  const handleYoutube = (e: CheckboxChangeEvent) => {
    const check = e.target.checked;
    const newAd: Ad = {
      ...ad,
      youtube: check,
      isAdultContent: !check,
      isAgreementRequired: !check,
    };
    setAd(newAd);
    setEditAd(true);
  };

  const handelChangeAgreement = async (e: CheckboxChangeEvent) => {
    const check = e.target.checked;
    const newAd: Ad = { ...ad, isAgreementRequired: check };
    if (check && modeAdType === "createAd") {
      const res = await getAllAgreements();
      setAgreementList(res);
      setRawStreamerList(res);
    }
    setAd(newAd);
    setEditAd(true);
  };

  const resetFilters = () => {
    setdisplayNameFilter([]);
    settwitchIdFilter([]);
    setStatus({ included: false, excluded: false });
    setViewersFilter({ from: -1, to: -1 });
    setCountriesFilter([]);
    setAgreementList(
      agreementList.map((el) => {
        return { ...el, isChecked: false };
      })
    );
  };

  const isAnyStreamerSelected = () => {
    return rawStreamerList.some((el) => el.isChecked);
  };

  const includeOrExclude = (action: boolean) => {
    const updatedAgreement = rawStreamerList.map((el) => {
      if (el.isChecked) {
        const auxElement = el;
        auxElement.hasAgreement = action;
        auxElement.isChecked = false;
        return auxElement;
      }
      return el;
    });
    setEditAgreement(true);
    setRawStreamerList(updatedAgreement);
    setAgreementList(updatedAgreement);
    setAllSelected(false);
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={() => includeOrExclude(true)}>Incluir</Menu.Item>
      <Menu.Item onClick={() => includeOrExclude(false)}>No Incluir</Menu.Item>
    </Menu>
  );

  const messageAlert = () => {
    return (
      <p
        className="text-alert-message"
        dangerouslySetInnerHTML={{
          __html: i18n.t("pages.editAd.segmentation.platform.message"),
        }}
      />
    );
  };

  return (
    <div className="container-segmentation">
      <Card>
        <div className="content">
          <div className="content-column">
            <div className="content-row content-title">
              <p className="title">
                {i18n.t(`pages.editAd.segmentation.platform.title`)}
              </p>
              <p>{i18n.t(`pages.editAd.segmentation.platform.subtitle`)}</p>
            </div>

            <div className="inner-content">
              <Alert message={messageAlert()} type="info" banner />
              <div className="checkbox-wrapper">
                <div>
                  <Checkbox onChange={handleTwitch} defaultChecked={ad.twitch}>
                    {i18n.t(`pages.editAd.segmentation.platform.twitch`)}
                  </Checkbox>
                </div>
                <div>
                  <Checkbox
                    onChange={handleYoutube}
                    defaultChecked={ad.youtube}
                  >
                    {i18n.t(`pages.editAd.segmentation.platform.youtube`)}
                  </Checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
      <Card className={`${ad.youtube ? "disabled-card" : ""}`}>
        <div className="content">
          <Checkbox
            onChange={handelChangeAdultContent}
            checked={ad.isAdultContent}
            disabled={ad.youtube}
          />
          <p className={`title ${ad.youtube ? "disabled" : ""}`}>
            {i18n.t(`pages.editAd.segmentation.age.title`)}
          </p>
          <p className={`${ad.youtube ? "disabled" : ""}`}>
            {i18n.t(`pages.editAd.segmentation.age.firstMessage`)}{" "}
            <strong>
              {i18n.t(`pages.editAd.segmentation.age.secondMessage`)}
            </strong>
            ?
          </p>
        </div>
      </Card>
      <Card className={`${ad.youtube ? "disabled-card" : ""}`}>
        <div className="content">
          <Checkbox
            onChange={handelChangeAgreement}
            checked={ad.isAgreementRequired}
            disabled={ad.youtube}
          />
          <p className={`title ${ad.youtube ? "disabled" : ""}`}>
            {i18n.t(`pages.editAd.segmentation.agreement.title`)}
          </p>
        </div>
        <div className={`streamersTable ${ad.youtube ? "hide-button" : ""}`}>
          <div style={{ marginTop: "50px" }} />
          <div className="filterWrapper">
            <div className="actionsButtons">
              <Button type="primary" icon={<span />} onClick={resetFilters}>
                {i18n.t(`pages.channels.buttons.clearFilter`)}
              </Button>
              {isAnyStreamerSelected() ? (
                <Dropdown overlay={menu} placement="bottomCenter">
                  <Button type="primary">
                    {i18n.t(`pages.editAd.segmentation.agreement.updateButton`)}{" "}
                    <DownOutlined />
                  </Button>
                </Dropdown>
              ) : (
                ""
              )}
            </div>
          </div>
          {ad.isAgreementRequired ? (
            <SegmentationTable
              rawStreamers={rawStreamerList}
              setRawStreamerList={setRawStreamerList}
              pagination={{ defaultPageSize: 10 }}
              countries={countriesList}
              displayNameFilter={displayNameFilter}
              setdisplayNameFilter={setdisplayNameFilter}
              twitchIdFilter={twitchIdFilter}
              settwitchIdFilter={settwitchIdFilter}
              status={status}
              setStatus={setStatus}
              viewersFilter={viewersFilter}
              setViewersFilter={setViewersFilter}
              countriesFilter={countriesFilter}
              setCountriesFilter={setCountriesFilter}
              allSelected={allSelected}
              setAllSelected={setAllSelected}
            />
          ) : (
            ""
          )}
        </div>
      </Card>
      {modeAdType === "createAd" ? (
        <Row className="positon-btn-segmentation">
          <Button onClick={() => setSelectMode(1)}>
            {i18n.t("pages.createAd.btns.back")}
          </Button>
          <Button onClick={() => setSelectMode(3)} type="primary">
            {i18n.t("pages.createAd.btns.next")}
          </Button>
        </Row>
      ) : null}
    </div>
  );
};

export default Segmentation;
