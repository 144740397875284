import React, { FunctionComponent, useState, useEffect } from "react";
import { useMappedState } from "redux-react-hook";
import { tokenAuth } from "../../services/Login";

type AuthContainerProps = {
  authenticator: JSX.Element;
  children: JSX.Element;
};

type Auth = {
  auth: { authenticated: boolean };
};

const AuthContainer: FunctionComponent<AuthContainerProps> = ({
  authenticator,
  children,
}): JSX.Element => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    tokenAuth().finally(() => setLoading(false));
  }, []);

  const mapState = (state: Auth): Auth => ({
    auth: {
      authenticated: state.auth.authenticated,
    },
  });

  const { auth } = useMappedState(mapState);

  if (loading) return <></>;

  return <>{auth.authenticated ? children : authenticator}</>;
};

export default AuthContainer;
