import React from "react";
import moment from "moment";
import i18next from "i18next";
import { Popover, Switch } from "antd";
import { TableColumnFormat } from "../../commonTypes";
import { ActiveCampaign, Ad } from "../../services/Campaign/type";

const datesRenderHandler = (value: string) =>
  value ? moment(value).format("DD MMM [at] HH:mm a") : "";

const numberThousandsSeparator = (n: number | string) => {
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

const getActiveDisableCount = (ads: Ad[]) => {
  let disabled = 0;
  let active = 0;
  ads.forEach((ad) => {
    if (ad.adActive === false) {
      disabled += 1;
    } else {
      active += 1;
    }
  });

  return { active, disabled };
};

const content = (
  <div>
    <p>Esta campaña tiene alguno de sus anuncios desactivados.</p>
  </div>
);
interface PropsColumn {
  statusCampaingChange: (e: boolean, campaing: ActiveCampaign) => void;
}

const isFutureCampaign = (campaing: ActiveCampaign) => {
  return new Date(campaing.startDate) > new Date();
};

const isBothActivatedAndDeactivatedAds = (campaign: ActiveCampaign) => {
  return (
    getActiveDisableCount(campaign.ads).active &&
    getActiveDisableCount(campaign.ads).disabled
  );
};

const getClassNameForSwitches = (campaign: ActiveCampaign) => {
  if (isFutureCampaign(campaign)) {
    if (isBothActivatedAndDeactivatedAds(campaign))
      return "futureWarningButton";

    if (getActiveDisableCount(campaign.ads).active > 0)
      return "futureCampaingOn";
    return "futureCampaingOff";
  }
  return getActiveDisableCount(campaign.ads).active &&
    getActiveDisableCount(campaign.ads).disabled
    ? "warningButton"
    : "";
};

const getColumns = ({
  statusCampaingChange,
}: PropsColumn): TableColumnFormat => [
  {
    title: i18next.t(`pages.activeCampaigns.headers.name`),
    dataIndex: "campaignName",
    align: "left",
  },
  {
    title: i18next.t(`pages.activeCampaigns.headers.totalViews`),
    dataIndex: "goalViews",
    render: numberThousandsSeparator,
  },
  {
    title: i18next.t(`pages.activeCampaigns.headers.remainingViews`),
    dataIndex: "remainingViews",
    render: numberThousandsSeparator,
  },
  {
    title: i18next.t(`pages.activeCampaigns.headers.country`),
    dataIndex: "country",
    render: (value: string) =>
      value ? i18next.t(`pages.channels.countries.${value}`) : "",
  },
  {
    title: i18next.t(`pages.activeCampaigns.headers.startDate`),
    dataIndex: "startDate",
    render: datesRenderHandler,
  },
  {
    title: i18next.t(`pages.activeCampaigns.headers.endDate`),
    dataIndex: "endDate",
    render: datesRenderHandler,
  },
  {
    title: "Status",
    dataIndex: "adActive",
    render: (value, campaing: ActiveCampaign) => (
      <span className={getClassNameForSwitches(campaing)}>
        {getActiveDisableCount(campaing.ads).active > 0 &&
        getActiveDisableCount(campaing.ads).disabled > 0 ? (
          <Popover
            content={content}
            title="Atención"
            placement="topRight"
            popupVisible={
              getActiveDisableCount(campaing.ads).active > 0 &&
              getActiveDisableCount(campaing.ads).disabled > 0
            }
          >
            <Switch
              checked={getActiveDisableCount(campaing.ads).active > 0}
              onChange={(e) => statusCampaingChange(e, campaing)}
            />
          </Popover>
        ) : (
          <Switch
            checked={getActiveDisableCount(campaing.ads).active > 0}
            onChange={(e) => statusCampaingChange(e, campaing)}
          />
        )}
      </span>
    ),
  },
];

export default getColumns;
