import axios, { AxiosResponse } from "axios";
import { Streamer } from "../../components/EditAd/Segmentation/SegmentationTable/types";
import createGetFn from "../get";
import createPutFn from "../put";
import { getTokenFromStorage } from "../_auth";
import { StreamerAgreement } from "./types";

export const baseUrl = `${window._env_.SERVER_URL}/admin/agreement`;
export const baseUrlGetAgreements = `${window._env_.SERVER_URL}/admin/agreement/streamers/newagreement`;

const calculateSumViewers = (v: { viewerCount: number }[]) => {
  return v.reduce((a, b) => {
    return a + b.viewerCount;
  }, 0);
};

const calculateAvgViewers = (ammount: number, total: number) => {
  return total !== 0 ? Math.round(ammount / total) : 0;
};

const get = createGetFn(baseUrl, {
  normalize: (res: AxiosResponse): StreamerAgreement[] => {
    const { data } = res;
    const withAgreement = data.StreamersWithAgreement.map(
      (streamerRes: Streamer) => {
        const streamer: StreamerAgreement = {
          id: streamerRes.id,
          hasAgreement: true,
          isChecked: false,
          avgViewers: calculateAvgViewers(
            calculateSumViewers(streamerRes.ad_metrics),
            streamerRes.ad_metrics.length
          ),

          twitchId: streamerRes.twitchUser.id,
          displayName: streamerRes.twitchUser.displayName,
          countryId: streamerRes.country.id,
          countryCode: streamerRes.country.code,
        };
        return streamer;
      }
    );
    const withoutAgreement = data.StreamersWithoutAgreement.map(
      (streamerRes: Streamer) => {
        const streamer: StreamerAgreement = {
          id: streamerRes.id,
          hasAgreement: false,
          isChecked: false,
          avgViewers: calculateAvgViewers(
            calculateSumViewers(streamerRes.ad_metrics),
            streamerRes.ad_metrics.length
          ),
          twitchId: streamerRes.twitchUser.id,
          displayName: streamerRes.twitchUser.displayName,
          countryId: streamerRes.country.id,
          countryCode: streamerRes.country.code,
        };
        return streamer;
      }
    );
    const streamerData: StreamerAgreement[] = withAgreement.concat(
      withoutAgreement
    );
    return streamerData;
  },
});

export const getAgreementsAll = () =>
  axios.get(baseUrlGetAgreements, {
    headers: {
      Authorization: getTokenFromStorage(),
    },
  });

export const getAgreements = (adId: number): Promise<StreamerAgreement[]> =>
  get(`/${adId}`);

const put = createPutFn(baseUrl, {
  normalize: (res: AxiosResponse) => res,
});

export const getAllAgreements = async () => {
  try {
    const res = await getAgreementsAll();
    const { data } = res;
    return data.StreamersWithoutAgreement.map((streamerRes: Streamer) => {
      const streamer: StreamerAgreement = {
        id: streamerRes.id,
        hasAgreement: false,
        isChecked: false,
        avgViewers: calculateAvgViewers(
          calculateSumViewers(streamerRes.ad_metrics),
          streamerRes.ad_metrics.length
        ),
        twitchId: streamerRes.twitchUser.id,
        displayName: streamerRes.twitchUser.displayName,
        countryId: streamerRes.country.id,
        countryCode: streamerRes.country.code,
      };
      return streamer;
    });
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const putAgreements = (
  adId: number,
  streamerIds: string[]
): Promise<AxiosResponse> => {
  return put(``, {
    adId,
    streamerIds,
  });
};
