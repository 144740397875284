export interface Ad {
  id: number;
  chatMessage: string;
  defaultMessage: string;
  name: string;
  publicName: string;
  isAgreementRequired: boolean;
  isAdultContent: boolean;
  overlayUrl: string;
  videoUrl: string;
  endDate: string;
  startDate: string;
  cpm: number;
  budget: number;
  active: boolean;
  youtube: boolean;
  twitch: boolean;
  countryId: string;
  campaignId: number;
}

export interface CountryData {
  id: string;
  code: string;
}

export interface CampaignData {
  id: number;
  advertiserId: string;
  title: string;
}

export interface Advertiser {
  id: string;
  name: string;
}

export interface AdLandingData {
  chatMessageTag: string;
  url: string;
  newUrl: string;
  adId: number;
  brandedUrl: null;
}

export interface DataAd {
  ad: Ad;
  campaign: CampaignData;
  advertiser: Advertiser;
  adLanding: AdLandingData;
  country: CountryData;
}

export const formatDate = "YYYY-MM-DD";
export const formatTime = "HH:mm:ss";

export interface Adcreate {
  chatMessage: string;
  defaultMessage: string;
  name: string;
  publicName: string;
  isAgreementRequired: boolean;
  isAdultContent: boolean;
  overlayUrl: string;
  videoUrl: string;
  endDate: string;
  startDate: string;
  cpm: number;
  budget: number;
  active: boolean;
  countryId: string;
  campaignId: number;
}
