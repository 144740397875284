import React from "react";
import i18next from "i18next";
import { PoweroffOutlined, SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import { TableColumnFormat } from "../../commonTypes";
import {
  Channel,
  Country,
  Group,
} from "../../services/ChannelsManagement/types";
import UpdateStatus from "./UpdateStatus";
import { UpdateStatusData } from "./types";
import DropDownSelector from "./DropDownSelector";

type getColumnsArguments = {
  onUpdateStatus: (v: UpdateStatusData) => Promise<void>;
  updateCountryAndMonetizationData: (
    value: string,
    keyProp: string,
    channel: Channel
  ) => Promise<void>;
  filters: {
    DateFilter: JSX.Element;
    StreamerIdFilter: JSX.Element;
    DisplayNameFilter: JSX.Element;
    AffiliateFilter: JSX.Element;
    EmailFilter: JSX.Element;
    CountryFilter: JSX.Element;
    MonetizationFilter: JSX.Element;
    MonetizationGroupFilter: JSX.Element;
  };
};

const baseI18N = "pages.channels.table.headers";

const getGroups = (countries: Country[], countryId: string) => {
  const groupSelected = countries.filter((element) =>
    element.id === countryId ? element.groups : null
  );
  return groupSelected.length ? groupSelected[0].groups : [];
};

const getRanges = (groups: Group[], groupId: string) => {
  const rangeSelected = groups.filter((element) =>
    element.id === groupId ? element.range : null
  );
  return rangeSelected.length ? rangeSelected[0].range : [];
};

const getColumns = (
  countries: Country[],
  onUpdateStatus: getColumnsArguments["onUpdateStatus"],
  updateCountryAndMonetizationData: getColumnsArguments["updateCountryAndMonetizationData"],
  {
    DateFilter,
    StreamerIdFilter,
    DisplayNameFilter,
    AffiliateFilter,
    EmailFilter,
    CountryFilter,
    MonetizationFilter,
    MonetizationGroupFilter,
  }: getColumnsArguments["filters"]
): TableColumnFormat => {
  return [
    {
      title: i18next.t(`${baseI18N}.id`),
      dataIndex: "twitchId",
      align: "center",
      filterIcon: (filtered) => <SearchOutlined />,
      filterDropdown: () => StreamerIdFilter,
    },
    {
      title: i18next.t(`${baseI18N}.name`),
      dataIndex: "displayName",
      align: "center",
      render: (value: string) => value || "-",
      filterIcon: (filtered) => <SearchOutlined />,
      filterDropdown: () => DisplayNameFilter,
    },
    {
      title: i18next.t(`${baseI18N}.email`),
      dataIndex: "email",
      align: "center",
      filterIcon: (filtered) => <SearchOutlined />,
      filterDropdown: () => EmailFilter,
    },
    {
      title: i18next.t(`${baseI18N}.registrationDate`),
      dataIndex: "createdAt",
      align: "center",
      render: (value: string) =>
        value ? moment(value).format("DD/MM/YYYY") : "",
      filterDropdown: () => DateFilter,
    },
    {
      title: i18next.t(`${baseI18N}.country`),
      dataIndex: "country",
      filterDropdown: () => CountryFilter,
      align: "center",
      render: (value: boolean, channel: Channel) => (
        <DropDownSelector
          key={channel.twitchId}
          selectedItem={channel.countryId}
          updateData={updateCountryAndMonetizationData}
          elements={countries}
          keyProp="countryId"
          channel={channel}
        />
      ),
    },
    {
      title: i18next.t(`${baseI18N}.affiliate`),
      dataIndex: "affiliateEmail",
      align: "center",
      filterIcon: (filtered) => <SearchOutlined />,
      filterDropdown: () => AffiliateFilter,
      render: (value: string) => value || "-",
    },
    {
      title: i18next.t(`${baseI18N}.monetizationGroup`),
      dataIndex: "monetizationGroupId",
      align: "center",
      filterDropdown: () => MonetizationGroupFilter,
      render: (value: boolean, channel: Channel) => (
        <DropDownSelector
          key={channel.twitchId}
          selectedItem={channel.monetizationGroupId}
          updateData={updateCountryAndMonetizationData}
          elements={getGroups(countries, channel.countryId)}
          keyProp="monetizationGroupId"
          channel={channel}
          disabled={!channel.countryId}
        />
      ),
    },
    {
      title: i18next.t(`${baseI18N}.monetizationRange`),
      dataIndex: "monetizationRangeId",
      filterDropdown: () => MonetizationFilter,
      align: "center",
      render: (value: boolean, channel: Channel) => (
        <DropDownSelector
          key={channel.twitchId}
          selectedItem={channel.monetizationRangeId}
          updateData={updateCountryAndMonetizationData}
          elements={getRanges(
            getGroups(countries, channel.countryId),
            channel.monetizationGroupId
          )}
          keyProp="monetizationRangeId"
          disabled={!channel.countryId || !channel.monetizationGroupId}
          channel={channel}
        />
      ),
    },
    {
      title: (
        <PoweroffOutlined
          style={{ fontSize: "1.5em", verticalAlign: "middle" }}
        />
      ),
      dataIndex: "createAutomaticTip",
      align: "center",
      width: 70,
      render: (value: boolean, channel: Channel) => (
        <UpdateStatus
          key={channel.twitchId}
          data={channel}
          updateStatus={onUpdateStatus}
          disabled={
            !channel.countryId ||
            !channel.monetizationGroupId ||
            !channel.monetizationRangeId
          }
        />
      ),
    },
  ];
};

export default getColumns;
