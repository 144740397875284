import axios from "axios";
import * as R from "ramda";

import { getTokenFromStorage } from "./_auth";

const deleteData = R.curry((baseUrl, options, url) =>
  axios
    .delete(`${baseUrl}${url}`, {
      headers: {
        Authorization: getTokenFromStorage(),
      },
    })
    .then(options.normalize)
);

/**
 *
 * @param {String} baseUrl
 * @param {Object} options
 * @param {Function} options.normalize
 * @param {Boolean} options.auth DISABLED
 */
export default (baseUrl: string, options: { [key: string]: any }): any =>
  deleteData(baseUrl, options); // TODO: TYPE RETURN VALUE
