import React, { useState, FunctionComponent, useCallback } from "react";
import { useMappedState } from "redux-react-hook";

import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import "./Layout.scss";

import LayoutAd from "../LayoutAd/LayoutAd";

const Layout: FunctionComponent = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleToggleCollapsed = (): void => {
    setIsCollapsed(!isCollapsed);
  };

  const mapState = useCallback(
    (state: any) => ({
      editAd: state.ad.editAd.editAd,
      createAd: state.ad.createAd.createAd,
    }),
    []
  );
  const { editAd, createAd } = useMappedState(mapState);

  return (
    <>
      {editAd || createAd ? (
        <LayoutAd editAd={editAd} createAd={createAd} />
      ) : (
        <div className="dashboard-layout">
          <Sidebar isCollapsed={isCollapsed} />
          <div className="dashboard-layout__top-navigation">
            <Navbar
              handleToggleCollapsed={handleToggleCollapsed}
              isCollapsed={isCollapsed}
            />
            <div className="dashboard-layout__main">{children}</div>
            <Footer />
          </div>
        </div>
      )}
    </>
  );
};

export default Layout;
