import React, { useState } from "react";
import { Form, Tabs, Input, Row, Button, Spin } from "antd";
import { Store } from "rc-field-form/lib/interface";
import {
  EyeInvisibleOutlined,
  LockOutlined,
  EyeTwoTone,
  UserOutlined,
} from "@ant-design/icons";
import { Helmet } from "react-helmet";
import { credentialsAuth } from "../../services/Login";
import logo from "../../assets/images/streamion-gradient-logo.png";
import "./login.scss";

const { TabPane } = Tabs;

const Login = (): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [form] = Form.useForm();

  const onFinish = async (values: Store) => {
    setLoading(true);

    const loginCredentials = {
      email: values.email || "",
      password: values.password || "",
    };

    await credentialsAuth(loginCredentials, setErrorMsg);
    setLoading(false);
  };

  return (
    <div id="login-page">
      <Helmet>
        <title>Streamion | Login</title>
      </Helmet>
      <div className="container">
        <Row align="middle" justify="center" gutter={[8, 48]}>
          <img src={logo} alt="Streamion" width="200px" />
        </Row>
        <Tabs defaultActiveKey="1" style={{ width: "300px" }}>
          <TabPane tab="LOGIN" key="1">
            <Form form={form} onFinish={onFinish}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Correo electrónico"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder="Contraseña"
                  iconRender={(visible: boolean): JSX.Element =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
              <Form.Item validateStatus={errorMsg && "error"} help={errorMsg}>
                {loading ? (
                  <div className="container-login">
                    <Spin size="large" />
                  </div>
                ) : (
                  <Button type="primary" htmlType="submit" block>
                    LOGIN
                  </Button>
                )}
              </Form.Item>
            </Form>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default Login;
