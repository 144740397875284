import React from "react";
import ReactDOM from "react-dom";
import "./assets/scss/main.scss";
import App from "./App";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

declare global {
  interface Window {
    _env_: { [key: string]: any };
  }
}
