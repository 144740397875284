import { TablePagination } from "../../commonTypes";

export interface ModerationRow {
  emissionProofsId: number;
  proofUrl: string;
  twitchDisplayName: string;
  status: number;
  viewerCount: number;
  date: string;
  adMetricId: number;
  zipUrl: string;
  campaignId: number;
  adName: ModerationRow;
}

export interface ModerationTable extends TablePagination {
  data: {
    total: number;
    rows: ModerationRow[];
  };
}
export type RequestStatus = 1 | 2 | 3 | 4;

export enum UpdateStatusActions {
  "APPROVED" = 1,
  "MISSING" = 2,
  "WRONG" = 3,
  "HIDDEN" = 4,
}
