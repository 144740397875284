import React, { useState, useEffect, useContext } from "react";
import "./sidebarAd.scss";
import { I18nContext } from "react-i18next";

interface Props {
  setSelectMode: (step: number) => void;
  selectMode: string;
  modeAdType: string;
}

interface Steps {
  [key: string]: string;
}

const desactive: Steps = {
  step1: "desactive",
  step2: "desactive",
  step3: "desactive",
  step4: "desactive",
};

const SidebarAd = ({ setSelectMode, selectMode, modeAdType }: Props) => {
  const { i18n } = useContext(I18nContext);
  const [active, setActive] = useState<Steps>({
    step1: "active",
    step2: "desactive",
    step3: "desactive",
    step4: "desactive",
  });

  useEffect(() => {
    const toActive: Steps = { ...desactive };
    toActive[selectMode] = "active";
    setActive(toActive);
  }, [selectMode]);

  const handelClick = (step: number) => {
    if (modeAdType === "editAd") {
      const toActive: Steps = { ...desactive };
      toActive[`step${step}`] = "active";
      setActive(toActive);
      setSelectMode(step);
    }
  };

  return (
    <>
      <button
        type="button"
        onClick={() => handelClick(1)}
        className={`steps ${active.step1}`}
      >
        <p>{i18n.t("components.sidebarAd.step1.title")}</p>
        <ul>
          <li>{i18n.t("components.sidebarAd.step1.desc1")}</li>
          <li>{i18n.t("components.sidebarAd.step1.desc2")}</li>
        </ul>
      </button>
      <button
        type="button"
        onClick={() => handelClick(2)}
        className={`steps ${active.step2}`}
      >
        <p>{i18n.t("components.sidebarAd.step2.title")}</p>
        <ul>
          <li>{i18n.t("components.sidebarAd.step1.desc1")}</li>
          <li>{i18n.t("components.sidebarAd.step1.desc2")}s</li>
        </ul>
      </button>
      <button
        type="button"
        onClick={() => handelClick(3)}
        className={`steps ${active.step3}`}
      >
        <p>{i18n.t("components.sidebarAd.step3.title")}</p>
        <ul>
          <li>{i18n.t("components.sidebarAd.step3.desc1")}</li>
          <li>{i18n.t("components.sidebarAd.step3.desc2")}</li>
        </ul>
      </button>
      <button
        type="button"
        onClick={() => handelClick(4)}
        className={`steps ${active.step4}`}
      >
        <p>{i18n.t("components.sidebarAd.step4.title")}</p>
      </button>
    </>
  );
};

export default SidebarAd;
