import React from "react";
import { DatePicker } from "antd";
import { getI18n } from "react-i18next";
import moment from "moment";
import { DatesState } from "../../../commonTypes";

type DateFilterProps = {
  format?: string;
  state: DatesState;
  setState: (dates: DatesState) => void;
};

export const DateFilter = ({
  format = "DD-MM-YYYY",
  state,
  setState = () => undefined,
}: DateFilterProps): JSX.Element => {
  const { language } = getI18n();

  return (
    <DatePicker.RangePicker
      value={state}
      format={[format, format]}
      onCalendarChange={(dates) => {
        if (dates) {
          setState([moment(dates[0]), moment(dates[1])]);
        } else {
          setState(null);
        }
      }}
      allowEmpty={[false, false]}
    />
  );
};

DateFilter.defaultProps = {
  format: "DD-MM-YYYY",
};
