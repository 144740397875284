import React from "react";
import moment from "moment";
import { Popover } from "antd";
import { StatusData, UpdateStatusData } from "./type";
import StatusTag from "../StatusTag";
import UpdateStatus from "./UpdateStatus";
import { TableColumnFormat } from "../../commonTypes";
import { ModerationRow } from "../../services/Moderation/type";

type getColumnsArguments = {
  onUpdateStatus: (v: UpdateStatusData) => void;
  filters: {
    DateFilter: JSX.Element;
    StreamerFilter: JSX.Element;
    AdFilter: JSX.Element;
    StatusFilter: JSX.Element;
    CampaignFilter: JSX.Element;
  };
};

const getColumns = (
  onUpdateStatus: getColumnsArguments["onUpdateStatus"],
  {
    DateFilter,
    StreamerFilter,
    AdFilter,
    StatusFilter,
    CampaignFilter,
  }: getColumnsArguments["filters"]
): TableColumnFormat => [
  {
    title: "Fecha de creación",
    dataIndex: "date",
    render: (value: string) =>
      value ? moment(value).format("DD/MM/YYYY HH:mm:ss") : "",
    filterDropdown: () => DateFilter,
  },
  {
    title: "Metric Id",
    dataIndex: "adMetricId",
    filterDropdown: () => AdFilter,
  },
  {
    title: "Campaign ID",
    dataIndex: "campaignId",
    render: (value: string, row: ModerationRow) => (
      <Popover content={<div>{row.adName}</div>}>
        <span className="highlight-campaign">{value}</span>
      </Popover>
    ),
    filterDropdown: () => CampaignFilter,
  },
  {
    title: "Twitch User Name",
    dataIndex: "twitchDisplayName",
    filterDropdown: () => StreamerFilter,
  },
  {
    title: "Views",
    dataIndex: "viewerCount",
  },
  {
    title: "Proof Preview",
    dataIndex: "proofUrl",
    render: (url: string) => (
      <Popover content={<img src={url} alt="Proof Preview" />}>
        <a href={url} target="_blank" rel="noreferrer">
          Preview
        </a>
      </Popover>
    ),
  },
  {
    title: "Proof Zip",
    dataIndex: "zipUrl",
    render: (url: string) => (
      <a href={url} target="_blank" rel="noreferrer">
        Download
      </a>
    ),
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (value: StatusData["status"]) => {
      return <StatusTag status={value} />;
    },
    filterDropdown: () => StatusFilter,
  },
  {
    title: "Update Status",
    render: (entry: StatusData) => (
      <UpdateStatus data={entry} updateStatus={onUpdateStatus} />
    ),
  },
];

export default getColumns;
