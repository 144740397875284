import React, { useContext, useState, useCallback, useEffect } from "react";
import { useDispatch } from "redux-react-hook";
import { Modal, Button, message, Row } from "antd";
import { I18nContext } from "react-i18next";
import { adPathCreate } from "../../store/ad/actions";
import "./createAd.scss";

// Component
import SidebarAd from "../../components/SidebarAd/SidebarAd";
import ConfigAd from "../../components/EditAd/ConfigAd/ConfigAd";
import ContentAd from "../../components/EditAd/ContentAd/ContentAd";
import Revision from "../../components/EditAd/Revision/Revision";
import Segmentation from "../../components/EditAd/Segmentation/Segmentantion";

// initial state
import {
  adInitialState,
  adLandingInitialState,
  advertiserInitialState,
  campaignInitialState,
  countryInitialState,
} from "../EditAd/initalState";

// Types
import {
  Ad,
  CampaignData,
  AdLandingData,
  Advertiser,
  CountryData,
} from "../../services/Ad/type";
import { StreamerAgreement } from "../../services/Agreements/types";
import { Countries } from "../../services/Country/types";
import { getCountry } from "../../services/Country/Country";

interface Props {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}

interface ModeSteps {
  [key: string]: JSX.Element;
}

const MAX_STEPS = 4;
const MIN_STEPS = 1;

const CreateAd = ({ showModal, setShowModal }: Props) => {
  const { i18n } = useContext(I18nContext);
  const dispatch = useDispatch();
  const handelUnSave = () => {
    setShowModal(false);
    dispatch(adPathCreate({ createAd: false }));
  };

  // data ad
  const [ad, setAd] = useState<Ad>(adInitialState);
  const [campaign, setCampaign] = useState<CampaignData>(campaignInitialState);
  const [advertiser, setAdvertiser] = useState<Advertiser>(
    advertiserInitialState
  );
  const [adLanding, setAdLanding] = useState<AdLandingData>(
    adLandingInitialState
  );
  const [country, setCountry] = useState<CountryData>(countryInitialState);
  const [countriesList, setCountriesList] = useState<Array<Countries>>([]);

  // interaction
  const [selectMode, setSelectMode] = useState(MIN_STEPS);
  const [loading, setLoading] = useState(false);

  // to send data to edited
  const [editAd, setEditAd] = useState(false);
  const [editCampaign, setEditCampaign] = useState(false);
  const [editLanding, setEditLanding] = useState(false);
  const [editAgreement, setEditAgreement] = useState(false);
  const [agreementList, setAgreementList] = useState<StreamerAgreement[]>([]);

  // image and video
  const [image, setImg] = useState<any>("");
  const [video, setVideo] = useState<any>("");

  const handelSave = () => {
    setSelectMode(4);
    setShowModal(false);
  };

  const oredreredCountries = (countryList: CountryData[]) => {
    return countryList
      ?.map((c) => ({
        id: c.id,
        code: i18n.t(`pages.channels.countries.${c.code}`),
      }))
      .sort((a, b) => a.code.localeCompare(b.code));
  };

  const getAllData = async () => {
    try {
      setLoading(true);
      const dataCountry = await getCountry();
      setCountriesList(dataCountry);
    } catch (err) {
      console.log(err);
      message.error({
        content: i18n.t("pages.editAd.message.errorData"),
        className: "message",
        duration: 5,
      });
    } finally {
      setLoading(false);
    }
  };

  const get = useCallback(getAllData, [setCountriesList, i18n]);

  useEffect(() => {
    get();
  }, [get]);
  const modeSteps: ModeSteps = {
    step1: (
      <ConfigAd
        setEditAd={setEditAd}
        setEditCampaign={setEditCampaign}
        ad={ad}
        setAd={setAd}
        campaign={campaign}
        setCampaign={setCampaign}
        advertiser={advertiser}
        setAdvertiser={setAdvertiser}
        country={country}
        countriesList={oredreredCountries(countriesList)}
        setCountry={setCountry}
        modeAdType="createAd"
        setSelectMode={setSelectMode}
      />
    ),
    step2: (
      <Segmentation
        adId={0}
        ad={ad}
        setAd={setAd}
        setEditAd={setEditAd}
        agreementList={agreementList}
        setAgreementList={setAgreementList}
        countriesList={countriesList}
        editAgreement={editAgreement}
        setEditAgreement={setEditAgreement}
        modeAdType="createAd"
        setSelectMode={setSelectMode}
      />
    ),
    step3: (
      <ContentAd
        setEditAd={setEditAd}
        setEditLanding={setEditLanding}
        editLanding={editLanding}
        ad={ad}
        setAd={setAd}
        adLanding={adLanding}
        setAdLanding={setAdLanding}
        modeAdType="createAd"
        setSelectMode={setSelectMode}
        setVideo={setVideo}
        setImg={setImg}
      />
    ),
    step4: (
      <Revision
        editAd={editAd}
        editCampaign={editCampaign}
        editLanding={editLanding}
        setEditAd={setEditAd}
        setEditCampaign={setEditCampaign}
        setEditLanding={setEditLanding}
        editAgreement={editAgreement}
        setEditAgreement={setEditAgreement}
        agreementList={agreementList}
        ad={ad}
        campaign={campaign}
        advertiser={advertiser}
        adLanding={adLanding}
        country={country}
        modeAdType="createAd"
        setSelectMode={setSelectMode}
        image={image}
        video={video}
      />
    ),
  };

  return (
    <div className="container-edit-ad">
      <div className="sidebar-edit-ad">
        <SidebarAd
          selectMode={`step${selectMode}`}
          modeAdType="createAd"
          setSelectMode={() => ""}
        />
      </div>
      <div className="content-edit-ad">{modeSteps[`step${selectMode}`]}</div>

      <Modal
        visible={showModal}
        className="modal-ad-edit"
        footer={[
          <Button
            className="btn-footer-modal"
            onClick={() => setShowModal(false)}
          >
            {" "}
            {i18n.t("pages.editAd.modal.cancel")}
          </Button>,
          <Button className="btn-footer-modal" onClick={handelUnSave}>
            {" "}
            {i18n.t("pages.editAd.modal.notSave")}
          </Button>,
          <Button type="primary" onClick={handelSave}>
            {" "}
            {i18n.t("pages.editAd.modal.save")}
          </Button>,
        ]}
      >
        <p className="modal-text">{i18n.t("pages.editAd.modal.text")}</p>
      </Modal>
    </div>
  );
};

export default CreateAd;
