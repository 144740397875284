export const adInitialState = {
  active: true,
  id: 0,
  isAgreementRequired: false,
  isAdultContent: false,
  twitch: true,
  youtube: false,
  cpm: 0,
  budget: 0,
  startDate: "",
  endDate: "",
  defaultMessage: "",
  chatMessage: "",
  countryId: "",
  publicName: "",
  name: "",
  videoUrl: "",
  overlayUrl: "",
  campaignId: 0,
};

export const campaignInitialState = {
  title: "",
  id: 0,
  advertiserId: "",
};

export const advertiserInitialState = {
  id: "",
  name: "",
};

export const adLandingInitialState = {
  chatMessageTag: "",
  url: "",
  newUrl: "",
  adId: 0,
  brandedUrl: null,
};

export const countryInitialState = {
  id: "",
  code: "",
};
