import React from "react";
import { Button } from "antd";

type ResetFiltersBtnProps = {
  label: string;
  type?: "default" | "text" | "link" | "ghost" | "primary" | "dashed";
  icon: JSX.Element;
  onClick: () => void;
};

const ResetFiltersBtn = ({
  label,
  type,
  icon,
  onClick = () => undefined,
}: ResetFiltersBtnProps): JSX.Element => {
  return (
    <Button type={type} shape="round" icon={icon} onClick={() => onClick()}>
      {label}
    </Button>
  );
};

ResetFiltersBtn.defaultProps = {
  type: "default",
};

export default ResetFiltersBtn;
