import { AuthActionTypes, User, LOG_OUT, USER_LOGIN } from "./types";
import { removeTokenFromStorage } from "../../services/_auth";

export const userLogin = (user: User): AuthActionTypes => ({
  type: USER_LOGIN,
  payload: { user },
});

export const logOut = (): AuthActionTypes => {
  removeTokenFromStorage();
  return {
    type: LOG_OUT,
  };
};
