import React from "react";
import { I18nextProvider } from "react-i18next";
import { StoreContext } from "redux-react-hook";
import { BrowserRouter } from "react-router-dom";

import AuthContainer from "./components/AuthContainer";
import Layout from "./components/Layout";
import Login from "./pages/Login";

import MainRouterSwitch from "./MainRouterSwitch";
import i18n from "./i18n";
import { store } from "./store";
import "./App.scss";

function App(): JSX.Element {
  return (
    <BrowserRouter>
      <StoreContext.Provider value={store}>
        <I18nextProvider i18n={i18n}>
          <AuthContainer authenticator={<Login />}>
            <Layout>
              <MainRouterSwitch />
            </Layout>
          </AuthContainer>
        </I18nextProvider>
      </StoreContext.Provider>
    </BrowserRouter>
  );
}

export default App;
