import React, { useContext } from "react";
import { Col, message, Table } from "antd";
import { I18nContext } from "react-i18next";
import "./ChannelTable.scss";
import moment from "moment";
import { Pagination, UpdateStatusData } from "./types";
import { Channel, Country } from "../../services/ChannelsManagement/types";
import { DateFilterSelectable, StringFilter } from "../Filters";
import DropDownFilter from "./DropDownFilter";
import getColumns from "./getColumns";
import {
  putStreamerCountry,
  putStreamerCountryGroup,
  putStreamerCountryRange,
  putStreamerCountrywithoutMonetization,
} from "../../services/ChannelsManagement/CountryMonetization";
import { channelFilters } from "../../pages/ChannelsManagement/types";

type ChannelTableProps = {
  loading: boolean;
  pagination: Pagination;
  countries: Country[];
  allFilters: channelFilters;
  getCountryById: (id: string) => Country[];
  getMonetizationGroupById: (id: string) => { id: string; name: string }[];
  getMonetizationRangeById: (id: string) => { id: string; name: string }[];
  streamerActivation: ({
    streamerId,
    createAutomaticTip,
  }: UpdateStatusData) => Promise<void>;
  setAllFilters: (v: channelFilters) => void;
  setLoading: (v: boolean) => void;
  rawChanels: Channel[];
  updateRawChannels: (
    updatedValues: {
      countryId: string;
      countryName: string;
      groupId: string;
      groupName: string;
      rangeId: string;
      rangeName: string;
    },
    channel: Channel
  ) => void;
};

const ChannelTable = ({
  loading,
  pagination,
  countries,
  getCountryById,
  getMonetizationGroupById,
  getMonetizationRangeById,
  allFilters,
  setLoading,
  streamerActivation,
  setAllFilters,
  rawChanels,
  updateRawChannels,
}: ChannelTableProps): JSX.Element => {
  const { i18n } = useContext(I18nContext);
  const getCountries = () => {
    const value = countries.map((element) => {
      return { id: element.id, name: element.code };
    });
    return value;
  };

  const getMonetizationGroup = () => {
    const arrayGroups = countries.map((countryElement) =>
      countryElement?.groups?.map((group) => ({
        id: group.id,
        name: group.name,
      }))
    );

    return Object.assign([], ...arrayGroups);
  };

  const getMonetizationRange = () => {
    const arrayRanges = countries.map((countryElement) =>
      countryElement?.groups?.map((group) =>
        group.range.map((range) => ({ id: range.id, name: range.name }))
      )
    );
    return Object.assign([], ...arrayRanges).flat();
  };

  const doStringFilter = (channelList: Channel[], key: string, value: string) =>
    channelList.filter((v) =>
      v[key]?.toString().toLowerCase().includes(value.toLowerCase()) ? v : null
    );

  const doDateFilter = (channelList: Channel[], key: string, value: string[]) =>
    channelList.filter((v) =>
      moment(v[key]?.toString()).isBetween(value[0], value[1]) ? v : null
    );

  const checkAllFilters = () => {
    let filteredChannels = rawChanels;

    Object.entries(allFilters).map((filterUsed) => {
      if (filterUsed[1]) {
        if (typeof filterUsed[1] === "string") {
          filteredChannels = doStringFilter(
            filteredChannels,
            filterUsed[0],
            filterUsed[1]
          );
        }

        if (Array.isArray(filterUsed[1])) {
          filteredChannels = doDateFilter(
            filteredChannels,
            filterUsed[0],
            filterUsed[1]
          );
        }
      }

      return filteredChannels;
    });
    return filteredChannels;
  };

  const updateChannelsIds = async (
    keyProp: string,
    channel: Channel,
    value: string
  ) => {
    if (keyProp === "countryId") {
      if (!countries.filter((e) => e.id === value)[0].groups) {
        return putStreamerCountrywithoutMonetization(channel.streamerId, value);
      }
      return putStreamerCountry(channel.streamerId, value);
    }
    if (keyProp === "monetizationGroupId") {
      return putStreamerCountryGroup(channel.streamerId, value);
    }
    if (keyProp === "monetizationRangeId") {
      return putStreamerCountryRange(channel.streamerId, value);
    }
    return { data: {} };
  };

  const getChangeOfMonetizationOrCountry = (key: string, value: string) => {
    if (key === "countryId") {
      return `${i18n.t("pages.channels.message.country")} ${i18n.t(
        "pages.channels.message.changed"
      )} ${getCountryById(value)[0].code}`;
    }
    if (key === "monetizationGroupId") {
      return `${i18n.t("pages.channels.message.monetizationGroup")} ${i18n.t(
        "pages.channels.message.changed"
      )} ${getMonetizationGroupById(value)[0].name}`;
    }

    if (key === "monetizationRangeId") {
      return `${i18n.t("pages.channels.message.monetization")} ${i18n.t(
        "pages.channels.message.changed"
      )} ${getMonetizationRangeById(value)[0].name}`;
    }
    return "";
  };
  const updateCountryAndMonetizationData = async (
    value: string,
    keyProp: string,
    channel: Channel
  ): Promise<void> => {
    try {
      setLoading(true);
      const updatedChannel = await updateChannelsIds(keyProp, channel, value);
      updateRawChannels(updatedChannel.data, channel);
      message.success({
        content: `${channel.displayName} ${getChangeOfMonetizationOrCountry(
          keyProp,
          value
        )}`,
        className: "message",
        duration: 5,
      });
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <>
      <Col xs={24}>
        <Table<Channel>
          rowKey="streamerId"
          className="channel-table"
          loading={loading}
          dataSource={checkAllFilters()}
          pagination={pagination}
          columns={getColumns(
            countries,
            streamerActivation,
            updateCountryAndMonetizationData,
            {
              DateFilter: (
                <DateFilterSelectable
                  filterProp="createdAt"
                  state={allFilters}
                  setState={setAllFilters}
                />
              ),
              StreamerIdFilter: (
                <StringFilter
                  filterProp="twitchId"
                  state={allFilters}
                  setState={setAllFilters}
                />
              ),
              DisplayNameFilter: (
                <StringFilter
                  filterProp="displayName"
                  state={allFilters}
                  setState={setAllFilters}
                />
              ),
              EmailFilter: (
                <StringFilter
                  filterProp="email"
                  state={allFilters}
                  setState={setAllFilters}
                />
              ),
              AffiliateFilter: (
                <StringFilter
                  filterProp="affiliateEmail"
                  state={allFilters}
                  setState={setAllFilters}
                />
              ),
              CountryFilter: (
                <DropDownFilter
                  filterProp="countryId"
                  state={allFilters}
                  setState={setAllFilters}
                  elements={getCountries()}
                />
              ),
              MonetizationFilter: (
                <DropDownFilter
                  filterProp="monetizationRangeId"
                  state={allFilters}
                  setState={setAllFilters}
                  elements={getMonetizationRange()}
                />
              ),
              MonetizationGroupFilter: (
                <DropDownFilter
                  filterProp="monetizationGroupId"
                  state={allFilters}
                  setState={setAllFilters}
                  elements={getMonetizationGroup()}
                />
              ),
            }
          )}
        />
      </Col>
    </>
  );
};

export default ChannelTable;
