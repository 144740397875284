import { AxiosResponse } from "axios";
import createGetFn from "../get";
import createPutFn from "../put";
import createPostFn from "../post";

import {
  Ad,
  AdLandingData,
  Advertiser,
  CampaignData,
  DataAd,
  CountryData,
  Adcreate,
} from "./type";

export const baseUrl = `${window._env_.SERVER_URL}/admin/ad`;

const adGet = createGetFn(baseUrl, {
  normalize: (res: any): DataAd => {
    const { data } = res;
    const { Campaign, AdLanding } = data;
    const ad: Ad = {
      active: data.active,
      id: data.id,
      isAgreementRequired: data.isAgreementRequired,
      isAdultContent: data.isAdultContent,
      cpm: data.cpm,
      budget: data.budget,
      startDate: data.startDate,
      endDate: data.endDate,
      defaultMessage: data.defaultMessage,
      chatMessage: data.chatMessage,
      countryId: data.countryId,
      publicName: data.publicName,
      name: data.name,
      twitch: data.twitch,
      youtube: data.youtube,
      videoUrl: data.videoUrl,
      overlayUrl: data.overlayUrl,
      campaignId: data.campaignId,
    };

    const campaign: CampaignData = {
      title: Campaign.title,
      id: Campaign.id,
      advertiserId: Campaign.advertiserId,
    };

    const adLanding: AdLandingData = {
      url: AdLanding.url,
      chatMessageTag: AdLanding.chatMessageTag,
      newUrl: "",
      adId: data.id,
      brandedUrl: AdLanding.brandedUrl,
    };

    const advertiser: Advertiser = {
      id: Campaign.advertiser.id,
      name: Campaign.advertiser.name,
    };

    const country: CountryData = {
      id: data.Country.id,
      code: data.Country.code,
    };

    return {
      ad,
      campaign,
      adLanding,
      advertiser,
      country,
    };
  },
});

const put = createPutFn(baseUrl, {
  normalize: (res: AxiosResponse) => res,
});

const post = createPostFn(baseUrl, {
  normalize: (res: AxiosResponse) => res.data.id,
});

const postCreativity = createPostFn(baseUrl, {
  normalize: (res: AxiosResponse) => res,
});

export const putAd = (ad: Ad) => put("", { ...ad });

export const getAd = (adId: string) => adGet(`/${adId}`);

export const postAd = (ad: Adcreate) => post("", { ...ad });

export const postCreativityAd = (adId: string, formData: any) =>
  postCreativity(`creativity/${adId}`, formData);
