import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import Video from "../Video/Video";

interface Props {
  videoUrl: string | null;
  modeType: string;
  setVideoPreview: (v: any) => void;
  hidenInputVideo: any;
  handelClick: (e: any) => void;
  handelFileImgAndVideo: (e: any, c: any, type: string, callback: any) => void;
  setVideo: (file: any) => void;
}

const VideAd = ({
  videoUrl,
  modeType,
  setVideoPreview,
  hidenInputVideo,
  handelClick,
  handelFileImgAndVideo,
  setVideo,
}: Props) => {
  return (
    <>
      {modeType === "createAd" ? (
        <button
          className="btn-upload-img"
          type="button"
          color="primary"
          onClick={handelClick}
        >
          {videoUrl !== "" ? <Video videoUrl={videoUrl} /> : null}
          <label htmlFor="contained-button-file">
            <input
              type="file"
              id="contained-button-file-1"
              style={{ display: "none" }}
              ref={hidenInputVideo}
              onChange={(e) =>
                handelFileImgAndVideo(e, setVideoPreview, "videoUrl", setVideo)
              }
            />
            {videoUrl === "" ? (
              <>
                <PlusOutlined />
                <p>Subir</p>
              </>
            ) : null}
          </label>
        </button>
      ) : (
        <Video videoUrl={videoUrl} />
      )}
    </>
  );
};

export default VideAd;
