import React from "react";
import { Tag } from "antd";
import { CloseOutlined } from "@ant-design/icons";

type TagsProps = {
  tags: string[];
  removeTag: (v: string) => void;
};

const Tags = ({
  tags = [],
  removeTag = () => undefined,
}: TagsProps): JSX.Element => {
  return (
    <div>
      {tags.map((v) => (
        <Tag key={v}>
          {`${v} `}
          <CloseOutlined onClick={() => removeTag(v)} />
        </Tag>
      ))}
    </div>
  );
};

export default Tags;
