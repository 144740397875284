import { AxiosResponse } from "axios";
import createGetFn from "../get";
import createPutFn from "../put";
import { Channel, ChannelRow } from "./types";

export const baseUrl = `${window._env_.SERVER_URL}/admin/streamer/accounts`;

const get = createGetFn(baseUrl, {
  auth: true,

  normalize: (res: AxiosResponse) => {
    const { data } = res;
    const channel: Channel[] = [];
    data.map((channelRow: ChannelRow) =>
      channel.push({
        streamerId: channelRow.id,
        twitchId: channelRow.twitchUser.id,
        displayName: channelRow.twitchUser.displayName,
        email: channelRow.account.email,
        createdAt: channelRow.account.created_at,
        countryId: channelRow.country.id,
        countryCode: channelRow.country.code,
        affiliateEmail: channelRow.affiliate.account.email,
        monetizationRangeId: channelRow.monetizationRangeEarnings.range.id,
        monetizationRangeName: channelRow.monetizationRangeEarnings.range.name,
        monetizationGroupId:
          channelRow.monetizationRangeEarnings.range.group.id,
        monetizationGroupName:
          channelRow.monetizationRangeEarnings.range.group.name,
        createAutomaticTip: channelRow.create_automatic_tip,
      })
    );

    return channel;
  },
});

const put = createPutFn(baseUrl, {
  normalize: (res: AxiosResponse) => res,
});

export const putStreamerCreateAutomaticTip = (
  id: string,
  status: "activate" | "deactivate"
): AxiosResponse => {
  return put(`${id}/${status}`, {});
};

export const getChannelData = (query: string): Promise<Channel[]> => get(query);
