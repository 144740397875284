import React, {
  FunctionComponent,
  useContext,
  useState,
  useEffect,
} from "react";
import Helmet from "react-helmet";
import { I18nContext } from "react-i18next";
import { Col, Row } from "antd";
import {
  getModerationData,
  updateEmissionProofStatus,
} from "../../services/Moderation";
import ModerationTable from "../../components/ModerationTable";
import { QueryFormatter } from "../../components/ModerationTable/type";
import { queryFormatter } from "./helper";
import { UpdateStatus, Moderation } from "./types";
import "./Moderation.scss";

const ModerationComponent: FunctionComponent = () => {
  const initModerationData: Moderation = {
    limit: 1,
    page: 1,
    data: {
      total: 0,
      rows: [],
    },
  };
  const { i18n } = useContext(I18nContext);
  const [loading, setLoading] = useState(true);
  const [moderationData, setModerationData] = useState(initModerationData);

  useEffect(() => {
    getModerationData("?page=1&limit=10&status=[0,1,2,3,4]")
      .then((data) => {
        setModerationData(data);
      })
      .finally(() => setLoading(false));
  }, []);

  async function fetchData(filters: QueryFormatter): Promise<void> {
    setLoading(true);
    try {
      const query = queryFormatter(filters);
      const data = await getModerationData(query);
      setModerationData(data);
    } catch (err) {
      setModerationData(initModerationData);
    }
    setLoading(false);
  }

  const handleUpdateStatus = async ({ adMetricId, status }: UpdateStatus) => {
    try {
      setLoading(true);
      await updateEmissionProofStatus(`${adMetricId}`, status);
      setModerationData((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          rows: prevState.data.rows.map((row) => {
            if (row.adMetricId === adMetricId) {
              return { ...row, status };
            }
            return row;
          }),
        },
      }));
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="moderation-page">
      <Helmet>
        <title>Streamion | {i18n.t("pages.moderation.title")}</title>
      </Helmet>
      <Row gutter={45}>
        <Col xs={24}>
          <ModerationTable
            loading={loading}
            dataSource={moderationData}
            onUpdateStatus={handleUpdateStatus}
            queryWithFilters={fetchData}
          />
        </Col>
      </Row>
    </section>
  );
};

export default ModerationComponent;
