import React, { useEffect, useState, useContext } from "react";
import "./frequenciesManagement.scss";
import { Tabs, Divider, message, Tag, Button, Checkbox } from "antd";
import { I18nContext } from "react-i18next";
import {
  getStreamerLive,
  putGroupStreamers,
} from "../../services/FrequencyManagement/FrequencyManagement";
import { Streamer } from "../../services/FrequencyManagement/types";
import { Filter, StreamerGroupId } from "./type";

import DynamicTable from "../../components/DynamicTable/DynamicTable";
import ColumnsTable from "./ColumnsTable";
import SearchPanelFrequencyManagement from "../../components/SearchFrequency/SearchPanelFrequencyManagement";
import ButtonsFrequency from "../../components/ButtonsFrequency/ButtonsFrequency";
import ViewerFrequency from "../../components/ViewersFrequency/ViewersFrequency";

const FrequenciesManagement = () => {
  const { TabPane } = Tabs;
  const { i18n } = useContext(I18nContext);

  const [dataStreamers, setDataStreamers] = useState<Array<Streamer>>([]);
  const [loading, setLoading] = useState(true);

  const [filterData, setFilterData] = useState<Array<Streamer>>([]);
  const [filterFrequencies, setFilterFrequencies] = useState(120);

  const [streamerGroupId, setStreamerGroupId] = useState<StreamerGroupId>({
    ids: [],
    minIntervalSeconds: 90 * 60,
  });
  const [searchStreamers, setSearchStreamers] = useState<Array<Streamer>>([]);
  const [filterSelected, setFilterSelected] = useState<Array<Filter>>([]);
  const [showButtonUpdateGroup, setShowButtonUpdateGropu] = useState(false);
  const [checkedAllStreamerSearch, setCheckedAllStreamerSearch] = useState(
    false
  );

  const filterDataForFrequencies = (data: Streamer[], frequency: number) => {
    const listStreamers = data.filter(
      (streamer: Streamer) =>
        streamer.getIntervalMinutes(streamer.minIntervalSeconds - 150) <=
          frequency &&
        streamer.getIntervalMinutes(streamer.minIntervalSeconds + 150) >=
          frequency &&
        streamer.isLive
    );
    setFilterData(listStreamers);
  };

  const onSearch = (streamerData: Streamer[], filters?: Filter[]) => {
    const selectedFilter = !filters ? filterSelected : filters;
    let data: Streamer[] = streamerData;
    if (!filterSelected.length) {
      setShowButtonUpdateGropu(false);
    }

    selectedFilter.forEach((filter: Filter) => {
      if (filter.name === "Min.viewers") {
        data = data.filter((streamer) => {
          const viewers = streamer[filter.column];

          return viewers >= filter.value;
        });
      }

      if (filter.name === "Max.viewers") {
        data = data.filter((streamer) => {
          const viewers = streamer[filter.column];

          return viewers <= filter.value;
        });
      }

      if (filter.name === "Frequency") {
        data = data.filter(
          (streamer) => streamer[filter.column] === Number(filter.value) * 60
        );
      }

      if (filter.name === "Country") {
        data = data.filter((streamer) => {
          if (streamer[filter.column] != null) {
            const index: number = streamer[filter.column]
              .toString()
              .toLowerCase()
              .indexOf(filter.value.toString().toLowerCase());
            if (index >= 0) {
              return streamer;
            }
          }

          return false;
        });
      }
      if (filter.name === "ID/Name") {
        data = data.filter((streamer: Streamer) => {
          const indexId = streamer.twitchId.indexOf(filter.value.toString());
          if (indexId >= 0) {
            return streamer;
          }

          const indexName = streamer.name
            .toLowerCase()
            .indexOf(filter.value.toString().toLowerCase());
          if (indexName >= 0) {
            return streamer;
          }

          return false;
        });
      }
    });

    const set = new Set(data);
    const dataStreamer = Array.from(set);

    setSearchStreamers(dataStreamer);
  };

  useEffect(() => {
    if (dataStreamers.length === 0) {
      getStreamerLive()
        .then((res: any) => {
          setDataStreamers(res);
          filterDataForFrequencies(res, filterFrequencies);
        })
        .catch((err: any) => console.log(err))
        .finally(() => setLoading(false));
    }
  }, [dataStreamers, filterFrequencies]);

  const tagsFilters = (key: string) => {
    const frequency = parseFloat(key);
    setFilterFrequencies(frequency);
    filterDataForFrequencies(dataStreamers, frequency);
  };

  const selectFrequency = (e: any, streamer: Streamer, mode: string) => {
    const newData: Streamer[] = [];
    const modeData: any = {
      isLive: {
        data: filterData,
        set: setFilterData,
      },
      search: {
        data: searchStreamers,
        set: setSearchStreamers,
      },
    };
    modeData[mode].data.forEach((data: Streamer) => {
      if (data.twitchId === streamer.twitchId) {
        const newStreamer = { ...data };
        newStreamer.minIntervalSeconds = parseInt(e.key, 10) * 60;
        newStreamer.disabled = false;
        newData.push(newStreamer);
      } else {
        newData.push(data);
      }
    });
    modeData[mode].set(newData);
    setStreamerGroupId({
      ids: [streamer.id],
      minIntervalSeconds: parseInt(e.key, 10) * 60,
    });
  };

  const updateFrequency = (streamer?: Streamer) => {
    putGroupStreamers(streamerGroupId)
      .then((res: any) => {
        const newData: Streamer[] = [];
        dataStreamers.forEach((dataStreamer: Streamer) => {
          if (streamerGroupId.ids.includes(dataStreamer.id)) {
            const newDataStramer = { ...dataStreamer };
            newDataStramer.minIntervalSeconds =
              streamerGroupId.minIntervalSeconds;
            newDataStramer.disabled = false;
            newData.push(newDataStramer);
          } else {
            newData.push(dataStreamer);
          }
        });
        setDataStreamers(newData);
        filterDataForFrequencies(newData, filterFrequencies);
        onSearch(newData);
        setShowButtonUpdateGropu(false);
        setCheckedAllStreamerSearch(false);
        setStreamerGroupId({
          ids: [],
          minIntervalSeconds: 90 * 60,
        });
        if (streamerGroupId.ids.length > 1) {
          message.success({
            content: "Frequencies have been updated!",
            className: "message",
            duration: 5,
          });
        } else {
          message.success({
            content: `${streamer ? streamer.name : "Streamers"} ${i18n.t(
              "components.tableFrequencies.message.succes"
            )} ${
              streamer
                ? streamer.getIntervalMinutes(streamer.minIntervalSeconds)
                : streamerGroupId.minIntervalSeconds / 60
            }min!`,
            className: "message",
            duration: 5,
          });
        }
      })
      .catch((err: any) => {
        message.error({
          content: i18n.t("components.tableFrequencies.message.error"),
          className: "message",
          duration: 5,
        });
      });
  };

  const selectFrequencyGroupStreamers = (frequency: any) => {
    setStreamerGroupId({
      ...streamerGroupId,
      minIntervalSeconds: parseInt(frequency.key, 10) * 60,
    });
  };

  const deleteFelter = (index: number) => {
    filterSelected.splice(index, 1);
    onSearch(dataStreamers);
  };

  const checkedStreamerSearchTable = (checked: boolean, streamer: Streamer) => {
    const searchStreamersChecked = searchStreamers.map(
      (streamerSarch: Streamer) => {
        if (streamerSarch.id === streamer.id) {
          const newStreamer: Streamer = {
            ...streamer,
            isChecked: checked,
            disabled: !streamer.disabled,
          };
          return newStreamer;
        }
        return streamerSarch;
      }
    );
    setSearchStreamers(searchStreamersChecked);
  };

  const selectAllSearch = (e: any) => {
    const { checked } = e.target;
    if (checked) {
      setCheckedAllStreamerSearch(checked);
      setShowButtonUpdateGropu(checked);
      const streamerIds: string[] = [];
      const checkedStreamers = searchStreamers.map((streamer) => {
        streamerIds.push(streamer.id);
        return { ...streamer, isChecked: checked };
      });
      setStreamerGroupId({ ...streamerGroupId, ids: streamerIds });
      setSearchStreamers(checkedStreamers);
    } else {
      setStreamerGroupId({ ...streamerGroupId, ids: [] });
      setShowButtonUpdateGropu(checked);
      setCheckedAllStreamerSearch(checked);
    }
  };

  const selectStreamerSearch = (e: any, streamer: Streamer) => {
    const { checked } = e.target;
    const inlcudeStreamer = streamerGroupId.ids.includes(streamer.id);
    if (inlcudeStreamer) {
      const newStreamerId = streamerGroupId.ids.filter(
        (id: string) => id !== streamer.id
      );
      if (!newStreamerId.length) {
        setShowButtonUpdateGropu(false);
      }
      setStreamerGroupId({
        ...streamerGroupId,
        ids: newStreamerId,
      });
    } else {
      setStreamerGroupId({
        ...streamerGroupId,
        ids: [...streamerGroupId.ids, streamer.id],
      });
      setShowButtonUpdateGropu(true);
    }
    checkedStreamerSearchTable(checked, streamer);
  };

  return (
    <div className="container-frequecies">
      <ViewerFrequency />
      <div className="container-search">
        <h1 className="section-tittle">{i18n.t("pages.frequencies.search")}</h1>
        <SearchPanelFrequencyManagement
          filterSelected={filterSelected}
          setFilterSelected={setFilterSelected}
          onSearch={onSearch}
          dataStreamers={dataStreamers}
        />

        <div className="search-table">
          <div className="header-tags">
            {filterSelected.length > 0 ? (
              <div className="container-tags">
                {!showButtonUpdateGroup ? (
                  <div className="wrapprer-tags">
                    <div>
                      {filterSelected.map((filter: Filter, index: number) => (
                        <Tag
                          key={filter.name + filter.value}
                          closable
                          color="geekblue"
                          visible
                          onClose={() => deleteFelter(index)}
                        >
                          {filter.name} : {filter.value}
                        </Tag>
                      ))}
                    </div>
                    <Button
                      className="ant-btn ant-btn-primary"
                      size="small"
                      onClick={() => {
                        setFilterSelected([]);
                        setStreamerGroupId({
                          ids: [],
                          minIntervalSeconds: 90 * 60,
                        });
                      }}
                    >
                      Clear Filters
                    </Button>
                  </div>
                ) : (
                  <ButtonsFrequency
                    text={i18n.t("components.tableFrequencies.table.button")}
                    selectFrequency={selectFrequencyGroupStreamers}
                    updateFrequency={updateFrequency}
                    mode="group"
                    streamer={searchStreamers[0]}
                  />
                )}
              </div>
            ) : null}
          </div>
          {filterSelected.length > 0 ? (
            <DynamicTable
              columns={ColumnsTable({
                text: i18n.t("components.tableFrequencies.table.button"),
                selectFrequency,
                updateFrequency,
                selectStreamerSearch,
                mode: "search",
                selectAllSearch,
                checkedAllStreamerSearch,
              })}
              dataToPrint={searchStreamers}
              rowSelection={undefined}
              loading={false}
            />
          ) : null}
        </div>
      </div>
      <div className="wrapper-title">
        <h1 className="section-tittle">{i18n.t("pages.frequencies.title")}</h1>
        <p>{i18n.t("pages.frequencies.sub_title")}</p>
      </div>
      <div className="wrapper-taps">
        <Tabs defaultActiveKey="120" onChange={tagsFilters}>
          <TabPane tab="120" key="120" />
          <TabPane tab="105" key="105" />
          <TabPane tab="90" key="90" />
          <TabPane tab="75" key="75" />
          <TabPane tab="60" key="60" />
          <TabPane tab="45" key="45" />
          <TabPane tab="40" key="40" />
          <TabPane tab="30" key="30" />
          <TabPane tab="20" key="20" />
          <TabPane tab="15" key="15" />
          <TabPane className="last-tap" tab="12" key="12" />
        </Tabs>
      </div>
      <Divider />
      <div className="wrapper-table">
        <DynamicTable
          columns={ColumnsTable({
            text: i18n.t("components.tableFrequencies.table.button"),
            selectFrequency,
            updateFrequency,
            mode: "isLive",
          })}
          dataToPrint={filterData}
          rowSelection={null}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default FrequenciesManagement;
