import React from "react";
import { Select } from "antd";
import "./DropDownSelector.scss";
import { Option } from "antd/lib/mentions";
import { Channel } from "../../../services/ChannelsManagement/types";

type DropdownType = {
  name?: string;
  code?: string;
  id: string;
};

type DropdownSelectorProps = {
  elements: DropdownType[];
  channel: Channel;
  keyProp: string;
  selectedItem: string;
  disabled?: boolean;
  updateData: (
    value: string,
    keyProp: string,
    channel: Channel
  ) => Promise<void>;
};

const DropDownSelector = ({
  elements,
  channel,
  keyProp,
  selectedItem,
  disabled,
  updateData,
}: DropdownSelectorProps): JSX.Element => {
  const updateValue = async (value: string) => {
    try {
      const updatedValues = await updateData(value, keyProp, channel);
      return updatedValues;
    } catch (err) {
      console.log(err);
      return {
        streamerId: "",
        countryId: "",
        rangeId: "",
        groupId: "",
      };
    }
  };
  return (
    <div className="dropdownWraper">
      <div className="selector">
        <Select
          value={selectedItem}
          disabled={disabled}
          onSelect={(value) => updateValue(value)}
        >
          {elements.map((element: DropdownType) => (
            <Option key={element.id} value={element.id}>
              {element.name || element.code}
            </Option>
          ))}
        </Select>
      </div>
    </div>
  );
};

DropDownSelector.defaultProps = {
  disabled: false,
};

export default DropDownSelector;
